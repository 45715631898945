// Node modules
import * as Sentry from '@sentry/react';

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import { MemberDocumentAlreadyApprovedException } from '../exceptions/MemberDocumentAlreadyApprovedException';
import { MemberDocumentPreviouslyDeniedException } from '../exceptions/MemberDocumentPreviouslyDeniedException';
import { MemberDocumentAlreadyDeniedException } from '../exceptions/MemberDocumentAlreadyDeniedException';
import { MemberDocumentPreviouslyApprovedException } from '../exceptions/MemberDocumentPreviouslyApprovedException';
import { FailedToFetchException } from '../exceptions/FailedToFetchException';

// Helpers
import { member, retryUnauthorizedRequestAfterRefresh } from '..';

export interface MemberDocumentDB {
	docID: string;
	type: string;
	uploaded_date: Date | null; // null is because of member import
	certification_date: Date;
	approved_date?: Date;
	approved_by?: string;
	denied_date?: Date;
	denied_by?: string;
	denied_reason?: string;

	latest?: boolean;
	has_expiration?: boolean;

	events?: Array<MemberDocumentEvent>;

	notifications?: Array<MemberDocumentNotifications>;

	cloudStorageError?: string;

	isMemberArchived?: boolean;
	isExpired?: boolean;

	memberDocID?: string; // Used in document search, etc. to get the Member's ID
}

/**
 *
 */
export interface MemberDocumentEvent {
	uid: string; // The event id
	date: Date; // The date the event happened
	state: 'created' | 'processing' | 'success' | 'failure'; // State of this event

	conditions?: Array<{ [key: string]: any }>; // This handles the expiration before

	params?: string; // Optional params like the notification id
}

/**
 *
 */
export interface MemberDocumentNotifications {
	uid: string; // The settings notification uid
	sent_date: Date;
	send_to: string;
	subject: string;
	body: string;
}

export default class Document {
	async list(token: string, memberDocID: string): Promise<Array<MemberDocumentDB>> {
		try {
			const response = await fetch(`/api/v1/admin/members/${memberDocID}/documents`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				if (respObj.document_list == null) {
					return [];
				}

				return respObj.document_list;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.list(newToken, memberDocID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async get(token: string, memberDocID: string, docID: string): Promise<MemberDocumentDB> {
		try {
			const response = await fetch(`/api/v1/admin/members/${memberDocID}/documents/${docID}`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.get(newToken, memberDocID, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async post(token: string, docID: string, formData: FormData): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/members/${docID}/documents`, {
				method: 'POST',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
				body: formData,
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.post(newToken, docID, formData);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async fix(token: string, memberDocID: string, docID: string, newData: MemberDocumentDB): Promise<boolean> {
		try {
			const response = await fetch(`/api/v1/admin/members/${memberDocID}/documents/${docID}`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(newData),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.fix(newToken, memberDocID, docID, newData);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async approve(token: string, memberDocID: string, docID: string): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/members/${memberDocID}/documents/${docID}/approve`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.approve(newToken, memberDocID, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 470) {
				throw new MemberDocumentAlreadyApprovedException(respObj.message);
			} else if (response.status === 471) {
				throw new MemberDocumentPreviouslyDeniedException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async deny(token: string, memberDocID: string, docID: string, reasonText: string): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/members/${memberDocID}/documents/${docID}/deny`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(reasonText),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.deny(newToken, memberDocID, docID, reasonText);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 470) {
				throw new MemberDocumentAlreadyDeniedException(respObj.message);
			} else if (response.status === 471) {
				throw new MemberDocumentPreviouslyApprovedException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}
}
