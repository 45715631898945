"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceNotFoundException = void 0;
/**
 * This exception is used if the content cannot be found in the database
 */
class ResourceNotFoundException extends Error {
    /**
     * Optional resource id to include in the error
     */
    resourceID;
    /**
     * Constructor
     * @param {string} message the error message
     * @param {object} resourceID the resource ID
     */
    constructor(message, resourceID) {
        super(message);
        this.resourceID = resourceID;
        // If we support a stack trace, capture it
        if ('captureStackTrace' in Error) {
            Error.captureStackTrace(this, this.constructor);
        }
        else {
            console.error(`${this.constructor.name} Does not support captureStackTrace()!`);
        }
    }
    /**
     * Returns the resource ID that caused the error
     */
    getResourceID() {
        return this.resourceID;
    }
}
exports.ResourceNotFoundException = ResourceNotFoundException;
