import React from 'react';

import { Dropzone } from '../../Dropzone';
import { FileWithAttributes } from 'api/tempTypes';

interface Props {
	validFileTypes: string | Array<string>;
	onDrop: (acceptedFiles: Array<FileWithAttributes>) => void;
}

export const UploadDocument = (props: Props) => {
	const { validFileTypes, onDrop } = props;

	return (
		<div className="upload-document">
			<Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)} validTypes={validFileTypes} />
		</div>
	);
};
