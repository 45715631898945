import React, { useState, useEffect } from 'react';

import { Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupText, InputGroup } from 'reactstrap';

import { AuthAdministrator, login } from '../../../api';
import { validateInt } from '../../../validator';

import { LoginUnsuccessfulException } from '../../../api/exceptions/LoginUnsuccessfulException';
import { FaKey, FaSpinner } from 'react-icons/fa';

interface Props {
	onSubmit: (loginData: { access: AuthAdministrator; refresh: string }) => void;
	loginToken: string | null;
}

export const VerifyForm = (props: Props) => {
	const { onSubmit, loginToken } = props;
	const [checkButtonEnabled, setCheckButtonEnabled] = useState(false);
	const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
	const [messageState, setMessageState] = useState('info');
	const [message, setMessage] = useState<string | null>(null);
	const [code, setCode] = useState('');

	/**
	 * This function will check to make sure that we are good to submit the form anytime that data is changed
	 */
	useEffect(() => {
		let canSave = true;

		if (code == null || code === '') {
			canSave = false;
		}

		if (!validateInt(code)) {
			canSave = false;
		}

		setCheckButtonEnabled(canSave);
	}, [code]);

	async function handleLoginFormAction(event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();

		try {
			// Check if we have valid input
			// TODO: Implement better checks
			if (loginToken == null || loginToken === '' || code === '') {
				throw new Error('Invalid information');
			}

			// Set the processing state
			setMessageState('info');
			setMessage(null);
			setFormSubmitInProgress(true);

			// Send to server
			const loginData = await login.login2fa(loginToken, code);
			onSubmit(loginData);

			setFormSubmitInProgress(false);
		} catch (error) {
			setMessageState('danger');

			if (error instanceof LoginUnsuccessfulException) {
				setMessage(error.message);
			} else {
				setMessage('Invalid credentials');
			}

			setFormSubmitInProgress(false);
		}
	}

	return (
		<Form action="" className="form" method="" onSubmit={(e) => handleLoginFormAction(e)}>
			<Card className="card-login">
				<CardHeader>
					<CardHeader>
						<h3 className="header text-center">Verification</h3>
					</CardHeader>
				</CardHeader>
				<CardBody>
					<InputGroup>
						<InputGroupText style={{ background: 'none' }}>
							<FaKey color="#66615b" />
						</InputGroupText>
						<Input
							type="number"
							name={'verificationCode'}
							id={'verificationCode'}
							autoFocus
							placeholder="Verification Code"
							onChange={(e) => setCode(e.target.value)}
						/>
					</InputGroup>
				</CardBody>
				<CardFooter>
					<Button
						block
						className="btn-round mb-3"
						color="warning"
						type="button"
						disabled={formSubmitInProgress || (!formSubmitInProgress && !checkButtonEnabled)}
						onClick={(e) => handleLoginFormAction(e)}
					>
						{formSubmitInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Verify'}
					</Button>
				</CardFooter>
			</Card>
		</Form>
	);
};
