import React, { useState } from 'react';

import { Button, Input, FormGroup, Row, Col } from 'reactstrap';
import { MemberGroupRequirement, SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	onSave: (requirementData: MemberGroupRequirement) => void;

	clearanceList: Array<SettingsClearancesType>;
}

export const MemberGroupOverlayAddRequirement = (props: Props) => {
	const { onSave } = props;
	const [data, setData] = useState<MemberGroupRequirement>({ clearance: '', required: false });
	const [isAddMode, setIsAddMode] = useState(false);

	const handleToggleAdd = () => {
		if (isAddMode === true) {
			setIsAddMode(false);
			setData({ clearance: '', required: true });
		} else {
			setIsAddMode(true);
		}
	};

	const handleFieldChange = (fieldName: keyof MemberGroupRequirement, newValue: any) => {
		let newData = { ...data };

		(newData as any)[fieldName] = newValue;

		setData(newData);
	};

	const handleOnSaveClick = () => {
		return Promise.resolve()
			.then(() => {
				return onSave({ clearance: data.clearance, required: data.required });
			})
			.then(() => {
				return handleToggleAdd();
			});
	};

	return (
		<>
			{isAddMode === true ? (
				<Row>
					<Col md={5}>
						<FormGroup>
							<Input
								type="select"
								name="inputClearance"
								id="inputClearance"
								onChange={(e) => handleFieldChange('clearance', e.target.value)}
								defaultValue={data['clearance']}
							>
								<option>- Select Clearance -</option>
								{props.clearanceList != null ? (
									props.clearanceList.map((clearance) => {
										return (
											<option key={clearance.uid} value={clearance.uid}>
												{clearance.name}
											</option>
										);
									})
								) : (
									<></>
								)}
							</Input>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Input
								type="select"
								name="inputClearanceRequired"
								id="inputClearanceRequired"
								onChange={(e) => handleFieldChange('required', e.target.value)}
								defaultValue={data['required'] as any}
							>
								<option value={true as any}>Reqired</option>
								<option value={false as any}>Optional</option>
							</Input>
						</FormGroup>
					</Col>
					<Col md={3}>
						<Button color="button" size="sm" onClick={() => handleOnSaveClick()}>
							Save
						</Button>
						<Button color="link" size="sm" onClick={() => handleToggleAdd()}>
							Cancel
						</Button>
					</Col>
				</Row>
			) : (
				<Button color="link" size="sm" onClick={() => handleToggleAdd()}>
					<i className="fa fa-plus" /> Add Requirement
				</Button>
			)}
		</>
	);
};
