import React, { useState } from 'react';

import { Col, FormGroup, Table } from 'reactstrap';

import { RoleRecord } from './permissions-helpers/RoleRecord';
import { AdministratorRoleCategory, AdministratorRoleDB, AdministratorRolePermission } from 'api/administrators/roles';

interface Props {
	onChange: (newData: { [key: string]: { [key: string]: boolean } }) => void;
	permissions: {
		categories: Array<AdministratorRoleCategory>;
		roles: Array<AdministratorRolePermission>;
	};
	data: { [key: string]: { [key: string]: boolean } };
}

export const PermissionsSection = (props: Props) => {
	const { onChange, permissions, data } = props;

	const handleInputFieldChange = (roleID: string, permissionID: string, value: boolean) => {
		if (value === true) {
			if (data[roleID] == null || data[roleID][permissionID] == null) {
				const newData = { ...data };
				if (newData[roleID] == null) {
					newData[roleID] = {};
				}

				if (newData[roleID][permissionID] == null) {
					newData[roleID][permissionID] = true;
				}

				onChange(newData);
			}
		} else {
			if (data[roleID] != null && data[roleID][permissionID] != null) {
				const newData = { ...data };
				delete data[roleID][permissionID];

				onChange(newData);
			}
		}
	};

	return (
		<div className="roles-permissions-section">
			<FormGroup row>
				<Col sm={12}>
					<Table responsive style={{ marginBottom: 0 }}>
						<thead className="text-primary">
							<tr>
								<th style={{ width: '15%' }} className="text-left">
									Name
								</th>
								{permissions.categories != null && permissions.categories.length > 0 ? (
									permissions.categories.map((category, key) => {
										return (
											<th
												className="text-center"
												style={{ width: `${Math.round((100 - 15) / permissions.categories.length + 1)}` }}
												key={`permissions-section-category-${key}`}
											>
												{category.name}
											</th>
										);
									})
								) : (
									<></>
								)}
								<th
									className="text-center"
									style={{ width: `${Math.round((100 - 15) / permissions.categories.length + 1)}` }}
									key={`permissions-section-category-other`}
								>
									Other
								</th>
							</tr>
						</thead>
						<tbody>
							{permissions.roles != null && permissions.roles.length > 0 ? (
								permissions.roles.map((role, key) => {
									return (
										<RoleRecord
											key={key}
											categories={permissions.categories}
											role={role}
											data={data[role.id]}
											onChange={handleInputFieldChange}
										/>
									);
								})
							) : (
								<tr>
									<td>No records defined</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
			</FormGroup>
		</div>
	);
};
