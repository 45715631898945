import React from 'react';

import { TabDataDisplayMode } from './TabDataDisplayMode';
import { TabDataEditMode } from './TabDataEditMode';

import { Col, FormGroup, Label } from 'reactstrap';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	field: SettingsDataField;
	value: any;
	isEditMode: boolean;
	handleInputFieldChange: (fieldName: string, newValue: any, dataType?: string, isPrimaryField?: boolean) => void;
	handleInputFieldMultipleAddMode: (detailsFieldUID: string, state: boolean) => void;
}

export const TabData = (props: Props) => {
	const { field, value, isEditMode, handleInputFieldChange, handleInputFieldMultipleAddMode } = props;

	return (
		<FormGroup row key={`member-details-overlay-details-tab-data-field-${field.uid}`}>
			<Label for={`member-details-overlay-details-tab-data-field-${field.uid}`} sm={3}>
				<strong>{field.name}</strong>
			</Label>
			<Col sm={9}>
				{isEditMode === true ? (
					<TabDataEditMode
						detailsField={field}
						detailsFieldValue={value}
						handleInputFieldChange={handleInputFieldChange}
						handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
					/>
				) : (
					<TabDataDisplayMode detailsField={field} detailsFieldValue={value} />
				)}
			</Col>
		</FormGroup>
	);
};
