"use strict";
// Node modules
Object.defineProperty(exports, "__esModule", { value: true });
exports.processConditionLogicOperator = void 0;
// Types
const _1 = require("./");
// Exceptions
const _2 = require("./");
// Helpers
/**
 * This function will compare values provided based on the logic operator
 * @param logicOperator the comparison operator
 * @param baseValue the value to compare against
 * @param compareToValue to value to compare to
 * @returns boolean true if there is a match, false otherwise
 */
function processConditionLogicOperator(logicOperator, baseValue, compareToValue) {
    if (logicOperator === _1.LogicOperator.EQUAL_TO) {
        // console.debug(`processConditionLogicOperator(): (${typeof baseValue}) "${baseValue}" === (${typeof compareToValue}) "${compareToValue}"`);
        if (baseValue === compareToValue) {
            // console.debug('\ttrue');
            return true;
        }
    }
    else if (logicOperator === _1.LogicOperator.CONTAINS) {
        if (!['string', 'number'].includes(typeof baseValue) && !Array.isArray(baseValue)) {
            throw new _2.InvalidContentException(`${logicOperator}: type "${typeof baseValue}" is not supported`);
        }
        // console.log(`${baseValue} in ${compareToValue}`);
        if (typeof baseValue === 'number') {
            if (`${baseValue}`.includes(`${compareToValue}`)) {
                // console.log('\ttrue');
                return true;
            }
        }
        else {
            if (baseValue.includes(compareToValue)) {
                // console.log('\ttrue');
                return true;
            }
        }
    }
    else if (logicOperator === _1.LogicOperator.STARTS_WITH) {
        if (!['string', 'number'].includes(typeof baseValue)) {
            throw new _2.InvalidContentException(`${logicOperator}: type "${typeof baseValue}" is not supported`);
        }
        // console.log(`${baseValue} starts with ${compareToValue}`);
        if (typeof baseValue === 'number') {
            if (`${baseValue}`.startsWith(`${compareToValue}`)) {
                // console.log('\ttrue');
                return true;
            }
        }
        else {
            if (baseValue.startsWith(compareToValue)) {
                // console.log('\ttrue');
                return true;
            }
        }
    }
    else if (logicOperator === _1.LogicOperator.ENDS_WITH) {
        if (!['string', 'number'].includes(typeof baseValue)) {
            throw new _2.InvalidContentException(`${logicOperator}: type "${typeof baseValue}" is not supported`);
        }
        // console.log(`${baseValue} ends with ${compareToValue}`);
        if (typeof baseValue === 'number') {
            if (`${baseValue}`.endsWith(`${compareToValue}`)) {
                // console.log('\ttrue');
                return true;
            }
        }
        else {
            if (baseValue.endsWith(compareToValue)) {
                // console.log('\ttrue');
                return true;
            }
        }
    }
    else if (logicOperator === _1.LogicOperator.NOT_EQUAL_TO) {
        // console.debug(`processConditionLogicOperator(): (${typeof baseValue}) "${baseValue}" !== (${typeof compareToValue}) "${compareToValue}"`);
        if (baseValue !== compareToValue) {
            // console.debug('\ttrue');
            return true;
        }
    }
    else {
        throw new _2.InvalidContentException(`processConditionLogicOperator() has unknown type`);
    }
    // console.debug('false');
    return false;
}
exports.processConditionLogicOperator = processConditionLogicOperator;
