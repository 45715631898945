import React, { useContext, useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';

import { setting } from '../../api';
import UserContext from '../UserContext';

export const Logs = () => {
	const { jwt } = useContext(UserContext);

	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState({});

	useEffect(() => {
		// setting
		// 	.get(jwt, 'logs')
		// 	.then((settings) => {
		// 		setData(settings);
		setIsLoaded(true);
		// })
		// .catch((e) => {
		// 	// TODO: Handle better
		// 	console.error('error');
		// 	console.error(e);
		// });
	}, []);

	/**
	 * The render function for the React component
	 */

	return (
		<div className="content">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Logs</CardTitle>
						</CardHeader>
						<CardBody>{isLoaded ? <Col sm={12}>No settings available</Col> : <FaSpinner size={12} color="#ccc" className="fa-spin" />}</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
