import React from 'react';

import { Input, Form, Label, Button, Col, FormGroup } from 'reactstrap';
import { SettingsGeneralDB } from 'api/settings/general';

interface Props {
	data: SettingsGeneralDB;
	onChange: (fieldName: keyof SettingsGeneralDB, value: any) => void;
}

export const SystemPreferencesSection = (props: Props) => {
	const { data, onChange } = props;

	return (
		<div className="general-settings-system-preferences-section">
			<Form>
				<FormGroup row>
					<Label for="siteName" sm={2}>
						Site Name
					</Label>
					<Col sm={10}>
						<Input
							type="text"
							name="siteName"
							id="siteName"
							placeholder="Enter Site Name"
							value={data.site_name != null ? data.site_name : ''}
							onChange={(e) => {
								onChange('site_name', e.target.value);
							}}
						/>
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
