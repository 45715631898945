import React, { useState, useContext } from 'react';

import { Modal, Col, FormGroup, Label, Button, ButtonGroup, CardTitle, Alert, UncontrolledTooltip, ModalHeader } from 'reactstrap';

import { notification } from '../../../api';
import UserContext from '../../UserContext';
import { FaTimes } from 'react-icons/fa';
import { NotificationDB } from 'api/notifications';

interface Props {
	show: boolean;
	data: NotificationDB;
	onClose: (removeRecord?: boolean) => void;
}

export const NotificationDetailsOverlay = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { show, data, onClose } = props;

	const [errorStatus, setErrorStatus] = useState('danger');
	const [displayError, setDisplayError] = useState<string | null>(null);
	const [removeRecordFromParentList, setRemoveRecordFromParentList] = useState(false);

	/**
	 * Handles approving the message to be sent
	 */
	function handleApproveClick() {
		notification
			.approve(jwt, data.docID)
			.then((response) => {
				data.approved = true;
				setErrorStatus('primary');
				setDisplayError('Successfully approved');
				setRemoveRecordFromParentList(true);
			})
			.catch((e) => {
				setErrorStatus('danger');
				setDisplayError(e.message);
			});
	}

	/**
	 * Handles resend the message
	 */
	function handleResendClick() {
		notification
			.resend(jwt, data.docID)
			.then((response) => {
				setErrorStatus('primary');
				setDisplayError('Successfully resent notification');
				setRemoveRecordFromParentList(true);
			})
			.catch((e) => {
				setErrorStatus('danger');
				setDisplayError(e.message);
			});
	}

	/**
	 * Handles archiving the message
	 */
	function handleArchiveClick() {
		notification
			.archive(jwt, data.docID)
			.then((response) => {
				data.archived = new Date();
				setErrorStatus('primary');
				setDisplayError('Successfully archived notification');
				setRemoveRecordFromParentList(true);
			})
			.catch((e) => {
				setErrorStatus('danger');
				setDisplayError(e.message);
			});
	}

	/**
	 * The render function
	 */
	return (
		<Modal
			className="modal-lg notifications-details-overlay"
			isOpen={show}
			toggle={() => {
				onClose(removeRecordFromParentList);
				setDisplayError(null);
				setErrorStatus('danger');
			}}
		>
			<ModalHeader
				toggle={() => {
					onClose(removeRecordFromParentList);
					setDisplayError(null);
					setErrorStatus('danger');
				}}
			>
				Notification
			</ModalHeader>
			<div className="modal-body">
				{data != null && data.requiresApproval === true && data.archived == null && (data.approved == null || data.approved === false) ? (
					<>
						<ButtonGroup className="pull-right">
							<Button className="pull-right" size="sm" id={`approve-button`} onClick={handleApproveClick} color="success">
								<i className="fa fa-thumbs-up" /> Approve
							</Button>
							<Button className="pull-right" size="sm" id={`archive-button`} onClick={handleArchiveClick} color="secondary">
								<i className="fa fa-archive" /> Archive
							</Button>
						</ButtonGroup>
						<UncontrolledTooltip delay={0} target={`archive-button`}>
							Note: This does not archive the member
						</UncontrolledTooltip>
					</>
				) : data != null && data.status === 'failed' ? (
					<>
						<ButtonGroup className="pull-right">
							<Button className="pull-right" size="sm" id={`resend-button`} onClick={handleResendClick} color="success">
								<i className="fa fa-thumbs-up" /> Resend
							</Button>
							<Button className="pull-right" size="sm" id={`archive-button`} onClick={handleArchiveClick} color="secondary">
								<i className="fa fa-archive" /> Archive
							</Button>
						</ButtonGroup>
						<UncontrolledTooltip delay={0} target={`archive-button`}>
							Note: This does not archive the member
						</UncontrolledTooltip>
					</>
				) : (
					<></>
				)}
				<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
					Details
				</CardTitle>
				{displayError != null ? (
					<Alert
						color={errorStatus}
						toggle={() => {
							setErrorStatus('danger');
							setDisplayError(null);
						}}
					>
						{displayError}
					</Alert>
				) : (
					<></>
				)}
				<FormGroup row key={`notifications-details-overlay-created`}>
					<Label for={`notifications-details-overlay-created`} sm={3}>
						<strong>Created</strong>
					</Label>
					<Col sm={9}>{data != null && data.created != null ? (data as any).created : ''}</Col>
				</FormGroup>
				<FormGroup row key={`notifications-details-overlay-to`}>
					<Label for={`notifications-details-overlay-to`} sm={3}>
						<strong>To</strong>
					</Label>
					<Col sm={9}>{data != null && data.to != null ? data.to : ''}</Col>
				</FormGroup>
				<FormGroup row key={`notifications-details-overlay-cc`}>
					<Label for={`notifications-details-overlay-cc`} sm={3}>
						<strong>Cc</strong>
					</Label>
					<Col sm={9}>{data != null && data.cc != null ? data.cc : ''}</Col>
				</FormGroup>
				<FormGroup row key={`notifications-details-overlay-subject`}>
					<Label for={`notifications-details-overlay-subject`} sm={3}>
						<strong>Subject</strong>
					</Label>
					<Col sm={9}>{data != null && data.subject != null ? data.subject : ''}</Col>
				</FormGroup>
				<FormGroup row key={`notifications-details-overlay-body`}>
					<Label for={`notifications-details-overlay-body`} sm={3}>
						<strong>Body</strong>
					</Label>
					<Col sm={9}>
						<div dangerouslySetInnerHTML={{ __html: data != null && data.body != null ? data.body : '' }} />
					</Col>
				</FormGroup>
				{data != null && data.source_type != null && data.source_id != null && (
					<FormGroup row key={`notifications-details-overlay-source`}>
						<Label for={`notifications-details-overlay-source`} sm={3}>
							<strong>Source</strong>
						</Label>
						<Col sm={9}>
							<a
								href={`/administrator/${
									data.source_type === 'administrator-user'
										? 'administrators/users'
										: data.source_type === 'member'
										? 'member'
										: data.source_type === 'report'
										? 'report'
										: ''
								}/${data.source_id}`}
								target="_blank"
							>
								{data.source_type === 'administrator-user'
									? 'Administrator'
									: data.source_type === 'member'
									? 'Member'
									: data.source_type === 'report'
									? 'Report'
									: ''}
							</a>
						</Col>
					</FormGroup>
				)}
			</div>
		</Modal>
	);
};
