import React from 'react';

import { Input } from 'reactstrap';
import { SettingsDataField, SettingsDataFieldAttributeOption } from 'api/settings/general';

interface Props {
	settingsDataField: SettingsDataField;
	value: string | number;
	fieldName: string;
	onChange: (newValue: string | number) => void;
}

export const SelectField = (props: Props) => {
	const { settingsDataField, value, fieldName, onChange } = props;
	const { description, attributes } = settingsDataField;

	return (
		<Input
			type="select"
			name={fieldName}
			id={fieldName}
			placeholder={description}
			onChange={(e) => onChange(e.target.value)}
			defaultValue={value != null ? value : ''}
		>
			<option value={null as any}>- Select Option -</option>
			{attributes.options != null &&
				attributes.options.map((option) => {
					return (
						<option key={option.uid} value={option.uid}>
							{option.name}
						</option>
					);
				})}
		</Input>
	);
};
