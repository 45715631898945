// Node modules
import * as Sentry from '@sentry/react';

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import { FailedToFetchException } from '../exceptions/FailedToFetchException';

// Helpers
import { AuthMember, retryUnauthorizedRequestAfterRefresh } from '..';

export default class Frontend {
	async getMessage(): Promise<string> {
		try {
			const response = await fetch(`/api/v1/frontend/login-message`, {
				method: 'GET',
				headers: new Headers({}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj.message;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async check(primaryID: string, secondaryID: string): Promise<{ token: string; verificationCodeEmail: string }> {
		try {
			const response = await fetch(`/api/v1/frontend/login`, {
				method: 'POST',
				headers: new Headers({
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify({
					bsaId: primaryID,
					dob: secondaryID,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async verify(token: string, code: string): Promise<AuthMember> {
		try {
			const response = await fetch(`/api/v1/frontend/validate`, {
				method: 'POST',
				headers: new Headers({
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify({
					token: token,
					code: code,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				throw new UnauthorizedException(respObj.message);
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async getProfile(token: string): Promise<Array<{ name: string; value: string }>> {
		try {
			const response = await fetch(`/api/v1/frontend/profile`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				throw new UnauthorizedException('Request response retry returned unauthorized');
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async getProfileClearances(token: string): Promise<Array<{ uid: string; name: string; readonly: boolean }>> {
		try {
			const response = await fetch(`/api/v1/frontend/profile/clearances`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				throw new UnauthorizedException('Request response retry returned unauthorized');
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async getClearanceSettings(
		token: string,
	): Promise<Array<{ docID: string; name: string; certification_date: string; approved?: boolean | undefined; readonly?: boolean | undefined }>> {
		try {
			const response = await fetch(`/api/v1/frontend/clearances`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				throw new UnauthorizedException('Request response retry returned unauthorized');
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async uploadClearance(token: string, file: FormData): Promise<void> {
		try {
			const response = await fetch(`/api/v1/frontend/profile/clearances`, {
				method: 'POST',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
				body: file,
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				throw new UnauthorizedException('Request response retry returned unauthorized');
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async downloadClearanceFile(token: string, docID: string): Promise<{ filename: string; buffer: Blob }> {
		try {
			const response = await fetch(`/api/v1/frontend/clearances/${docID}`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			if (response.status === 200) {
				let filename = '';
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers.get('content-disposition')!);
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, '');
				}

				return { filename: filename, buffer: await response.blob() };
			} else if (response.status === 400) {
				const respObj = await response.json();
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				throw new UnauthorizedException('Request response retry returned unauthorized');
			} else if (response.status === 403) {
				const respObj = await response.json();
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				const respObj = await response.json();
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				const respObj = await response.json();
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}
}
