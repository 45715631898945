import React from 'react';

import { Input, FormGroup, Col } from 'reactstrap';
import { SettingsNotificationsListEntryRecipientCondition } from 'api/settings/notifications';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	data: SettingsNotificationsListEntryRecipientCondition;
	conditionsData: Array<SettingsNotificationsListEntryRecipientCondition>;
	clearanceFields: Array<SettingsClearancesType>;
	onChange: (fieldName: keyof SettingsNotificationsListEntryRecipientCondition, newValue: any) => void;
}

export const FieldTypeClearance = (props: Props) => {
	const { data, conditionsData, clearanceFields, onChange } = props;

	if (data == null) {
		return <></>;
	}

	return (
		<>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name={`logicOperator`}
						id={`logicOperator`}
						onChange={(e) => {
							onChange('logicOperator', e.target.value);
						}}
						defaultValue={data.logicOperator != null ? data.logicOperator : ''}
					>
						<option value="equal-to">Equal to</option>
						<option value="not-equal-to">Not equal to</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name="value_type"
						id="value_type"
						onChange={(e) => {
							onChange('value_type', e.target.value);
						}}
						defaultValue={data != null && data.value_type != null ? data.value_type : ''}
						disabled={true}
					>
						<option value="user">User</option>
						<option value="value">Value</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name={`value`}
						id={`value`}
						onChange={(e) => {
							onChange('value', e.target.value);
						}}
						value={data.value != null ? data.value : clearanceFields != null ? clearanceFields[0].uid : ''}
					>
						{clearanceFields != null && clearanceFields.length > 0 ? (
							clearanceFields.map((field) => {
								return (
									<option
										key={field.uid}
										value={field.uid}
										disabled={conditionsData != null && conditionsData.filter((condition) => condition.field === field.uid)[0] != null}
									>
										{field.name}
									</option>
								);
							})
						) : (
							<></>
						)}
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}></Col>
		</>
	);
};
