import React, { useState } from 'react';

import { UncontrolledTooltip, Input } from 'reactstrap';
import { AdministratorRoleCategory, AdministratorRoleDB, AdministratorRolePermission } from 'api/administrators/roles';

interface Props {
	onChange: (roleID: string, permissionID: string, value: boolean) => void;
	categories: Array<AdministratorRoleCategory>;
	role: AdministratorRolePermission;
	data: { [key: string]: boolean };
}

export const RoleRecord = (props: Props) => {
	const { onChange, categories, role, data } = props;

	return (
		<tr>
			<td>{role.name}</td>
			{categories != null && categories.length > 0 ? (
				categories.map((category, catIndex) => {
					const permissionObjs = role.permissions.filter((role) => role.category_id === category.id);

					return (
						<td key={catIndex} className="text-center" width={`${Math.round((100 - 15) / categories.length)}`}>
							{permissionObjs != null && permissionObjs.length > 0 ? (
								permissionObjs.map((permissionObj, perObjIndex) => {
									return (
										<div key={`${catIndex}-${perObjIndex}`}>
											<Input
												type={'checkbox'}
												id={`role-${role.id}-category-${category.id}-${catIndex}-${perObjIndex}`}
												onChange={(e) => {
													onChange(role.id, permissionObj.id, e.target.checked);
												}}
												style={{ marginLeft: '0px', position: 'static' }}
												checked={data != null && data[permissionObj.id] != null ? true : false}
											/>
											<UncontrolledTooltip delay={0} target={`role-${role.id}-category-${category.id}-${catIndex}-${perObjIndex}`}>
												{permissionObj.name}
											</UncontrolledTooltip>
										</div>
									);
								})
							) : (
								<></>
							)}
						</td>
					);
				})
			) : (
				<></>
			)}
			<td key={`permissions-section-category-other`} className="text-center">
				{role.permissions != null ? (
					role.permissions.map((permissionObj, perObjIndex) => {
						if (permissionObj.category_id == null || permissionObj.category_id === '') {
							return (
								<div key={`other-${perObjIndex}`}>
									<Input
										type={'checkbox'}
										id={`role-${role.id}-category-other-${perObjIndex}`}
										onChange={(e) => {
											onChange(role.id, permissionObj.id, e.target.checked);
										}}
										style={{ marginLeft: '0px', position: 'static' }}
										checked={data != null && data[permissionObj.id] != null ? true : false}
									/>
									<UncontrolledTooltip delay={0} target={`role-${role.id}-category-other-${perObjIndex}`}>
										{permissionObj.name}
									</UncontrolledTooltip>
								</div>
							);
						}
					})
				) : (
					<></>
				)}
			</td>
		</tr>
	);
};
