import React from 'react';

import { FieldConditionSelector } from './FieldConditionSelector';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	handleFieldChange: (newValue: any) => void;
	dataField: SettingsDataField;
	inputValue: string | object | number;
}

export const GroupField = (props: Props) => {
	const { dataField, inputValue, handleFieldChange } = props;
	const { attributes } = dataField;

	// Check if this supports multiple values or not
	if (attributes.multiple === true) {
		/**
		 * This function will handle the change of the input select
		 */
		const handleInputFieldChange = (fieldName: string, newValue: any) => {
			const newVal = { ...(inputValue as object) };
			(newVal as any)[fieldName] = newValue;

			handleFieldChange(newVal);
		};

		return (
			<>
				{(attributes as any).options.map((groupField: any, key: any) => {
					return (
						<span key={`report-group-field-${groupField.uid}`}>
							<FieldConditionSelector
								index={key}
								dataField={groupField}
								value={inputValue != null ? (inputValue as any)[groupField.uid] : null}
								onUpdate={(newValue) => {
									handleInputFieldChange(groupField.uid, newValue);
								}}
							/>
						</span>
					);
				})}
			</>
		);
	} else {
		return <>Not currently supported</>;
	}
};
