import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { Alert, Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupText, InputGroup, FormGroup, Label } from 'reactstrap';
import toast from 'react-hot-toast';

import { frontend } from '../../api';
import { validateDate, validateInt } from '../../validator';
import { FaRegCalendarAlt, FaSpinner, FaUser } from 'react-icons/fa';
import { FailedToFetchException } from 'api/exceptions/FailedToFetchException';

interface Props {
	onVerifyStepClick: (data: { bsaId?: string; dob?: string; loginToken?: string; verificationCodeEmail?: string }) => void;
}

export const CheckClearances = (props: Props) => {
	const { onVerifyStepClick } = props;
	const [checkButtonEnabled, setCheckButtonEnabled] = useState(false);
	const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
	const [messageState, setMessageState] = useState('info');
	const [message, setMessage] = useState(null);
	const [data, setData] = useState<{ bsaId?: string; dob?: string; loginToken?: string; verificationCodeEmail?: string }>({});
	const [loginMessage, setLoginMessage] = useState<string | null>(null);

	useEffect(() => {
		const getContents = async () => {
			try {
				const result = await frontend.getMessage();

				if (result != null) {
					setLoginMessage(result);
				}
			} catch (error) {
				if (error instanceof FailedToFetchException) {
					toast.error(error.message);
				} else {
					Sentry.captureException(error);
				}
			}
		};

		getContents();
	}, []);

	/**
	 * This function will check to make sure that we are good to submit the form anytime that data is changed
	 */
	useEffect(() => {
		let canSave = true;

		if (data.bsaId == null || data.bsaId === '') {
			canSave = false;
		}

		if (!validateInt(data.bsaId!)) {
			canSave = false;
		}

		if (data.dob == null || data.dob === '') {
			canSave = false;
		}

		if (!validateDate(data.dob!)) {
			canSave = false;
		}

		setCheckButtonEnabled(canSave);
	}, [data]);

	/**
	 * This function handles the update of data
	 * @param fieldName the field to udpate
	 * @param newValue the new data object
	 */
	const handleInputFieldChange = (fieldName: 'bsaId' | 'dob', newValue: any) => {
		const newData = { ...data };
		newData[fieldName] = newValue;
		setData(newData);
	};

	/**
	 * This function handles moving to the next step
	 */
	const handleCheckClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		Promise.resolve()
			.then(() => {
				setMessageState('info');
				setMessage(null);
				setIsSubmitInProgress(true);
			})
			.then(() => {
				return frontend.check(data.bsaId!, data.dob!);
			})
			.then((result) => {
				const newData = { ...data };
				newData.loginToken = result.token;
				newData.verificationCodeEmail = result.verificationCodeEmail;
				onVerifyStepClick(newData);
			})
			.catch((error) => {
				setMessageState('danger');
				setMessage(error.message);
				setIsSubmitInProgress(false);
			});
	};

	return (
		<Form action="" className="form" method="">
			<Card className="card-login">
				<CardHeader>
					<CardHeader>
						<h3 className="header text-center">PA Clearances Portal</h3>
					</CardHeader>
				</CardHeader>
				<CardBody>
					{message != null && (
						<Alert color={messageState}>
							<span>{message}</span>
						</Alert>
					)}
					{loginMessage != null && loginMessage.length > 0 && <p dangerouslySetInnerHTML={{ __html: loginMessage }} />}
					<FormGroup>
						<Label for="bsaId">BSA Member ID</Label>
						<InputGroup>
							<InputGroupText style={{ background: 'none' }}>
								<FaUser color="#66615b" />
							</InputGroupText>
							<Input
								id="bsaId"
								name="bsaId"
								type="number"
								placeholder="Enter BSA ID"
								onChange={(e) => handleInputFieldChange('bsaId', e.target.value)}
							/>
						</InputGroup>
					</FormGroup>
					<FormGroup>
						<Label for="dob">Date of Birth</Label>
						<InputGroup>
							<InputGroupText style={{ background: 'none' }}>
								<FaRegCalendarAlt color="#66615b" />
							</InputGroupText>
							<Input id="dob" type="date" placeholder="Date of Birth" onChange={(e) => handleInputFieldChange('dob', e.target.value)} />
						</InputGroup>
					</FormGroup>
				</CardBody>
				<CardFooter>
					<Button
						block
						className="btn-round mb-3"
						color="primary"
						type="button"
						disabled={isSubmitInProgress || (!isSubmitInProgress && !checkButtonEnabled)}
						onClick={(e) => handleCheckClick(e)}
					>
						{isSubmitInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Check'}
					</Button>
				</CardFooter>
			</Card>
		</Form>
	);
};
