import React from 'react';

import { Button, Input, FormGroup, Col, UncontrolledTooltip } from 'reactstrap';

import { ReportNotificationRecipientConditionDatafield } from 'api/reports';
import { SettingsDataField } from 'api/settings/general';
import { FieldConditionSelector } from 'components/DataFields/FieldConditionSelector/FieldConditionSelector';

interface Props {
	data: ReportNotificationRecipientConditionDatafield;
	onUpdate: (fieldName: keyof ReportNotificationRecipientConditionDatafield, newValue: any) => void;
	onDelete: () => void;
	settingsDataFields: Array<SettingsDataField>;
}

export const RecipientConditionDatafield = (props: Props) => {
	const { data, onUpdate, onDelete, settingsDataFields } = props;

	const key = 1;

	return (
		<>
			<Col md={4}>
				<FormGroup>
					<Input
						type="select"
						name="datafield_name"
						id="datafield_name"
						onChange={(e) => {
							onUpdate('datafield_name', e.target.value);
						}}
						value={data != null && data.datafield_name != null ? data.datafield_name : ''}
					>
						{settingsDataFields != null && settingsDataFields.length > 0 ? (
							<>
								{settingsDataFields.map((field) => {
									return (
										<option
											key={field.uid}
											value={field.uid}
											// disabled={conditionsData != null && conditionsData.filter((condition) => condition.field === field.uid)[0] != null}
										>
											{field.name}
										</option>
									);
								})}
								<option value={'member_group'}>Member Group</option>
							</>
						) : (
							<></>
						)}
					</Input>
				</FormGroup>
			</Col>
			<Col md={3}>
				<FormGroup>
					<Input
						type="select"
						name={`inputLogicOperator-${key}`}
						id={`inputLogicOperator-${key}`}
						onChange={(e) => onUpdate('logicOperator', e.target.value)}
						defaultValue={data.logicOperator}
					>
						<option value="equal-to">Equal to</option>
						<option value="contains">Contains</option>
						<option value="starts-with">Starts with</option>
						<option value="ends-with">Ends with</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={4}>
				<FormGroup>
					<FieldConditionSelector
						index={0}
						value={data.value}
						settingsDataField={settingsDataFields.filter((field) => field.uid === data.datafield_name)[0] || settingsDataFields[0]}
						onUpdate={(newValue) => onUpdate('value', newValue)}
					/>
				</FormGroup>
			</Col>
			<Col md={1}>
				<FormGroup className="pull-right">
					<Button
						className="btn-icon"
						color="danger"
						id={`notifications-section-recipient-overlay-condition-delete`}
						size="sm"
						type="button"
						onClick={onDelete}
					>
						<i className="fa fa-times" />
					</Button>
					<UncontrolledTooltip delay={0} target={`notifications-section-recipient-overlay-condition-delete`}>
						Remove
					</UncontrolledTooltip>
				</FormGroup>
			</Col>
		</>
	);
};
