import React, { useState } from 'react';

import { Label, Input, Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { PreProcessingStepOverlay } from './pre-processing-section/PreProcessingStepOverlay';
import { SettingsDataField } from 'api/settings/general';
import { MemberImportTemplateDB, MemberImportTemplateField, MemberImportTemplatePreProcessingStep } from 'api/members/templates';

interface Props {
	dynamicSettingsDataFields: Array<SettingsDataField>;
	handleInputFieldChange: (fieldName: keyof MemberImportTemplateDB, fieldValue: any, type?: string) => void;
	data: MemberImportTemplateDB;
}

export const PreProcessingSection = (props: Props) => {
	const { dynamicSettingsDataFields, handleInputFieldChange, data } = props;
	const [showFieldRowModal, setShowFieldRowModal] = useState(false);

	const handleShowFieldRowModalToggle = () => {
		// Toggle the modal display
		setShowFieldRowModal(!showFieldRowModal);
	};

	const handleRowRemoveClick = (uid: number) => {
		let newData = { ...data };

		if (newData.pre_processing_steps == null) {
			newData.pre_processing_steps = [];
		}

		newData.pre_processing_steps.splice(uid, 1);

		handleInputFieldChange('pre_processing_steps', newData.pre_processing_steps);
	};

	const handleFieldRowSave = (fieldRowData: MemberImportTemplatePreProcessingStep) => {
		let newData = { ...data };

		if (newData.pre_processing_steps == null) {
			newData.pre_processing_steps = [];
		}

		newData.pre_processing_steps.push(fieldRowData);
		handleInputFieldChange('pre_processing_steps', newData.pre_processing_steps);
	};

	return (
		<>
			<PreProcessingStepOverlay show={showFieldRowModal} onSave={handleFieldRowSave} onClose={handleShowFieldRowModalToggle} />
			<FormGroup row>
				<Col md={6}>
					<Label for="title">Steps</Label>
				</Col>
				<Col sm={12}>
					{data.pre_processing_steps != null && data.pre_processing_steps.length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left" style={{ width: '10%' }}>
										Step
									</th>
									<th className="text-left" style={{ width: '25%' }}>
										Title
									</th>
									<th className="text-left" style={{ width: '25%' }}>
										Excel Column
									</th>
									<th className="text-right" style={{ width: '40%' }}>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{data.pre_processing_steps.map((row, key) => {
									return (
										<tr key={`template-details-pre-processing-section-${key}`}>
											<td>{row.step}</td>
											<td>{row.title}</td>
											<td>{row.excelColumnName}</td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="success"
													id={`template-details-pre-processing-section-${key}-edit`}
													size="sm"
													type="button"
													disabled={true}
													// onClick={() => handleEditClick(key)}
												>
													<i className="fa fa-edit" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`template-details-pre-processing-section-${key}-edit`}>
													Edit
												</UncontrolledTooltip>
												<Button
													className="btn-icon"
													color="danger"
													id={`template-details-pre-processing-section-${key}-delete`}
													size="sm"
													type="button"
													onClick={() => handleRowRemoveClick(key)}
												>
													<i className="fa fa-times" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`template-details-pre-processing-section-${key}-delete`}>
													Delete
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No steps defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button
							className="btn-icon"
							id={`template-details-pre-processing-section-field-add`}
							color="info"
							size="sm"
							onClick={() => handleShowFieldRowModalToggle()}
							disabled={showFieldRowModal}
						>
							<i className="fa fa-plus" />
						</Button>
						<UncontrolledTooltip delay={0} target={`template-details-pre-processing-section-field-add`}>
							Add Step
						</UncontrolledTooltip>
					</div>
				</Col>
			</FormGroup>
		</>
	);
};
