import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ReportDetails } from './ReportDetails/ReportDetails';
import { ReportTable } from './ReportTable/ReportTable';

import './Reports.scss';

export const Reports = () => {
	return (
		<div className="content report-page">
			<Routes location={location}>
				<Route path={':uid/*'} element={<ReportDetails />} />
				<Route index element={<ReportTable />} />
			</Routes>
		</div>
	);
};
