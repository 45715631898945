import React, { useState, useEffect, useContext } from 'react';

import { Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { TableRecordDeletePrompt } from '../TableRecordDeletePrompt';
import { ClearanceRecordOverlay } from './ClearanceRecordOverlay';
import toast from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa';
import { SettingsClearancesDB, SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	data: SettingsClearancesDB;
	onSave: (newData: SettingsClearancesDB) => void;
}

export const TypeSettingSection = (props: Props) => {
	const { data: inputData, onSave } = props;
	const [data, setData] = useState<SettingsClearancesDB>(
		inputData != null ? inputData : { max_upload_size: 0, member_groups: [], types_list: [], valid_file_extensions: [] },
	);
	const [detailsData, setDetailsData] = useState<SettingsClearancesType | null>(null);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [showRecordDeleteConfirm, setShowRecordDeleteConfirm] = useState(false);
	const [tableRecordDeleteId, setTableRecordDeleteId] = useState<string | null>(null);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	/**
	 * This function will handle the toggle of the type add/edit overlay
	 */
	const handleTableRecordDetailsModalToggle = () => {
		setShowDetailsModal(!showDetailsModal);
	};

	/**
	 * This function will trigger the add/edit overlay and pass in the data to edit
	 * @param {*} id
	 */
	const handleTableRecordEditClick = (record: SettingsClearancesType) => {
		setDetailsData(record);
		handleTableRecordDetailsModalToggle();
	};

	/**
	 * This function will update the table record (clearance type settings) data from the caller (model)
	 * @param {*} inputData
	 */
	const handleTableRecordDetailsSave = async (inputData: SettingsClearancesType) => {
		let newData = data;

		return new Promise((resolve, reject) => {
			let update = null;

			// First we need to check if the uid already exists. If it does, this is a duplicate or an update
			for (const [index, record] of data.types_list.entries()) {
				if (record.uid === inputData.uid) {
					if (detailsData == null) {
						// This is an add
						// Record already exists. Don't continue.
						reject('Record already exists');
						return;
					} else {
						// This is an update
						update = index;
					}
				}
			}

			if (update == null) {
				// This is an add
				newData.types_list.push(inputData);
			} else {
				// This is an update
				newData.types_list[update] = inputData;
			}

			resolve(null);
		}).then(() => {
			setData(newData);
			setShowDetailsModal(!showDetailsModal);
		});
	};

	/**
	 * This function will trigger the confirmation overlay before deleting the row
	 * @param {*} id
	 */
	const handleTableRecordDeleteClick = (id: string) => {
		setTableRecordDeleteId(id);
		setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
	};

	/**
	 * This function will remove the record from the table and save the result to the react state
	 */
	const handleTableRecordDeleteOverlayConfirm = () => {
		let newData = data;

		const entryRecord = newData.types_list.findIndex((entry) => entry.uid === tableRecordDeleteId);

		if (entryRecord > -1) {
			newData.types_list[entryRecord].archived = new Date();
		}

		return Promise.resolve().then(() => {
			setData(newData);
			setTableRecordDeleteId(null);
			setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
		});
	};

	/**
	 * This function will cancel/close the confirmation overlay and not remove a record
	 */
	const handleTableRecordDeleteOverlayCancel = () => {
		setTableRecordDeleteId(null);
		setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
	};

	const handleSaveClick = async () => {
		setFormSaveInProgress(true);
		try {
			await onSave(data);
		} catch (error) {
			// TODO: Handle better
			console.error('error');
			console.error(error);
		} finally {
			setFormSaveInProgress(false);
		}
	};

	return (
		<div className="clearance-settings-upload-section">
			<ClearanceRecordOverlay
				key={`clearance-type-record-${detailsData == null ? 'add' : `edit-${detailsData.uid}`}`}
				show={showDetailsModal}
				editRecord={detailsData!}
				onClose={() => handleTableRecordDetailsModalToggle()}
				onSave={handleTableRecordDetailsSave}
			/>
			<TableRecordDeletePrompt
				show={showRecordDeleteConfirm}
				onConfirm={() => {
					handleTableRecordDeleteOverlayConfirm();
				}}
				onCancel={() => {
					handleTableRecordDeleteOverlayCancel();
				}}
			>
				This action cannot be undone.
			</TableRecordDeletePrompt>
			<FormGroup row>
				<Col sm={12}>
					{data.types_list != null && data.types_list.filter((entry) => entry.archived == null).length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left">Name</th>
									<th className="text-right">Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.types_list
									.filter((entry) => entry.archived == null)
									.map((row, key) => {
										const { uid, name, attributes } = row;
										const { canDelete, canModify } = attributes;

										return (
											<tr key={`clearance-settings-type-field-${uid}`}>
												<td>{name}</td>
												<td className="text-right">
													<Button
														className="btn-icon"
														color="success"
														id={`clearance-settings-type-field-${key}-edit`}
														size="sm"
														type="button"
														disabled={canModify != null && canModify !== true ? true : false}
														onClick={() => handleTableRecordEditClick(row)}
													>
														<i className="fa fa-edit" />
													</Button>{' '}
													<UncontrolledTooltip delay={0} target={`clearance-settings-type-field-${key}-edit`}>
														Edit
													</UncontrolledTooltip>
													<Button
														className="btn-icon"
														color="danger"
														id={`clearance-settings-type-field-${key}-delete`}
														size="sm"
														type="button"
														disabled={canDelete != null && canDelete !== true ? true : false}
														onClick={() => handleTableRecordDeleteClick(uid)}
													>
														<i className="fa fa-times" />
													</Button>{' '}
													<UncontrolledTooltip delay={0} target={`clearance-settings-type-field-${key}-delete`}>
														Delete
													</UncontrolledTooltip>
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No fields defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button
							className="btn-icon"
							id={`clearance-settings-type-field-add`}
							color="info"
							size="sm"
							onClick={() => handleTableRecordDetailsModalToggle()}
						>
							<i className="fa fa-plus" />
						</Button>
						<UncontrolledTooltip delay={0} target={`clearance-settings-type-field-add`}>
							Add Row
						</UncontrolledTooltip>
					</div>
				</Col>
			</FormGroup>
			<FormGroup check row>
				<Col sm={{ size: 10, offset: 2 }}>
					<Button color="primary" onClick={() => handleSaveClick()} disabled={formSaveInProgress}>
						{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
					</Button>
				</Col>
			</FormGroup>
		</div>
	);
};
