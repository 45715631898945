import React from 'react';

interface Props {}

export const FrontendNavbar = (props: Props) => {
	return (
		<header className="fixed-top bsa-topbar">
			<nav id="top-nav" className="navbar flex-wrap navbar-expand-lg p-0">
				<div id="desktop-scouting-menu" className="collapse col-12 bg-blue">
					<div className="d-flex justify-content-end">
						<ul className="navbar-nav">
							<li className="nav-item scouting-desktop-toggler-hide">
								<a
									data-toggle="collapse"
									data-target="#desktop-scouting-menu"
									aria-controls="desktop-scouting-menu"
									aria-label="Toggle Scouting.org Menu"
									className="nav-link text-white"
								>
									<i className="fa fa-chevron-up"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="col-12 mb-auto d-flex flex-wrap align-items-center justify-content-between upper">
					{/* <!-- Navbar Brand --> */}
					<a href={process.env.REACT_APP_CLIENT_HOME_PAGE} className="p-0 navbar-brand">
						<img alt="BSA Logo" data-src="/logo-lg.svg" className="d-none d-lg-block ls-is-cached lazyloaded" src="/logo-lg.svg" />
						<img
							alt="BSA Logo"
							data-src="/logo.svg"
							className="d-lg-none lazyload"
							src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
						/>
					</a>
					<div className="text-logo d-none d-lg-block">
						<h5>Boy Scouts of America</h5>
						<h6 className="mb-0">{process.env.REACT_APP_CLIENT_NAME}</h6>
					</div>
					{/* <!-- Desktop Menu --> */}
					<div id="desktop-menu" className="collapse navbar-collapse ml-auto justify-content-end">
						<ul id="menu-main-menu" className="navbar-nav">
							<li id="menu-item-842" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-842 nav-item">
								<a href={process.env.REACT_APP_CLIENT_HOME_PAGE} className=" nav-link ">
									Home
								</a>
							</li>
						</ul>
					</div>
				</div>

				{/* <!-- Mobile --> */}
				<div className="col-12 d-flex d-lg-none justify-content-end align-items-center lower">
					<div className="h-100 d-flex align-items-center justify-content-between buttons">
						<a data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" className="ml-auto text-white mobile-menu-toggler">
							<i className="material-icons align-middle">menu</i>
						</a>
					</div>
				</div>
				<div id="mobile-menu" className="collapse">
					<ul id="mobile-main-menu" className="navbar-nav">
						<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-809 nav-item">
							<a href={process.env.REACT_APP_CLIENT_HOME_PAGE} className=" nav-link ">
								Home
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</header>
	);
};
