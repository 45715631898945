// Node modules

// Types
import { SettingsDataField } from 'api/settings/general';

// Exceptions
import { InvalidParametersException } from 'api/exceptions/InvalidParametersException';

// Helpers

export function generateConditionsString(detailsField: SettingsDataField, detailsFieldValue: any): string {
	const { attributes } = detailsField;

	const displayValues: Array<any> = [];
	let fieldValues = [];

	// Check if there is a value to process
	if (detailsFieldValue == null) {
		// There is no value to process, just return
		return '';
	}

	if (attributes == null || attributes.multiple == null || attributes.multiple === false) {
		// Convert the single field into a multiple for processing purposes
		fieldValues.push(detailsFieldValue);
	} else if (Array.isArray(detailsFieldValue)) {
		// Verified that the input is an array... lets just use it
		fieldValues = detailsFieldValue;
	} else {
		throw new InvalidParametersException(`generateConditionsString(): Unknown detailsFieldValue (${detailsFieldValue}) passed in for ${detailsField.uid}`);
	}

	// Loop through all of the values
	fieldValues.map((fieldValue) => {
		if (attributes.type != null) {
			if (attributes.type === 'string') {
				// Nothing needs to be done to format the value
				if (fieldValue !== null) {
					displayValues.push(fieldValue);
				}
			} else if (attributes.type === 'date') {
				if (fieldValue != null && fieldValue.length > 0) {
					const parts = fieldValue.toString().match(/^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/);

					if (parts) {
						// mm-dd-yyyy
						displayValues.push(`${parts[2]}/${parts[3]}/${parts[1]}`);
					}
				}
			} else if (attributes.type === 'int') {
				// Nothing needs to be done to format the value
				displayValues.push(fieldValue);
			} else if (attributes.type === 'dropdown') {
				if (attributes.options != null) {
					const selectedOption = attributes.options.filter((option) => option.uid === fieldValue)[0];

					if (selectedOption != null) {
						displayValues.push(selectedOption.name);
					} else {
						displayValues.push('- Not Selected -');
					}
				}
			} else if (attributes.type === 'email') {
				// Nothing needs to be done to format the value
				displayValues.push(fieldValue);
			} else if (attributes.type === 'group') {
				if (attributes.options != null) {
					const groupDisplayValues: Array<any> = [];

					attributes.options.map((childField) => {
						if (fieldValue[childField.uid] !== null) {
							groupDisplayValues.push(generateConditionsString(childField as SettingsDataField, fieldValue[childField.uid]));
						} else {
							groupDisplayValues.push('Any');
						}
					});

					displayValues.push(groupDisplayValues.join(' / '));
				}
			} else {
				throw new InvalidParametersException(`generateConditionsString(): Unknown detailsField type for ${detailsField.uid}`);
			}
		}
	});

	if (displayValues == null || displayValues.length === 0) {
		return '';
	}

	return `${displayValues.reduce((prev, curr) => [prev, ', ', curr])}`;
}
