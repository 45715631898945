"use strict";
// Node modules
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToKebabCase = exports.validateInt = exports.validateString = exports.convertToDateObject = exports.validateISODate = exports.validateDate = exports.validateEmailAddress = void 0;
// Exceptions
const _1 = require("./");
/**
 * Validates input string to determine if it is in the format of an email address
 * @param {string} inputStr
 */
function validateEmailAddress(inputStr) {
    const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (tester.test(inputStr)) {
        return true;
    }
    return false;
}
exports.validateEmailAddress = validateEmailAddress;
/**
 * Validates input string to determine if it is in the format of a date
 * @param {string} inputStr
 * @param {string} dateFormatStr
 */
function validateDate(inputStr, dateFormatStr = `YYYY-MM-DD`) {
    let tester = null;
    if (dateFormatStr === 'YYYY-MM-DD') {
        tester = /^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/;
    }
    else if (dateFormatStr === 'MM-DD-YYYY') {
        tester = /^(\d{2})[-/]?(\d{2})[-/]?(\d{4})$/;
    }
    if (tester == null) {
        console.log(`Validator.validateDate() tester is null`);
        return false;
    }
    if (tester.test(inputStr)) {
        return true;
    }
    return false;
}
exports.validateDate = validateDate;
/**
 * Validates input string to determine if it is in the format of a date
 * @param {string} inputStr
 */
function validateISODate(inputStr) {
    const tester = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
    if (tester.test(inputStr)) {
        const date = new Date(inputStr);
        if (date.toISOString() === inputStr) {
            return true;
        }
    }
    return false;
}
exports.validateISODate = validateISODate;
/**
 * Validates input string to determine if it is in the format of a date
 * @param {string} inputStr
 * @param {string} dateFormatStr
 */
function convertToDateObject(inputStr, currentDateFormatStr = `YYYY-MM-DD`) {
    let date = null;
    if (inputStr == null) {
        throw new _1.InvalidContentException(`Invalid input provided`);
    }
    if (currentDateFormatStr === 'YYYY-MM-DD') {
        const parts = inputStr.match(/^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/);
        if (parts == null) {
            throw new _1.InvalidContentException(`Invalid date format provided`);
        }
        // Format: Year, Month, Day, Hour, Minute, Second
        date = new Date(Date.UTC(Number(parts[1]), Number(parts[2]) - 1, Number(parts[3]), 0, 0, 0));
    }
    else if (currentDateFormatStr === 'MM-DD-YYYY') {
        const parts = inputStr.match(/^(\d{2})[-/]?(\d{2})[-/]?(\d{4})$/);
        if (parts == null) {
            throw new _1.InvalidContentException(`Invalid date format provided "${inputStr}" "${currentDateFormatStr}"`);
        }
        // Format: Year, Month, Day, Hour, Minute, Second
        date = new Date(Date.UTC(Number(parts[3]), Number(parts[1]) - 1, Number(parts[2]), 0, 0, 0));
    }
    if (date == null) {
        console.log('Validator.convertToDateObject() date is null');
    }
    return date;
}
exports.convertToDateObject = convertToDateObject;
/**
 * Validates input string to determine if it is in the format of a string
 * @param {string} inputStr
 */
function validateString(inputStr) {
    let tester = /^[\w\s\d-'\)\(\.]*$/;
    if (tester.test(inputStr)) {
        return true;
    }
    return false;
}
exports.validateString = validateString;
/**
 * Validates input string to determine if it is in the format of an integer
 * @param {string} inputStr
 */
function validateInt(inputStr) {
    let tester = /^[\d]*$/;
    if (tester.test(inputStr)) {
        return true;
    }
    return false;
}
exports.validateInt = validateInt;
/**
 * Converts a string to kebab case (hyphen case)
 * @param inputStr the input string
 * @returns the kebab-cased string
 */
function convertToKebabCase(inputStr) {
    // Regex from https://www.geeksforgeeks.org/how-to-convert-a-string-into-kebab-case-using-javascript/
    return inputStr
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
}
exports.convertToKebabCase = convertToKebabCase;
