import React from 'react';
import * as Sentry from '@sentry/react';

import { InputField } from './InputField';
import { SelectField } from './SelectField';
import { GroupField } from './GroupField';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	onUpdate: (newValue: any) => void;
	index: number;
	dataField: SettingsDataField;
	value: string | number | object;
}

export const FieldConditionSelector = (props: Props) => {
	const { index, dataField, value, onUpdate } = props;
	const { uid, attributes } = dataField;
	const fieldName = `column-attributes-section-details-overlay-condition-selector-${index}-${uid}`;

	if (attributes == null) {
		Sentry.captureMessage(`Attributes is null for ${dataField.uid}`);
		return <></>;
	}

	// Figure out what type of field we need to handle
	if (attributes.type != null) {
		if (attributes.type === 'string') {
			return <InputField dataField={dataField} type={'text'} inputValue={value} handleFieldChange={onUpdate} />;
		} else if (attributes.type === 'date') {
			let newValue: string | number | object = '';
			if (value != null && (value as any).length > 0) {
				const parts = value.toString().match(/^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/);

				if (parts) {
					// yyyy-mm-ddd
					newValue = `${parts[1]}-${parts[2]}-${parts[3]}`;
				}
			} else {
				newValue = '';
			}

			return <InputField dataField={dataField} type={'date'} inputValue={newValue} handleFieldChange={onUpdate} />;
		} else if (attributes.type === 'int') {
			const handleNumberChange = (newValue: any) => {
				let numberValue = newValue;
				if (typeof numberValue !== 'number') {
					numberValue = parseInt(numberValue);
				}

				onUpdate(numberValue);
			};

			return <InputField dataField={dataField} type={'number'} inputValue={value} handleFieldChange={handleNumberChange} />;
		} else if (attributes.type === 'dropdown') {
			let selectedValue = '';

			if (attributes.options != null) {
				const selectedOption = attributes.options.filter((option) => option.uid === value)[0];

				if (selectedOption != null) {
					selectedValue = selectedOption.uid;
				}
			}

			return <SelectField dataField={dataField} selectedValue={selectedValue} handleFieldChange={onUpdate} />;
		} else if (attributes.type === 'email') {
			return <InputField dataField={dataField} type={'text'} inputValue={value!} handleFieldChange={onUpdate} />;
		} else if (attributes.type === 'group') {
			return <GroupField dataField={dataField} inputValue={value} handleFieldChange={onUpdate} />;
		} else {
			// TODO: Implement
		}
	}

	return null;
};
