import { LogicOperator } from '@mouseware/lib-core';
import { ReportDB, ReportNotificationCCRecipient, ReportNotificationToRecipient, report_output_format, report_type } from 'api/reports';
import { validateEmailAddress } from '../../../validator';

export const validateReportSettings = (report: ReportDB): Array<{ field: keyof ReportDB; message: string }> => {
	const errors: Array<{ field: keyof ReportDB; message: string }> = [];

	if (report.title === '') {
		errors.push({ field: 'title', message: 'Title is not defined' });
	}

	if (report.type == null || ![report_type.AD_HOC, report_type.RECURRING].includes(report.type)) {
		errors.push({ field: 'type', message: report.type == null ? 'Type is not defined' : 'Invalid type' });
	}

	if (report.outputFormat == null || ![report_output_format.PDF, report_output_format.EXCEL].includes(report.outputFormat)) {
		errors.push({ field: 'outputFormat', message: report.outputFormat == null ? 'Output format is not defined' : 'Invalid output format' });
	}

	if (report.recipients != null && report.recipients.length > 0 && (report.subject == null || report.subject.length === 0)) {
		errors.push({ field: 'subject', message: 'Notification subject not defined' });
	}

	if (report.recipients != null && report.recipients.length > 0 && (report.body == null || report.body.length === 0)) {
		errors.push({ field: 'body', message: 'Notification body not defined' });
	}

	if (report.recipients != null && report.recipients.length > 0) {
		for (const recipient of report.recipients) {
			const errs = validateRecipientAttrs(recipient);

			if (errs.length > 0) {
				for (const err of errs) {
					errors.push({ field: 'recipients', message: err.message });
				}
			}
		}
	}

	if (report.columns == null || report.columns.length === 0) {
		errors.push({ field: 'columns', message: 'No columns defined' });
	}

	return errors;
};

export const validateRecipientAttrs = (
	recipient: ReportNotificationToRecipient | ReportNotificationCCRecipient
): Array<{ field: keyof ReportNotificationToRecipient | keyof ReportNotificationCCRecipient; message: string }> => {
	const errors: Array<{ field: keyof ReportNotificationToRecipient | keyof ReportNotificationCCRecipient; message: string }> = [];

	if (recipient.recipient === 'address' && (recipient.conditions == null || recipient.conditions.length === 0)) {
		errors.push({ field: 'recipient', message: 'Address not specified' });
	}

	if (recipient.conditions) {
		for (const condition of recipient.conditions) {
			if (condition.field_type === 'datafield') {
				// TODO: Implement
			} else if (condition.field_type === 'role') {
				// TODO: Implement
			} else if (condition.field_type === 'value') {
				if (condition.logicOperator !== LogicOperator.EQUAL_TO) {
					errors.push({ field: 'conditions', message: 'Invalid value condition' });
				} else if (!validateEmailAddress(condition.value as string)) {
					errors.push({ field: 'recipient', message: 'Invalid address specified' });
				}
			} else {
				errors.push({ field: 'conditions', message: 'Unknown condition type' });
			}
		}
	} else {
		errors.push({ field: 'conditions', message: 'No conditions defined' });
	}

	return errors;
};
