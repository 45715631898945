import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { TemplateDetails } from './template-helpers/TemplateDetails';
import { ListTemplates } from './template-helpers/ListTemplates';
import { TemplateUpload } from './template-helpers/TemplateUpload';
import { TemplateHistory } from './template-helpers/TemplateHistory/TemplateHistory';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	clearanceFields: Array<SettingsClearancesType>;
	dynamicSettingsDataFields: Array<SettingsDataField>;
	memberImportRootPath: string;
}

export const ImportTemplates = (props: Props) => {
	const { clearanceFields, dynamicSettingsDataFields, memberImportRootPath } = props;

	return (
		<div className="member-import-templates">
			<Routes>
				<Route
					path={`:templateID/upload/:importID?`}
					element={<TemplateUpload memberImportRootPath={memberImportRootPath} dynamicSettingsDataFields={dynamicSettingsDataFields} />}
				/>
				<Route path={`:templateID/history`} element={<TemplateHistory />} />
				<Route path={`:templateID/*`} element={<TemplateDetails dynamicSettingsDataFields={dynamicSettingsDataFields} />} />
				<Route index element={<ListTemplates clearanceFields={clearanceFields} dynamicSettingsDataFields={dynamicSettingsDataFields} />} />
			</Routes>
		</div>
	);
};
