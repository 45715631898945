import React from 'react';

import { Alert, FormGroup, Row, Col, Label, Input } from 'reactstrap';
import { SettingsClearancesType } from 'api/settings/clearances';
import { ReportColumn, ReportDB } from 'api/reports';

interface Props {
	onUpdate: (fieldName: keyof ReportColumn, newValue: any) => void;
	data: ReportColumn;
	settingsClearances: Array<SettingsClearancesType>;
}

export const ClearanceTab = (props: Props) => {
	const { onUpdate, settingsClearances, data } = props;

	return (
		<>
			<Row>
				<Col md={5}>
					<FormGroup>
						<Label for="clearanceColumn">Clearance</Label>
						<Input
							type={'select'}
							name={'clearanceColumn'}
							id={'clearanceColumn'}
							onChange={(e) => {
								onUpdate('column', e.target.value);
							}}
							defaultValue={(data.column != null ? data.column : null) as any}
						>
							<option value={''}>- Select Clearance -</option>
							{settingsClearances != null && settingsClearances.length > 0 ? (
								settingsClearances.map((item) => {
									return (
										<option key={`column-attributes-section-details-overlay-clearance-option-${item.uid}`} value={item.uid}>
											{item.name}
										</option>
									);
								})
							) : (
								<></>
							)}
						</Input>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup>
						<Label for="show">Show</Label>
						<Input
							type="select"
							name="show"
							id="show"
							onChange={(e) => {
								onUpdate('show', e.target.value);
							}}
							defaultValue={(data.show != null ? data.show : true) as any}
						>
							<option value={true as any}>Yes</option>
							<option value={false as any}>No</option>
						</Input>
					</FormGroup>
				</Col>
				<Col md={5}>
					<FormGroup>
						<Label for="name">Override Name</Label>
						<Input
							type="text"
							name="name"
							id="name"
							onChange={(e) => {
								onUpdate('name', e.target.value);
							}}
							value={data.name != null ? data.name : ''}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<FormGroup>
						<Label for="conditions">Conditions</Label>
						<Alert color="secondary">
							<span>Conditions not available</span>
						</Alert>
					</FormGroup>
				</Col>
			</Row>
		</>
	);
};
