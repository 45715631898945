"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Events = void 0;
var Events;
(function (Events) {
    Events["AUTH_LOGIN_ADMIN"] = "auth.login.admin";
    Events["AUTH_LOGIN_MEMBER"] = "auth.login.member";
    Events["AUTH_PASSWORD_REQUEST_RESET"] = "auth.password.request-reset";
    Events["AUTH_PASSWORD_RESET"] = "auth.password.reset";
    Events["MEMBER_ADD"] = "member.add";
    Events["MEMBER_UPDATE"] = "member.update";
    Events["MEMBER_ARCHIVE"] = "member.archive";
    Events["MEMBER_UNARCHIVE"] = "member.unarchive";
    Events["DOCUMENT_ADD"] = "document.add";
    Events["DOCUMENT_UPDATE"] = "document.update";
    Events["DOCUMENT_APPROVE"] = "document.approve";
    Events["DOCUMENT_DENY"] = "document.deny";
    Events["DOCUMENT_EXPIRING"] = "document.expiring";
    Events["DOCUMENT_EXPIRED"] = "document.expired";
    Events["SETTINGS_UPDATE"] = "setting.update";
})(Events || (exports.Events = Events = {}));
