import React from 'react';

import { Footer } from '../../components/Footer/Footer';

interface Props {
	children: React.ReactNode;
}

export const FullPage = (props: Props) => {
	return (
		<div className="wrapper wrapper-full-page">
			<div className="full-page section-image">
				{props.children}
				<Footer fluid />
			</div>
		</div>
	);
};
