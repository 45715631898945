import React, { useState } from 'react';

import { Badge, Button } from 'reactstrap';

import { TabDataEditMode } from './TabDataEditMode';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	detailsField: SettingsDataField;
	inputValue: any;
	handleFieldChange: (fieldName: string, newValue: any, dataType?: string, isPrimaryField?: boolean) => void;
	handleInputFieldMultipleAddMode: (detailsFieldUID: string, state: boolean) => void;
}

export const TabDataEditModeGroupField = (props: Props) => {
	const { detailsField, inputValue, handleFieldChange, handleInputFieldMultipleAddMode } = props;
	const { attributes } = detailsField;
	const [inAddMode, setInAddMode] = useState(false);
	const [addModeValue, setAddModeValue] = useState({});

	// This is a local variable of the inputValue prop... processing it here
	let parentInputValue = inputValue;

	// Check if this supports multiple values or not
	if (attributes.multiple === true) {
		// Make sure we have a valid default value
		if (parentInputValue == null) {
			parentInputValue = [];
		}

		/**
		 * This function will handle the pill "X" click
		 * @param {*} e
		 * @param {*} valObjId
		 */
		const handleRemovePillClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, valObjId: any) => {
			e.preventDefault();

			(handleFieldChange as any)(parentInputValue.filter((val: any) => val !== valObjId));
		};

		/**
		 * This function will toggle the add pill mode
		 */
		const handleToggleAddPillModeClick = () => {
			if (inAddMode === true) {
				setAddModeValue({});
				setInAddMode(false);
				handleInputFieldMultipleAddMode(detailsField.uid, false);
			} else {
				setInAddMode(true);
				handleInputFieldMultipleAddMode(detailsField.uid, true);
			}
		};

		/**
		 * This function will handle the change of the input select
		 */
		const handleInputFieldChange = (fieldName: string, newValue: any, dataType: string) => {
			let val = newValue;

			// TODO: Refactor this code... same as DetailsTab.handleInputFieldChange()
			if (dataType != null) {
				if (dataType === 'int') {
					if (Array.isArray(val)) {
						val = val.map((nonParsedVal) => {
							return parseInt(nonParsedVal, 10);
						});
					} else {
						val = parseInt(val, 10);
					}
				}
			}

			(addModeValue as any)[fieldName] = val;

			setAddModeValue({ ...addModeValue });
		};

		/**
		 * This function will process the selected value and add it to the parent value set
		 */
		const handleAddPillValueClick = () => {
			if (addModeValue != null) {
				parentInputValue.push(addModeValue);

				return Promise.resolve()
					.then(() => {
						return (handleFieldChange as any)(parentInputValue);
					})
					.then(() => {
						setAddModeValue({});
						setInAddMode(false);
						handleInputFieldMultipleAddMode(detailsField.uid, false);
					});
			}
		};

		return (
			<div className="tab-data-edit-mode-group-field">
				<div className="pill-section">
					{Array.isArray(parentInputValue) && parentInputValue.length > 0 ? (
						// TODO: Improve this implementation
						parentInputValue.map((valueObj, index) => {
							const groupDisplayValues: Array<string> = [];

							attributes.options!.map((groupField) => {
								if (groupField.attributes!.type === 'dropdown') {
									const selectedOption = groupField.attributes!.options!.filter((option) => option.uid === valueObj[groupField.uid])[0];

									if (selectedOption != null) {
										groupDisplayValues.push(selectedOption.name);
									}
								} else {
									groupDisplayValues.push(valueObj[groupField.uid]);
								}

								return null;
							});

							return (
								<Badge key={`tab-data-edit-mode-group-field-pill-badge-${index}`} className="pill" color="primary" pill>
									{groupDisplayValues.join(' ')}
									<Button className="close" color="link" onClick={(e) => handleRemovePillClick(e, valueObj)}>
										<i className="fa fa-close" />
									</Button>
								</Badge>
							);
						})
					) : (
						<div className="no-values">Click add to update this field</div>
					)}
					{inAddMode === true ? (
						<Button
							className="add-button"
							color="link"
							size="sm"
							onClick={() => {
								handleToggleAddPillModeClick();
							}}
						>
							Cancel
						</Button>
					) : (
						<Button
							className="add-button"
							color="link"
							size="sm"
							onClick={() => {
								handleToggleAddPillModeClick();
							}}
							disabled={false}
						>
							<i className="fa fa-plus" /> Add
						</Button>
					)}
				</div>
				<div className="add-section">
					{inAddMode === true ? (
						<>
							{attributes.options!.map((groupField) => {
								return (
									<span key={`tab-data-edit-mode-group-field-${groupField.uid}`}>
										<TabDataEditMode
											detailsField={groupField as SettingsDataField}
											detailsFieldValue={(addModeValue as any)[groupField.uid]}
											handleInputFieldChange={handleInputFieldChange as any}
											handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
										/>
									</span>
								);
							})}
							<Button
								className="add-button"
								color="primary"
								size="sm"
								onClick={() => {
									handleAddPillValueClick();
								}}
								disabled={addModeValue == null}
							>
								Add
							</Button>
						</>
					) : (
						<></>
					)}
				</div>
			</div>
		);
	} else {
		return <>Not currently supported</>;
	}
};
