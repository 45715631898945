import React from 'react';

import { Button } from 'reactstrap';

import { Dropzone } from '../../../../Dropzone';
import { FileWithAttributes } from 'api/tempTypes';

interface Props {
	validFileTypes: string | Array<string>;
	onDrop: (acceptedFiles: Array<FileWithAttributes>) => void;
	onBackButtonClick: () => void;
}

export const UploadDocument = (props: Props) => {
	const { validFileTypes, onDrop, onBackButtonClick } = props;

	return (
		<div className="upload-document">
			<Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)} validTypes={validFileTypes} />
			<div className="back-button">
				<Button color="link" size="sm" onClick={() => onBackButtonClick()}>
					Back
				</Button>
			</div>
		</div>
	);
};
