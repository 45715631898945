import React, { useState, useEffect, useContext } from 'react';

import { Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { TableRecordDeletePrompt } from '../TableRecordDeletePrompt';
import { MemberGroupOverlay } from './MemberGroupOverlay';
import { FaSpinner } from 'react-icons/fa';
import { MemberGroup, SettingsClearancesDB } from 'api/settings/clearances';

interface Props {
	data: SettingsClearancesDB;
	onSave: (newData: SettingsClearancesDB) => void;
}

export const MemberGroupSection = (props: Props) => {
	const [data, setData] = useState<SettingsClearancesDB>(
		props.data != null ? props.data : { max_upload_size: 0, member_groups: [], types_list: [], valid_file_extensions: [] }
	);
	const [detailsData, setDetailsData] = useState<MemberGroup | null>(null);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [showRecordDeleteConfirm, setShowRecordDeleteConfirm] = useState(false);
	const [tableRecordDeleteId, setTableRecordDeleteId] = useState<string | null>(null);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	/**
	 * This function will handle the toggle of the type add/edit overlay
	 */
	const handleTableRecordDetailsModalToggle = () => {
		setShowDetailsModal(!showDetailsModal);
	};

	/**
	 * This function will trigger the add/edit overlay and pass in the data to edit
	 * @param {*} id
	 */
	const handleTableRecordEditClick = (record: MemberGroup) => {
		setDetailsData(record);
		handleTableRecordDetailsModalToggle();
	};

	/**
	 * This function will update the table record (clearance type settings) data from the caller (model)
	 * @param {*} inputData
	 */
	const handleTableRecordDetailsSave = (inputData: MemberGroup) => {
		let newData = data;

		if (newData.member_groups == null) {
			newData.member_groups = [];
		}

		return new Promise((resolve, reject) => {
			let update = null;

			// First we need to check if the uid already exists. If it does, this is a duplicate or an update
			if (data.member_groups != null) {
				for (const [index, record] of data.member_groups.entries()) {
					if (record.uid === inputData.uid) {
						if (detailsData == null) {
							// This is an add
							// Record already exists. Don't continue.
							reject('Record already exists');
							return;
						} else {
							// This is an update
							update = index;
						}
					}
				}
			}

			if (update == null) {
				// This is an add
				newData.member_groups.push(inputData);
			} else {
				// This is an update
				newData.member_groups[update] = inputData;
			}

			resolve(null);
		}).then(() => {
			setData(newData);
			setShowDetailsModal(!showDetailsModal);
		});
	};

	/**
	 * This function will trigger the confirmation overlay before deleting the row
	 * @param {*} id
	 */
	const handleTableRecordDeleteClick = (id: string) => {
		setTableRecordDeleteId(id);
		setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
	};

	/**
	 * This function will remove the record from the table and save the result to the react state
	 */
	const handleTableRecordDeleteOverlayConfirm = () => {
		let newData = data;

		const entryRecord = newData.member_groups.findIndex((entry) => entry.uid === tableRecordDeleteId);

		if (entryRecord > -1) {
			newData.member_groups[entryRecord].archived = new Date();
		}

		return Promise.resolve().then(() => {
			setData(newData);
			setTableRecordDeleteId(null);
			setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
		});
	};

	/**
	 * This function will cancel/close the confirmation overlay and not remove a record
	 */
	const handleTableRecordDeleteOverlayCancel = () => {
		setTableRecordDeleteId(null);
		setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
	};

	const handleSaveClick = () => {
		return Promise.resolve()
			.then(() => {
				return setFormSaveInProgress(true);
			})
			.then(() => {
				return props.onSave(data);
			})
			.then(() => {
				return setFormSaveInProgress(false);
			});
	};

	return (
		<div className="member-group-section">
			<MemberGroupOverlay
				key={`member-group-record-${detailsData == null ? 'add' : `edit-${detailsData.uid}`}`}
				show={showDetailsModal}
				editRecord={detailsData!}
				clearanceList={data.types_list}
				onClose={() => handleTableRecordDetailsModalToggle()}
				onSave={handleTableRecordDetailsSave}
			/>
			<TableRecordDeletePrompt
				show={showRecordDeleteConfirm}
				onConfirm={() => {
					handleTableRecordDeleteOverlayConfirm();
				}}
				onCancel={() => {
					handleTableRecordDeleteOverlayCancel();
				}}
			>
				This action cannot be undone.
			</TableRecordDeletePrompt>
			<FormGroup row>
				<Col sm={12}>
					{data.member_groups != null && data.member_groups.length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left">Name</th>
									<th className="text-left">Default</th>
									<th className="text-right">Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.member_groups.map((row, key) => {
									const { uid, name, default: defaultGroup, attributes } = row;
									const { canDelete, canModify } = attributes;

									return (
										<tr key={`member-group-${uid}`}>
											<td>{name}</td>
											<td>{defaultGroup != null && defaultGroup === true ? 'Yes' : ''}</td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="success"
													id={`member-group-${key}-edit`}
													size="sm"
													type="button"
													disabled={canModify != null && canModify !== true ? true : false}
													onClick={() => handleTableRecordEditClick(row)}
												>
													<i className="fa fa-edit" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`member-group-${key}-edit`}>
													Edit
												</UncontrolledTooltip>
												<Button
													className="btn-icon"
													color="danger"
													id={`member-group-${key}-delete`}
													size="sm"
													type="button"
													disabled={canDelete != null && canDelete !== true ? true : false}
													onClick={() => handleTableRecordDeleteClick(uid)}
												>
													<i className="fa fa-times" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`member-group-${key}-delete`}>
													Delete
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No member groups defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button className="btn-icon" id={`member-group-add`} color="info" size="sm" onClick={() => handleTableRecordDetailsModalToggle()}>
							<i className="fa fa-plus" />
						</Button>
						<UncontrolledTooltip delay={0} target={`member-group-add`}>
							Add Row
						</UncontrolledTooltip>
					</div>
				</Col>
			</FormGroup>
			<FormGroup check row>
				<Col sm={{ size: 10, offset: 2 }}>
					<Button color="primary" onClick={() => handleSaveClick()} disabled={formSaveInProgress}>
						{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
					</Button>
				</Col>
			</FormGroup>
		</div>
	);
};
