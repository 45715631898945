import React, { useState, useEffect } from 'react';
import { Routes, Route, NavLink as RouterNavLink, generatePath, useParams, useNavigate, useLocation } from 'react-router-dom';

import { Form, Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { FullPageLoading } from '../../../FullPageLoading';

import { TableRecordDeletePrompt } from '../TableRecordDeletePrompt';
import { SenderRecordOverlay } from './sender-section-helpers/SenderRecordOverlay';
import { SettingsNotificationsSenderEntry } from 'api/settings/notifications';

interface Props {
	data: Array<SettingsNotificationsSenderEntry>;
	onChange: (newData: Array<SettingsNotificationsSenderEntry>) => void;
}

export const SenderSection = (props: Props) => {
	const { hash: locationHash, pathname: locationPathname, search: locationSearch } = useLocation();
	const { action: routerAction } = useParams<{ action: string }>();
	let navigate = useNavigate();

	const { data, onChange } = props;
	const [showTableRecordDetailsModal, setShowTableRecordDetailsModal] = useState(false);
	const [tableRecordDetailsData, setTableRecordDetailsData] = useState<SettingsNotificationsSenderEntry | null>(null);
	const [tableRecordDeleteId, setTableRecordDeleteId] = useState<string | null>(null);

	useEffect(() => {
		if (routerAction === 'add') {
			setShowTableRecordDetailsModal(true);
		}
	}, [routerAction]);

	const handleTableRecordDetailsModalToggle = (recordId?: string) => {
		// Cleanup the component props
		if (recordId != null) {
			const record = data.filter((sender) => sender.uid === recordId)[0];
			setTableRecordDetailsData(record);
		}

		// Toggle the modal display
		if (showTableRecordDetailsModal) {
			navigate('../sender');
		}

		setShowTableRecordDetailsModal(!showTableRecordDetailsModal);
	};

	const handleModalUrlUpdate = () => {
		// Update the url
		if (showTableRecordDetailsModal === false) {
			navigate('..');
		}
	};

	const handleTableRecordDeleteClick = (id: string) => {
		setTableRecordDeleteId(id);
	};

	const handleTableRecordDelete = () => {
		const newData = [...data];

		const rowIndex = newData.findIndex((record) => record.uid === tableRecordDeleteId);

		if (rowIndex >= 0) {
			newData.splice(rowIndex, 1);
		}

		onChange(newData);
		setTableRecordDeleteId(null);
	};

	const handleTableRecordDetailsSave = (inputData: SettingsNotificationsSenderEntry) => {
		let update = null;
		let newData = [...data];

		// Check if there is a uid
		if (inputData.uid == null) {
			// Generate a UID
			inputData.uid = inputData.name.replace(/\s+/g, '-').toLowerCase();
		}

		// First we need to check if the uid already exists. If it does, this is an update
		data.forEach((record, index) => {
			if (record.uid === inputData.uid) {
				// This is an update
				update = index;
			}
		});

		if (update == null) {
			// This is an add
			newData.push(inputData);
		} else {
			// This is an update
			newData[update] = inputData;
		}

		onChange(newData);
		handleTableRecordDetailsModalToggle();
	};

	return (
		<div className="notification-settings-sender-section">
			<SenderRecordOverlay
				key={`notification-sender-record-${tableRecordDetailsData == null ? 'add' : `edit-${tableRecordDetailsData.uid}`}`}
				show={showTableRecordDetailsModal}
				editRecord={tableRecordDetailsData!}
				onClose={() => handleTableRecordDetailsModalToggle()}
				onClosed={() => handleModalUrlUpdate()}
				onSave={handleTableRecordDetailsSave}
				senderList={
					data != null && data.length > 0
						? data.map((sender) => {
								return { uid: sender.uid, name: sender.name };
							})
						: []
				}
			/>
			<TableRecordDeletePrompt
				show={tableRecordDeleteId != null}
				onConfirm={() => handleTableRecordDelete()}
				onCancel={() => setTableRecordDeleteId(null)}
			>
				This action cannot be undone.
			</TableRecordDeletePrompt>
			<Form>
				<FormGroup row>
					<Col sm={12}>
						{data != null && data.length > 0 ? (
							<Table responsive style={{ marginBottom: 0 }}>
								<thead className="text-primary">
									<tr>
										<th className="text-left">Name</th>
										<th className="text-center">Default</th>
										<th className="text-center">Fallback To</th>
										<th className="text-right">Actions</th>
									</tr>
								</thead>
								<tbody>
									{data.map((row, key) => {
										const { uid, name, is_default, fallback_to } = row;

										return (
											<tr key={`notification-settings-sender-section-field-${uid}`}>
												<td>{name}</td>
												<td className="text-center">{is_default != null && is_default === true ? 'Yes' : 'No'}</td>
												<td className="text-center">{fallback_to != null ? fallback_to : ''}</td>
												<td className="text-right">
													<Button
														className="btn-icon"
														color="success"
														id={`notification-settings-sender-section-field-${key}-edit`}
														size="sm"
														type="button"
														disabled={true}
														onClick={() => handleTableRecordDetailsModalToggle(uid)}
													>
														<i className="fa fa-edit" />
													</Button>{' '}
													<UncontrolledTooltip delay={0} target={`notification-settings-sender-section-field-${key}-edit`}>
														Edit
													</UncontrolledTooltip>
													<Button
														className="btn-icon"
														color="danger"
														id={`notification-settings-sender-section-field-${key}-delete`}
														size="sm"
														type="button"
														onClick={() => handleTableRecordDeleteClick(uid)}
													>
														<i className="fa fa-times" />
													</Button>{' '}
													<UncontrolledTooltip delay={0} target={`notification-settings-sender-section-field-${key}-delete`}>
														Delete
													</UncontrolledTooltip>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						) : (
							<Alert color="secondary">
								<span>No senders defined</span>
							</Alert>
						)}

						<div className="pull-right">
							<Button tag={RouterNavLink} to={`add`} exact className="btn-icon" id={`general-settings-data-field-add`} color="info" size="sm">
								<i className="fa fa-plus" />
							</Button>
							<UncontrolledTooltip delay={0} target={`general-settings-data-field-add`}>
								Add Row
							</UncontrolledTooltip>
						</div>
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
