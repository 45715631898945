"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogicOperator = void 0;
/**
 * The values for logic operator in settings, reports, etc.
 */
var LogicOperator;
(function (LogicOperator) {
    LogicOperator["EQUAL_TO"] = "equal-to";
    LogicOperator["NOT_EQUAL_TO"] = "not-equal-to";
    LogicOperator["CONTAINS"] = "contains";
    LogicOperator["STARTS_WITH"] = "starts-with";
    LogicOperator["ENDS_WITH"] = "ends-with";
})(LogicOperator || (exports.LogicOperator = LogicOperator = {}));
