import React, { useContext, useEffect, useState } from 'react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';

import { Form, Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';
import toast from 'react-hot-toast';

import UserContext from '../../../UserContext';

import { member } from '../../../../api';
import { SettingsClearancesType } from 'api/settings/clearances';
import { SettingsDataField } from 'api/settings/general';
import { MemberImportTemplateDB } from 'api/members/templates';

interface Props {
	clearanceFields: Array<SettingsClearancesType>;
	dynamicSettingsDataFields: Array<SettingsDataField>;
}

export const ListTemplates = (props: Props) => {
	const { clearanceFields, dynamicSettingsDataFields } = props;
	const { hash: locationHash, pathname: locationPathname, search: locationSearch } = useLocation();
	const { jwt } = useContext(UserContext);

	const [data, setData] = useState<Array<MemberImportTemplateDB>>([]);

	useEffect(() => {
		const getContents = async () => {
			try {
				const response = await member.import.getTemplates(jwt);
				setData(response);
			} catch (err) {
				// TODO: Handle better
				console.error('error');
				console.error(err);
			}
		};

		getContents();
	}, []);

	const handleTableRecordArchiveClick = async (templateID: string) => {
		try {
			await member.import.templates.archive(jwt, templateID);

			const newData = [...data];

			const recordIndex = newData.findIndex((record) => record.docID === templateID);

			if (recordIndex > -1) {
				newData.splice(recordIndex, 1);
				setData(newData);
			}

			toast.success('Successfully archived template');
		} catch (err) {
			// TODO: Handle better
			console.error('error');
			console.error(err);
		}
	};

	return (
		<Form>
			<FormGroup row>
				<Col sm={12}>
					{data != null && data.length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left" style={{ width: '15%' }}>
										Name
									</th>
									<th className="text-left" style={{ width: '70%' }}>
										Fields
									</th>
									<th className="text-right" style={{ width: '15%' }}>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((row, key) => {
									const { docID, title, fields } = row;

									const fieldsList =
										fields != null
											? fields
													.map((field) => {
														const dynamicFieldName = dynamicSettingsDataFields.filter(
															(dynamicField) => dynamicField.uid === field.dynamicFieldUID,
														)[0];

														if (dynamicFieldName != null) {
															return `${dynamicFieldName.name} (${field.excelColumnName})`;
														}

														const clearanceFieldName = clearanceFields.filter(
															(clearance) => clearance.uid === field.dynamicFieldUID,
														)[0];

														if (clearanceFieldName != null) {
															return `${clearanceFieldName.name} (${field.excelColumnName})`;
														}

														if (field.dynamicFieldUID === 'member_group') {
															return `Member Group (${field.excelColumnName})`;
														}
													})
													.join(', ')
											: '';

									return (
										<tr key={`member-import-templates-list-templates-section-${docID}`}>
											<td>{title}</td>
											<td>{fieldsList}</td>
											<td className="text-right">
												<Button
													tag={RouterNavLink}
													to={`${locationPathname}/${docID}/upload`}
													exact
													className="btn-icon"
													color="success"
													id={`member-import-templates-list-templates-section-${key}-upload`}
													size="sm"
													type="button"
												>
													<i className="fa fa-upload" />
												</Button>
												<UncontrolledTooltip delay={0} target={`member-import-templates-list-templates-section-${key}-upload`}>
													Upload
												</UncontrolledTooltip>
												<Button
													tag={RouterNavLink}
													to={`${locationPathname}/${docID}/history`}
													exact
													className="btn-icon"
													color="secondary"
													id={`member-import-templates-list-templates-section-${key}-history`}
													size="sm"
													type="button"
												>
													<i className="fa fa-history" />
												</Button>
												<UncontrolledTooltip delay={0} target={`member-import-templates-list-templates-section-${key}-history`}>
													History
												</UncontrolledTooltip>
												<Button
													tag={RouterNavLink}
													to={`${locationPathname}/${docID}`}
													exact
													className="btn-icon"
													color="info"
													id={`member-import-templates-list-templates-section-${key}-edit`}
													size="sm"
													type="button"
												>
													<i className="fa fa-edit" />
												</Button>
												<UncontrolledTooltip delay={0} target={`member-import-templates-list-templates-section-${key}-edit`}>
													Edit
												</UncontrolledTooltip>
												<Button
													className="btn-icon"
													color="secondary"
													id={`member-import-templates-list-templates-section-${key}-archive`}
													size="sm"
													type="button"
													onClick={() => handleTableRecordArchiveClick(docID!)}
												>
													<i className="fa fa-archive" />
												</Button>
												<UncontrolledTooltip delay={0} target={`member-import-templates-list-templates-section-${key}-archive`}>
													Archive
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No templates defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button
							tag={RouterNavLink}
							to={`${locationPathname}/add`}
							exact
							className="btn-icon"
							id={`member-import-templates-add`}
							color="info"
							size="sm"
						>
							<i className="fa fa-plus" />
						</Button>
						<UncontrolledTooltip delay={0} target={`member-import-templates-add`}>
							Add Template
						</UncontrolledTooltip>
					</div>
				</Col>
			</FormGroup>
		</Form>
	);
};
