import React from 'react';

import { Input } from 'reactstrap';
import { SettingsDataField } from 'api/settings/general';
import { InputType } from 'reactstrap/types/lib/Input';

interface Props {
	handleFieldChange: (newValue: any) => void;
	dataField: SettingsDataField;
	inputValue: string | number | object;
	type: InputType;
}

export const InputField = (props: Props) => {
	const { dataField, inputValue, handleFieldChange, type } = props;
	const { description } = dataField;

	// This is a local variable of the inputValue prop... processing it here
	let parentInputValue = inputValue;

	// Make sure we have a valid default value
	if (parentInputValue == null) {
		parentInputValue = '';
	}

	return (
		<Input
			type={type}
			name={dataField.uid}
			id={dataField.uid}
			placeholder={description}
			onChange={(e) => {
				handleFieldChange(e.target.value);
			}}
			value={parentInputValue as any}
		/>
	);
};
