import React, { useState, useContext, useEffect } from 'react';

import { Table } from 'reactstrap';

import UserContext from '../../../../UserContext';
import { member } from '../../../../../api';
import { InvalidMemberDB, MemberImportDB } from 'api/members/import';
import { SettingsClearancesType } from 'api/settings/clearances';
import { SettingsDataField } from 'api/settings/general';
import { MemberDB } from 'api/members';

interface Props {
	tabID: string;
	data: MemberImportDB;
	clearanceSettingsFields: Array<SettingsClearancesType>;
	dynamicSettingsDataFields: Array<SettingsDataField>;
}

export const TableContents = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { tabID, clearanceSettingsFields, dynamicSettingsDataFields, data } = props;

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingError, setLoadingError] = useState(false);
	const [records, setRecords] = useState<Array<MemberDB | InvalidMemberDB>>([]);

	const dataColumns = Object.keys(data.template_fields!).filter((column) => {
		if (data.template_fields![column as any].attributes != null && (data.template_fields![column as any].attributes as any).type === 'group') {
			return true;
		} else {
			return (data.template_fields![column as any] as any).excelColumnNumber != null;
		}
	});

	useEffect(() => {
		const getContents = async () => {
			if (data.docID != null) {
				setIsLoading(true);

				try {
					if (tabID === 'add') {
						if (data.record_counts.add_records > 0) {
							const response = await member.import.getAddRecords(jwt, data.docID);
							setRecords(response);
						} else {
							setRecords([]);
						}
					} else if (tabID === 'update') {
						if (data.record_counts.update_records > 0) {
							const response = await member.import.getUpdateRecords(jwt, data.docID);
							setRecords(response);
						} else {
							setRecords([]);
						}
					} else if (tabID === 'conflict') {
						if (data.record_counts.conflict_records > 0) {
							const response = await member.import.getConflictRecords(jwt, data.docID);
							setRecords(response);
						} else {
							setRecords([]);
						}
					} else if (tabID === 'skip') {
						if (data.record_counts.skip_records > 0) {
							const response = await member.import.getSkipRecords(jwt, data.docID);
							setRecords(response);
						} else {
							setRecords([]);
						}
					} else if (tabID === 'bad') {
						if (data.record_counts.bad_records > 0) {
							const response = await member.import.getBadRecords(jwt, data.docID);
							setRecords(response);
						} else {
							setRecords([]);
						}
					} else if (tabID === 'archive') {
						if (data.record_counts.archive_records > 0) {
							const response = await member.import.getArchiveRecords(jwt, data.docID);
							setRecords(response);
						} else {
							setRecords([]);
						}
					} else if (tabID === 'unarchive') {
						if (data.record_counts.unarchive_records > 0) {
							const response = await member.import.getUnarchiveRecords(jwt, data.docID);
							setRecords(response);
						} else {
							setRecords([]);
						}
					} else {
						setLoadingError(true);
					}
				} catch (error) {
					console.error(error);
					setLoadingError(true);
				} finally {
					setIsLoading(false);
				}
			}
		};

		getContents();
	}, [tabID]);

	return (
		<Table responsive>
			<thead className="text-primary">
				<tr>
					{dataColumns.map((column) => {
						return (
							<th key={`record-header-${(data.template_fields![column as any] as any).settingsField.uid}`}>
								{(data.template_fields![column as any] as any).settingsField.name}
							</th>
						);
					})}
					{tabID === 'bad' ? <th key={`record-header-reason`}>Reason</th> : <></>}
				</tr>
			</thead>
			{loadingError ? (
				<tbody>
					<tr>
						<td colSpan={dataColumns.length}>There was an error loading data.</td>
					</tr>
				</tbody>
			) : isLoading ? (
				<tbody>
					<tr>
						<td colSpan={dataColumns.length}>Loading...</td>
					</tr>
				</tbody>
			) : (
				<tbody>
					{records != null && records.length > 0 ? (
						records.map((row, key) => {
							const originalRow = { ...row };
							if ((tabID === 'bad' || tabID === 'conflict') && row.contents != null) {
								row = row.contents;
							}

							return (
								<tr key={`record-content-${key}`}>
									{dataColumns.map((column) => {
										const recordData = (data.template_fields![column as any] as any).settingsField;
										let val = (row as any)[recordData.uid as any];

										if ((data.template_fields![column as any] as any).type === 'clearance') {
											if ((row as any)[recordData.uid] != null) {
												val = (row as any)[recordData.uid].map((clearance: any) => clearance.certification_date).join(', ');
											}
										} else {
											if (
												(data.template_fields![column as any] as any).settingsField.attributes != null &&
												(data.template_fields![column as any] as any).settingsField.attributes.type === 'group'
											) {
												val = JSON.stringify((row as any)[recordData.uid]);
											}
										}

										return <td key={`record-content-${column}-${recordData.uid}`}>{val !== null ? val : ' '}</td>;
									})}
									{tabID === 'bad' && originalRow.error != null ? (
										<td key={`record-content-reason-${key}`}>{originalRow.error.join(', ')}</td>
									) : tabID === 'conflict' && originalRow.error != null ? (
										<td key={`record-content-reason-${key}`}>{originalRow.error}</td>
									) : (
										<></>
									)}
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan={dataColumns.length}>No records found.</td>
						</tr>
					)}
				</tbody>
			)}
		</Table>
	);
};
