// Based on
// https://reacttricks.com/sharing-global-data-in-next-with-custom-app-and-usecontext-hook/
// https://stackoverflow.com/a/60701229/1017520
import { createContext } from 'react';

import { AuthUser } from 'api';

export interface UserContextObj {
	isUserAuthenticated: boolean;
	organization?: string;
	uid?: string;
	jwt: string;
	user?: AuthUser;

	setDisplayUserLoginOverlay: (state: boolean, callback?: () => void) => void;
	setUserCredentials: (userObj: { access: AuthUser; refresh: string }) => void;
	signIn: (user: AuthUser) => void;
}

export const UserContext = createContext<UserContextObj>({
	isUserAuthenticated: false,
	jwt: '',
	user: undefined,

	setDisplayUserLoginOverlay: () => {},
	setUserCredentials: () => {},
	signIn: () => {},
});

export default UserContext;
