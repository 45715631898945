import React from 'react';

import { Input, FormGroup, Col } from 'reactstrap';
import { FieldConditionSelector } from '../../../../../../../../../DataFields/FieldConditionSelector/FieldConditionSelector';
import { SettingsNotificationsListEntryRecipientCondition } from 'api/settings/notifications';
import { SettingsClearancesType } from 'api/settings/clearances';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	recipient: 'user' | 'any-member' | 'administrator';
	data: SettingsNotificationsListEntryRecipientCondition;
	conditionsData: Array<SettingsNotificationsListEntryRecipientCondition>;
	dynamicDataFields: Array<SettingsDataField>;
	onChange: (fieldName: keyof SettingsNotificationsListEntryRecipientCondition, newValue: any) => void;
}

export const FieldTypeDataField = (props: Props) => {
	const { recipient, data, conditionsData, dynamicDataFields, onChange } = props;

	if (data == null) {
		return <></>;
	}

	return (
		<>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name={`field`}
						id={`field`}
						onChange={(e) => {
							onChange('field', e.target.value);
						}}
						value={data != null && data.field != null ? data.field : ''}
					>
						{dynamicDataFields != null && dynamicDataFields.length > 0 ? (
							<>
								{dynamicDataFields.map((field) => {
									return (
										<option
											key={field.uid}
											value={field.uid}
											disabled={conditionsData != null && conditionsData.filter((condition) => condition.field === field.uid)[0] != null}
										>
											{field.name}
										</option>
									);
								})}
								<option value={'member-group'}>Member Group</option>
							</>
						) : (
							<></>
						)}
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name={`logicOperator`}
						id={`logicOperator`}
						onChange={(e) => {
							onChange('logicOperator', e.target.value);
						}}
						defaultValue={data != null && data.logicOperator != null ? data.logicOperator : ''}
					>
						<option value="equal-to">Equal to</option>
						<option value="contains" disabled={data.field != null && data.field === 'document-expiration'}>
							Contains
						</option>
						<option value="starts-with" disabled={data.field != null && data.field === 'document-expiration'}>
							Starts with
						</option>
						<option value="ends-with" disabled={data.field != null && data.field === 'document-expiration'}>
							Ends with
						</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name="value_type"
						id="value_type"
						onChange={(e) => {
							onChange('value_type', e.target.value);
						}}
						defaultValue={data != null && data.value_type != null ? data.value_type : ''}
					>
						<option value="user" disabled={recipient === 'user'}>
							User
						</option>
						<option value="value">Value</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}>
				<FormGroup>
					{data.value_type != null && data.value_type === 'user' ? (
						<Input
							type="select"
							name={`value`}
							id={`value`}
							onChange={(e) => {
								onChange('value', e.target.value);
							}}
							defaultValue={data.value != null ? data.value : ''}
						>
							{dynamicDataFields != null && dynamicDataFields.length > 0 ? (
								<>
									{dynamicDataFields.map((field) => {
										return (
											<option key={field.uid} value={field.uid}>
												{field.name}
											</option>
										);
									})}
									<option value={'member-group'}>Member Group</option>
								</>
							) : (
								<></>
							)}
						</Input>
					) : data.value_type != null && data.value_type === 'role' ? (
						<>Not supported</>
					) : (
						<FieldConditionSelector
							index={0}
							value={data.value}
							settingsDataField={dynamicDataFields.filter((field) => field.uid === data.field)[0]}
							onUpdate={(newValue) => {
								onChange('value', newValue);
							}}
						/>
					)}
				</FormGroup>
			</Col>
		</>
	);
};
