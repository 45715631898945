import React from 'react';

import { Input, Form, Label, Button, Col, FormGroup } from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SettingsGeneralDB } from 'api/settings/general';

interface Props {
	data: SettingsGeneralDB;
	onChange: (fieldName: keyof SettingsGeneralDB, value: any) => void;
}

export const CustomizationsSection = (props: Props) => {
	const { data, onChange } = props;

	return (
		<div className="general-settings-customizations-section">
			<Form>
				<FormGroup row>
					<Label for="frontendLoginMessage" sm={2}>
						Frontend Login Message
					</Label>
					<Col sm={10}>
						<CKEditor
							editor={ClassicEditor}
							config={{
								toolbar: [
									'heading',
									'|',
									'bold',
									'italic',
									'|',
									'link',
									'|',
									'outdent',
									'indent',
									'|',
									'bulletedList',
									'numberedList',
									'insertTable',
									'|',
									'blockQuote',
									'|',
									'undo',
									'redo',
								],
							}}
							data={data.frontendLoginMessage != null ? data.frontendLoginMessage : ''}
							onChange={(event: any, editor: any) => {
								const data = editor.getData();

								onChange('frontendLoginMessage', data);
							}}
						/>
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
