import React, { useState } from 'react';

import { Input, FormGroup, Label, Table, Button, Row, Col } from 'reactstrap';
import { SettingsDataFieldAttributeOption } from 'api/settings/general';

interface Props {
	editRecord: SettingsDataFieldAttributeOption;
	onFieldsUpdate: (newData: SettingsDataFieldAttributeOption) => void;
}

export const DataFieldsDropdownItems = (props: Props) => {
	const { onFieldsUpdate } = props;

	const [isAddMode, setIsAddMode] = useState(false);
	const [data, setData] = useState<SettingsDataFieldAttributeOption>(props.editRecord != null ? props.editRecord : { uid: '', name: '' });
	const [addNameFieldValue, setAddNameFieldValue] = useState('');

	const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAddNameFieldValue(e.target.value);
	};

	const handleToggleAddOptionButtonClick = () => {
		setIsAddMode(!isAddMode);
	};

	const onAddOptionSave = () => {
		const uid = addNameFieldValue.replace(/\s+/g, '-').toLowerCase();

		const newData = { uid, name: addNameFieldValue };

		return Promise.resolve()
			.then(() => {
				return setData(newData);
			})
			.then(() => {
				return onFieldsUpdate(newData);
			})
			.then(() => {
				setAddNameFieldValue('');
				setIsAddMode(false);
			});
	};

	return (
		<FormGroup>
			<Label for="fieldTypeDropdownItems">Dropdown Items</Label>
			<Table responsive>
				<thead>
					<tr>
						<th>Name</th>
					</tr>
				</thead>
				<tbody>
					<tr key={data.uid}>
						<td>{data.name}</td>
					</tr>
				</tbody>
			</Table>

			<div className="add-item">
				{isAddMode === true ? (
					<Row>
						<Col md={6}>
							<FormGroup>
								<Input
									type="text"
									name="inputName"
									id="inputName"
									placeholder="Name"
									onChange={(e) => handleFieldChange(e)}
									value={addNameFieldValue}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<Button color="button" size="sm" onClick={() => onAddOptionSave()}>
								Save
							</Button>
							<Button color="link" size="sm" onClick={() => handleToggleAddOptionButtonClick()}>
								Cancel
							</Button>
						</Col>
					</Row>
				) : (
					<Button color="link" size="sm" onClick={() => handleToggleAddOptionButtonClick()}>
						<i className="fa fa-plus" /> Add Option
					</Button>
				)}
			</div>
		</FormGroup>
	);
};
