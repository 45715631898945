"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionExecutionException = void 0;
/**
 * This exception is used when a process or action, like generating reports, fails
 */
class ActionExecutionException extends Error {
    /**
     * Optional parameter to include the content of the error
     */
    content;
    /**
     * Constructor
     * @param {string} message the error message
     * @param {object} content the content used for future debugging
     */
    constructor(message, content) {
        super(message);
        this.content = content;
        // If we support a stack trace, capture it
        if ('captureStackTrace' in Error) {
            Error.captureStackTrace(this, this.constructor);
        }
        else {
            console.error(`${this.constructor.name} Does not support captureStackTrace()!`);
        }
    }
    /**
     * Returns the content attribute of the class
     */
    getContent() {
        return this.content;
    }
}
exports.ActionExecutionException = ActionExecutionException;
