import React, { useState, useContext, useEffect } from 'react';
import { FaSpinner, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { Alert, Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupText, InputGroup, Container, Col, Row } from 'reactstrap';

import { login } from '../../api';
import UserContext from '../UserContext';

export const ForgotPassword = () => {
	const navigate = useNavigate();
	const { isUserAuthenticated } = useContext(UserContext);

	const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
	const [messageState, setMessageState] = useState('info');
	const [message, setMessage] = useState<string | null>(null);
	const [username, setUsername] = useState('');

	useEffect(() => {
		if (isUserAuthenticated === true) {
			navigate('/administrator/dashboard');
		}
	});

	const handleForgotPasswordFormAction = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		return new Promise((resolve, reject) => {
			// Check if we have valid input
			// TODO: Implement better checks
			if (username !== '') {
				resolve(null);
			} else {
				reject('Please enter your username');
			}
		})
			.then(() => {
				setMessageState('info');
				setMessage(null);
				return setFormSubmitInProgress(true);
			})
			.then(() => {
				login
					.forgotPassword(username)
					.then((data) => {
						setMessageState('info');
						setMessage('An email was sent to the address on file.');
						setUsername('');
					})
					.catch((e) => {
						console.error(e);
						setMessageState('danger');
						setMessage('Invalid information provided');
					});
			})
			.then(() => {
				return setFormSubmitInProgress(false);
			})
			.catch((e) => {
				setMessageState('danger');
				setMessage(e);
				setFormSubmitInProgress(false);
			});
	};

	return (
		<div className="full-page section-image">
			<div className="forgot-password-page">
				<Container>
					<Row className="justify-content-center align-items-center">
						<Col lg="4" md="6">
							<Form action="" className="form" method="" onSubmit={(e) => handleForgotPasswordFormAction(e)}>
								<Card className="card-login">
									<CardHeader>
										<CardHeader>
											<h3 className="header text-center">Forgot Password</h3>
										</CardHeader>
									</CardHeader>
									<CardBody>
										{message != null && (
											<Alert color={messageState}>
												<span>{message}</span>
											</Alert>
										)}
										<InputGroup>
											<InputGroupText style={{ background: 'none' }}>
												<FaUser color="#66615b" />
											</InputGroupText>
											<Input placeholder="Email Address" type="text" onChange={(e) => setUsername(e.target.value)} />
										</InputGroup>
									</CardBody>
									<CardFooter>
										<Button
											type="submit"
											block
											className="btn-round mb-3"
											color="warning"
											onClick={(e) => handleForgotPasswordFormAction(e)}
											disabled={formSubmitInProgress === true}
										>
											{formSubmitInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Reset'}
										</Button>
										<div style={{ textAlign: 'center' }}>
											<a href="/administrator/login" style={{ color: '#000' }}>
												Login
											</a>
										</div>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</Container>
				<div
					className="full-page-background"
					style={{
						backgroundImage: `url(${process.env.REACT_APP_FRONTEND_BACKGROUND_IMAGE || '/img/bg/default-background.jpg'})`,
					}}
				/>
			</div>
		</div>
	);
};
