// Node modules
import * as Sentry from '@sentry/react';

// Helpers

// Child Classes
import Roles from './roles';
import Users from './users';

export default class Administrators {
	roles: Roles;
	users: Users;

	constructor() {
		const rolesActions = new Roles();
		this.roles = rolesActions;

		const usersActions = new Users();
		this.users = usersActions;
	}
}
