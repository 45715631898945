import { FaHome, FaWrench, FaCopy, FaRegIdCard, FaRegEnvelope, FaCog } from 'react-icons/fa';

import { Dashboard } from './components/Dashboard/Dashboard';
import { Members } from './components/Members/Members';
import { Notifications } from './components/Notifications/Notifications';
import { AdministratorUsers } from './components/Administrator/Users/Users';
import { AdministratorRoles } from './components/Administrator/Roles/Roles';
import { GeneralSettings } from './components/Settings/GeneralSettings';
import { ClearanceSettings } from './components/Settings/ClearanceSettings';
import { Logs } from './components/Settings/Logs';
import { NotificationSettings } from './components/Settings/NotificationSettings';
import { ReportSettings } from './components/Settings/ReportSettings';
import { Reports } from './components/Reports/Reports';
import { QuickApproval } from './components/QuickApproval';
import { Preferences } from './components/Preferences';
import { IconType } from 'react-icons';

export interface RouteEntry {
	path?: string;
	name: string;
	icon?: IconType;
	component?: React.ReactNode;
	collapse?: boolean;
	state?: string;
	display?: boolean;
	views?: Array<RouteEntry>;
	mini?: string;
}

const routes: Array<RouteEntry> = [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: FaHome,
		component: Dashboard as any,
	},
	{
		collapse: true,
		name: 'Administrators',
		icon: FaWrench,
		state: 'administratorsCollapse',
		views: [
			{
				path: '/administrators/users',
				name: 'Users',
				mini: 'U',
				component: AdministratorUsers as any,
			},
			{
				path: '/administrators/roles',
				name: 'Roles',
				mini: 'R',
				component: AdministratorRoles as any,
			},
		],
	},
	{
		path: '/member',
		name: 'Members',
		icon: FaRegIdCard,
		component: Members as any,
	},
	{
		path: '/notifications',
		name: 'Notifications',
		icon: FaRegEnvelope,
		component: Notifications as any,
	},
	{
		path: '/quick-approval',
		name: 'Quick Approval',
		icon: FaRegIdCard,
		component: QuickApproval as any,
		display: false,
	},
	{
		path: '/report',
		name: 'Reports',
		icon: FaCopy,
		component: Reports as any,
	},
	{
		collapse: true,
		name: 'Settings',
		icon: FaWrench,
		state: 'settingsCollapse',
		views: [
			{
				path: '/setting/general',
				name: 'General',
				mini: 'S',
				component: GeneralSettings as any,
			},
			{
				path: '/setting/clearance',
				name: 'Clearances',
				mini: 'C',
				component: ClearanceSettings as any,
			},
			{
				path: '/setting/log',
				name: 'Logs',
				mini: 'L',
				component: Logs as any,
			},
			{
				path: '/setting/notification',
				name: 'Notifications',
				mini: 'N',
				component: NotificationSettings as any,
			},
			{
				path: '/setting/report',
				name: 'Reports',
				mini: 'R',
				component: ReportSettings as any,
			},
		],
	},
	{
		path: '/preferences',
		name: 'Preferences',
		icon: FaCog,
		component: Preferences as any,
		display: false,
	},
];

export default routes;
