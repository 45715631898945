export class ResetPasswordCodeNotFoundException extends Error {
	constructor(message: string) {
		super(message);

		// capturing the stack trace keeps the reference to your error class
		if ('captureStackTrace' in Error) {
			Error.captureStackTrace(this, this.constructor);
		} else {
			console.error(`ResetPasswordCodeNotFoundException Does not support captureStackTrace()!`);
		}
	}
}
