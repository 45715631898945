import React, { useState, useEffect } from 'react';

import { Button, Modal, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

import { ClearanceTab } from './ClearanceTab/ClearanceTab';
import { DataFieldTab } from './DataFieldTab/DataFieldTab';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';
import { ReportColumn, ReportDB, report_frequency, report_output_format, report_type } from 'api/reports';

interface Props {
	show: boolean;
	onClose: () => void;
	onSave: (newValue: any) => void;
	editData?: ReportColumn;
	settingsDataFields: Array<SettingsDataField>;
	settingsClearances: Array<SettingsClearancesType>;
}

export const ColumnDetailsOverlay = (props: Props) => {
	const { show, onSave, onClose, editData, settingsDataFields, settingsClearances } = props;
	const isFieldEdit = editData != null ? true : false;

	const [canSave, setCanSave] = useState(false);
	const [data, setData] = useState<ReportColumn>(
		editData != null
			? editData
			: {
					type: 'field',
					column: '',
					conditions: [],
			  }
	);

	/**
	 * This function will check to see if a field has been passed in for editing
	 */
	useEffect(() => {
		if (editData != null) {
			setData(editData);
		}
	}, [editData]);

	/**
	 * This function will check to make sure we can save the form anytime that data is changed
	 */
	useEffect(() => {
		let canSave = false;

		if (data.column != null) {
			canSave = true;
		}

		setCanSave(canSave);
	}, [data]);

	/**
	 * This function handles closing the modal when the Cancel button or close is clicked
	 */
	const handleOnCloseClick = async () => {
		await setData(
			editData != null
				? editData
				: {
						type: 'field',
						column: '',
						conditions: [],
				  }
		);
		onClose();
	};

	/**
	 * This function handles the click of a tab pane
	 * @param e the event handler
	 * @param tabName the name of the tab being selected
	 */
	const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabName: 'field' | 'clearance') => {
		const newData = { ...data };
		newData.type = tabName;
		setData(newData);

		// We don't want to have the actual <a> perform an action
		e.preventDefault();
	};

	/**
	 * This function handles changing the value of any input fields
	 * @param fieldName the name of the field to be updated
	 * @param fieldValue the value that is to be saved
	 */
	const handleInputFieldChange = (fieldName: string, fieldValue: any) => {
		let newData = { ...data };
		(newData as any)[fieldName] = fieldValue;
		setData(newData);
	};

	/**
	 * This function handles saving the data when the Add/Save button is clicked
	 */
	const handleOnSaveClick = () => {
		// Since this component reuses data, need to make a copy to send to parent to actually save
		onSave({ ...data });

		// Reset data
		setData({ type: 'field', column: '', conditions: [] });
	};

	return (
		<Modal className="modal-lg" isOpen={show} toggle={() => handleOnCloseClick()}>
			<ModalHeader toggle={() => handleOnCloseClick()}>{isFieldEdit ? `Edit` : `Add`} Column</ModalHeader>
			<div className="modal-body">
				<div className="nav-tabs-navigation">
					<div className="nav-tabs-wrapper">
						<Nav id="tabs" role="tablist" tabs>
							<NavItem>
								<NavLink
									data-toggle="tab"
									href="#"
									role="tab"
									className={data.type === 'field' ? 'active' : ''}
									onClick={(e) => handleTabClick(e, 'field')}
								>
									Field
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									data-toggle="tab"
									href="#"
									role="tab"
									className={data.type === 'clearance' ? 'active' : ''}
									onClick={(e) => handleTabClick(e, 'clearance')}
								>
									Clearance
								</NavLink>
							</NavItem>
						</Nav>
					</div>
				</div>
				<TabContent activeTab={data.type}>
					<TabPane tabId="field" role="tabpanel">
						{data.type === 'field' ? <DataFieldTab onUpdate={handleInputFieldChange} settingsDataFields={settingsDataFields} data={data} /> : <></>}
					</TabPane>
					<TabPane tabId="clearance" role="tabpanel">
						{data.type === 'clearance' ? (
							<ClearanceTab onUpdate={handleInputFieldChange} settingsClearances={settingsClearances} data={data} />
						) : (
							<></>
						)}
					</TabPane>
				</TabContent>
			</div>
			<div className="modal-footer justify-content-center">
				<Button className="btn-round" color="primary" data-dismiss="modal" type="button" disabled={!canSave} onClick={() => handleOnSaveClick()}>
					{isFieldEdit ? `Update` : `Add`}
				</Button>
				<Button className="btn-round" color="secondary" data-dismiss="modal" type="button" onClick={() => handleOnCloseClick()}>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
