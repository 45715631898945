import React, { useState, useEffect, useContext } from 'react';
import { FaSpinner } from 'react-icons/fa';
import * as Sentry from '@sentry/react';

import { Card, CardHeader, CardBody, CardTitle, Row, Col, FormGroup, Button, Form, Label, Input } from 'reactstrap';
import toast from 'react-hot-toast';

import { preferences } from '../../api';
import UserContext from '../UserContext';

interface Props {}

export const Preferences = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const [data, setData] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [hasFormChanged, setHasFormChanged] = useState(false);

	/**
	 * This function will retrieve the notifications data from the backend
	 */
	useEffect(() => {
		const getContent = async () => {
			try {
				const response = await preferences.get(jwt);
				setData(response);
				setIsLoaded(true);
			} catch (err) {
				// TODO: Handle better
				console.error('error');
				console.error(err);
			}
		};

		getContent();
	}, []);

	/**
	 * This function handles changing any of the fields
	 * @param fieldName the name of the field to be updated
	 * @param value the new value to be saved
	 */
	const handleFieldChange = (fieldName: string, value: any, type?: string) => {
		const newData = { ...data };
		(newData as any)[fieldName] = value;

		if (type === 'boolean') {
			(newData as any)[fieldName] = value === 'true';
		} else {
			(newData as any)[fieldName] = value;
		}

		setData(newData);
		setHasFormChanged(true);
	};

	/**
	 * This function will save settings to the backend
	 */
	const handleSettingsSave = async () => {
		setFormSaveInProgress(true);
		try {
			await preferences.update(jwt, data);

			setHasFormChanged(false);
			toast.success('Changes successfully saved');
		} catch (err) {
			// TODO: Handle better
			console.error('error');
			console.error(err);
			Sentry.captureException(err);
			toast.error('Failed to save changes');
		} finally {
			setFormSaveInProgress(false);
		}
	};

	/**
	 * The render function for the React component
	 */
	return (
		<div className="content">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Preferences</CardTitle>
						</CardHeader>
						<CardBody>
							<Form>
								<FormGroup row>
									<Label for="notifications-administrator" sm={2}>
										Receive Administrator Notifications
									</Label>
									<Col sm={2}>
										<Input
											type="select"
											name="notifications-administrator"
											id="notifications-administrator"
											onChange={(e) => handleFieldChange('notifications-administrator', e.target.value, 'boolean')}
											value={(data as any)['notifications-administrator'] != null ? (data as any)['notifications-administrator'] : true}
										>
											<option value={true as any}>Yes</option>
											<option value={false as any}>No</option>
										</Input>
									</Col>
								</FormGroup>
							</Form>

							<hr />
							<FormGroup check row>
								<Col sm={{ size: 10, offset: 2 }}>
									<Button color="primary" onClick={() => handleSettingsSave()} disabled={!hasFormChanged || formSaveInProgress}>
										{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
									</Button>
								</Col>
							</FormGroup>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
