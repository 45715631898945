import React, { useState } from 'react';

import { UploadDocument } from './document-upload/UploadDocument';
import { SelectAttributes } from './document-upload/DocumentAttributes';
import { ConfirmUpload } from './document-upload/ConfirmUpload';
import { FileWithAttributes } from 'api/tempTypes';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	validFileTypes: string | Array<string>;
	clearanceSettings: Array<
		SettingsClearancesType | { docID: string; name: string; certification_date: string; approved?: boolean | undefined; readonly?: boolean | undefined }
	>;
	handleUploadFiles: (formData: FormData) => Promise<void>;
}

export const DocumentUpload = (props: Props) => {
	const { validFileTypes, clearanceSettings, handleUploadFiles } = props;
	const [uploadState, setUploadState] = useState(1);
	const [uploadedFiles, setUploadedFiles] = useState<Array<FileWithAttributes>>([]);

	/**
	 * This function moves us to the next state
	 */
	const advanceToNextStep = () => {
		setUploadState(uploadState + 1);
	};

	/**
	 * This function handles the uploaded file and stores it in the component state until
	 * we get some attributes to upload to the server
	 * @param {*} acceptedFiles
	 */
	const handleUploadDocument = (acceptedFiles: Array<FileWithAttributes>) => {
		if (acceptedFiles instanceof Array) {
			const file = acceptedFiles[0];
			file.attributes = {};
			const newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.push(file);

			setUploadedFiles(newUploadedFiles);
			advanceToNextStep();
		} else {
			console.error('Unknown acceptedFiles type', Object.prototype.toString.call(acceptedFiles));
		}
	};

	/**
	 * This function handles the attribute selection
	 */
	const handleAttributeSelect = (fileIndex: number, attribute: string, value: any) => {
		let newUploadedFiles = [...uploadedFiles];

		newUploadedFiles[fileIndex].attributes[attribute] = value;

		setUploadedFiles(newUploadedFiles);
	};

	/**
	 * This function uploads the file to the server and resets the state
	 */
	const handleConfirmUploadStepClick = async () => {
		const formData = new FormData();
		let attributes: any = [];
		uploadedFiles.forEach((file) => {
			let obj: any = {};
			obj[file.name] = file.attributes;

			attributes.push(obj);
		});

		formData.append('file-attributes', JSON.stringify(attributes));
		formData.append('files', uploadedFiles[0]);

		await handleUploadFiles(formData).then(() => {
			setUploadState(1);
			setUploadedFiles([]);
		});
	};

	return (
		<div className="document-upload">
			{uploadState === 1 ? (
				<UploadDocument
					key={'document-upload-upload-document'}
					onDrop={(acceptedFiles) => handleUploadDocument(acceptedFiles)}
					validFileTypes={validFileTypes}
				/>
			) : uploadState === 2 ? (
				<SelectAttributes
					key={'document-upload-document-attributes'}
					clearanceSettings={clearanceSettings as any}
					files={uploadedFiles}
					onNextStepClick={advanceToNextStep}
					onAttributeSelect={handleAttributeSelect}
				/>
			) : uploadState === 3 ? (
				<ConfirmUpload
					key={'document-upload-confirm-upload'}
					clearanceSettings={clearanceSettings as any}
					files={uploadedFiles}
					onConfirm={() => handleConfirmUploadStepClick()}
				/>
			) : (
				<></>
			)}
		</div>
	);
};
