import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Collapse } from 'reactstrap';
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from 'perfect-scrollbar';

import logo from '../../assets/img/react-logo.png';

import './Sidebar.scss';
import { RouteEntry } from 'routes';

// var ps: PerfectScrollbar;

interface Props {
	routes: Array<RouteEntry>;
}

export const Sidebar = (props: Props) => {
	const { hash: locationHash, pathname: locationPathname, search: locationSearch } = useLocation();
	const [state, setState] = useState<{ [key: string]: boolean }>({});

	// const ref = useRef<Element>();

	/**
	 * This creates the intial state of this component based on the collapse routes
	 * that it gets through props.routes
	 */
	const getCollapseStates = (routes: Array<RouteEntry>) => {
		let initialState = {};
		routes.map((prop, key) => {
			if (prop.collapse) {
				initialState = {
					[prop.state!]: getCollapseInitialState(prop.views!),
					...getCollapseStates(prop.views!),
					...initialState,
				};
			}
			return null;
		});
		return initialState;
	};

	useEffect(() => {
		getCollapseStates(props.routes);

		// if (navigator.platform.indexOf('Win') > -1) {
		// 	ps = new PerfectScrollbar(ref as any, {
		// 		suppressScrollX: true,
		// 		suppressScrollY: false,
		// 	});
		// }

		return () => {
			// we need to destroy the false scrollbar when we navigate
			// to a page that doesn't have this component rendered
			// if (navigator.platform.indexOf('Win') > -1) {
			// 	ps.destroy();
			// }
		};
	}, []);

	/**
	 * this verifies if any of the collapses should be default opened on a rerender of this component
	 * for example, on the refresh of the page,
	 * while on the src/views/forms/RegularForms.js - route /admin/regular-forms
	 */
	const getCollapseInitialState = (routes: Array<RouteEntry>) => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && getCollapseInitialState(routes[i].views!)) {
				return true;
			} else if (window.location.pathname.indexOf(routes[i].path!) !== -1) {
				return true;
			}
		}
		return false;
	};

	/**
	 * This function creates the links and collapses that appear in the sidebar (left menu)
	 */
	const createLinks = (routes: Array<RouteEntry>) => {
		return routes.map((prop, key) => {
			const { icon: Icon } = prop;

			if (prop.collapse) {
				var st: { [key: string]: boolean } = {};
				st[prop.state!] = !state[prop.state!];
				return (
					<li className={getCollapseInitialState(prop.views!) ? 'active' : ''} key={key}>
						<a
							href="#pablo"
							data-toggle="collapse"
							aria-expanded={state[prop.state!]}
							onClick={(e) => {
								e.preventDefault();
								setState(st);
							}}
						>
							{Icon !== undefined ? (
								<>
									<Icon size={20} />
									<span>
										{prop.name}
										<b className="caret" />
									</span>
								</>
							) : (
								<>
									<span className="sidebar-mini-icon">{prop.mini}</span>
									<span className="sidebar-normal">
										{prop.name}
										<b className="caret" />
									</span>
								</>
							)}
						</a>
						<Collapse isOpen={state[prop.state!]}>
							<ul className="nav">{createLinks(prop.views!)}</ul>
						</Collapse>
					</li>
				);
			}

			return (
				<li className={activeRoute('/administrator' + prop.path)} key={key}>
					<NavLink to={'/administrator' + prop.path}>
						{Icon !== undefined ? (
							<>
								<Icon size={20} />
								<p>{prop.name}</p>
							</>
						) : (
							<>
								<span className="sidebar-mini-icon">{prop.mini}</span>
								<span className="sidebar-normal">{prop.name}</span>
							</>
						)}
					</NavLink>
				</li>
			);
		});
	};

	/**
	 * Verifies if routeName is the one active (in browser input)
	 */
	const activeRoute = (routeName: string) => {
		return locationPathname.indexOf(routeName) > -1 ? 'active' : '';
	};

	return (
		<div className="sidebar">
			<div className="logo">
				<a href="#" className="simple-text logo-mini">
					<div className="logo-img">
						<img src={logo} alt="react-logo" />
					</div>
				</a>
				<a href="#" className="simple-text logo-normal">
					{process.env.REACT_APP_CLIENT_NAME_SHORT}
				</a>
			</div>

			<div className="sidebar-wrapper">
				<Nav>{createLinks(props.routes.filter((route) => route.display !== false))}</Nav>
			</div>
		</div>
	);
};
