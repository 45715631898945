import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { MembersImport } from './MembersImport';
import { MembersDisplay } from './MembersDisplay';

export const Members = () => {
	return (
		<div className="content members">
			<Routes location={location}>
				<Route path={'import/*'} element={<MembersImport />} />
				<Route index path={'*'} element={<MembersDisplay />} />
			</Routes>
		</div>
	);
};
