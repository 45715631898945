import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import { AdministratorRoleDB } from 'api/administrators/roles';
import { AdministratorUserDB } from 'api/administrators/users';
import { ButtonGroup, Button, Card, CardHeader, CardBody, CardTitle, Table, Row, Col, UncontrolledTooltip } from 'reactstrap';
import toast from 'react-hot-toast';

import { administrators } from '../../../../api';
import UserContext from '../../../UserContext';
import { ForbiddenException } from 'api/exceptions/ForbiddenException';

export const UsersTable = () => {
	const navigate = useNavigate();
	const { jwt } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingError, setLoadingError] = useState(false);
	const [data, setData] = useState<Array<AdministratorUserDB>>([]);
	const [roles, setRoles] = useState<Array<AdministratorRoleDB>>([]);

	async function loadList() {
		try {
			const data = await administrators.users.list(jwt);
			setData(data);

			const roles = await administrators.roles.list(jwt);
			setRoles(roles);
			setIsLoading(false);
		} catch (error) {
			if (error instanceof ForbiddenException) {
				toast.error('Unauthorized');
			} else {
				console.error(error);
				Sentry.captureException(error);
				setLoadingError(true);
			}
		}
	}

	useEffect(() => {
		loadList();
	}, []);

	const handleToolbarAddClick = () => {
		navigate({ pathname: `${location.pathname}/add` });
	};

	const handleRecordViewClick = (docID: string) => {
		navigate({ pathname: `${location.pathname}/${docID}` });
	};

	return (
		<div className="users-table">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<ButtonGroup className="pull-right">
								<Button className="btn-icon" id={`users-add-button`} color="info" onClick={() => handleToolbarAddClick()}>
									<i className="fa fa-plus" />
								</Button>
							</ButtonGroup>
							<UncontrolledTooltip delay={0} target={`users-add-button`}>
								Add User
							</UncontrolledTooltip>
							<CardTitle tag="h4">Users</CardTitle>
						</CardHeader>
						<CardBody>
							<Table responsive>
								<thead className="text-primary">
									<tr>
										<th>Name</th>
										<th>Email Address</th>
										<th>Role</th>
										<th className="text-right">Actions</th>
									</tr>
								</thead>
								<tbody>
									{loadingError ? (
										<tr>
											<td colSpan={4}>There was an error loading data.</td>
										</tr>
									) : isLoading ? (
										<tr>
											<td colSpan={4}>Loading...</td>
										</tr>
									) : (
										<>
											{data.length > 0 ? (
												data.map((record, key) => {
													let roleName = 'Unknown';

													if (record.role != null) {
														const roleObj = roles.filter((role) => role.docID === record.role)[0];

														if (roleObj != null) {
															roleName = roleObj.name;
														}
													}

													return (
														<tr key={key}>
															<td>{record.name}</td>
															<td>{record.email}</td>
															<td>{roleName}</td>
															<td className="text-right">
																<Button
																	className="btn-icon"
																	color={'info'}
																	id={`users-${record.docID.replace(/[\@\.]/g, '-')}-details`}
																	size="sm"
																	type="button"
																	onClick={() => handleRecordViewClick(record.docID)}
																>
																	<i className="fa fa-user" />
																</Button>
																<UncontrolledTooltip delay={0} target={`users-${record.docID.replace(/[\@\.]/g, '-')}-details`}>
																	View Details
																</UncontrolledTooltip>
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan={4}>No records found.</td>
												</tr>
											)}
										</>
									)}
								</tbody>
							</Table>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
