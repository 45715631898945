import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, NavLink as RouterNavLink, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Card, CardHeader, CardBody, CardTitle, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Button } from 'reactstrap';
import toast from 'react-hot-toast';
import { ForbiddenException } from 'api/exceptions/ForbiddenException';

import { GeneralPreferencesSection } from './partials/notification-settings/GeneralPreferencesSection';
import { NotificationSection } from './partials/notification-settings/NotificationSection';
import { SenderSection } from './partials/notification-settings/SenderSection';

import { setting } from '../../api';
import UserContext from '../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsNotificationsDB } from 'api/settings/notifications';

interface Props {}

export const NotificationSettings = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const [data, setData] = useState<SettingsNotificationsDB>({ from_name: '', senders: [], from_email: '', reply_to_email: '', notification_list: [] });
	const [isLoaded, setIsLoaded] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [hasFormChanged, setHasFormChanged] = useState(false);

	/**
	 * This function will retrieve the notifications data from the backend
	 */
	useEffect(() => {
		setting.notifications
			.get(jwt)
			.then((settings) => {
				if (settings.senders == null) {
					settings.senders = [];
				}

				setData(settings);
				setIsLoaded(true);
			})
			.catch((error) => {
				if (error instanceof ForbiddenException) {
					toast.error(error.message);
				} else {
					// TODO: Handle better
					console.error('error');
					console.error(error);
					Sentry.captureException(error);
				}
			});
	}, []);

	/**
	 * This function handles changing any of the fields
	 * @param fieldName the name of the field to be updated
	 * @param value the new value to be saved
	 */
	const handleFieldChange = (fieldName: keyof SettingsNotificationsDB, value: any, type?: string) => {
		const newData = { ...data };
		newData[fieldName] = value;

		if (type === 'boolean') {
			(newData as any)[fieldName] = value === 'true';
		} else {
			newData[fieldName] = value;
		}

		setData(newData);
		setHasFormChanged(true);
	};

	/**
	 * This function will save settings to the backend
	 */
	const handleSettingsSave = async () => {
		setFormSaveInProgress(true);
		try {
			const response = await setting.notifications.update(jwt, data);
			setHasFormChanged(false);
			toast.success('Successfully saved settings');
		} catch (error) {
			if (error instanceof ForbiddenException) {
				toast.error('You do not have permission to perform this action');
			} else {
				toast.error('Failed to save');
				Sentry.captureException(error);
			}
		} finally {
			setFormSaveInProgress(false);
		}
	};

	/**
	 * The render function for the React component
	 */
	return (
		<div className="content">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Notification Settings</CardTitle>
						</CardHeader>
						<CardBody>
							{isLoaded ? (
								<>
									<div className="nav-tabs-navigation">
										<div className="nav-tabs-wrapper">
											<Nav id="tabs" role="tablist" tabs>
												<NavItem key={`general-preference-nav-item`}>
													<NavLink tag={RouterNavLink} to={`general-preference`} end>
														General Preferences
													</NavLink>
												</NavItem>
												<NavItem key={`sender-nav-item`}>
													<NavLink tag={RouterNavLink} to={`sender`} end>
														Senders
													</NavLink>
												</NavItem>
												<NavItem key={`notification-nav-item`}>
													<NavLink tag={RouterNavLink} to={`notification`} end>
														Notifications
													</NavLink>
												</NavItem>
											</Nav>
										</div>
									</div>
									<TabContent>
										<TabPane>
											<Routes>
												<Route
													path={`general-preference/*`}
													element={<GeneralPreferencesSection data={data} onChange={handleFieldChange} />}
												/>
												<Route
													path={`sender/:action?`}
													element={
														<SenderSection data={data.senders} onChange={(newData) => handleFieldChange('senders', newData)} />
													}
												/>
												<Route
													path={`notification/:action?`}
													element={<NotificationSection data={data} onChange={handleFieldChange} />}
												/>
												<Route index element={<Navigate to={`/administrator/setting/notification/general-preference`} />} />
											</Routes>
										</TabPane>
									</TabContent>
									<hr />
									<FormGroup check row>
										<Col sm={{ size: 10, offset: 2 }}>
											<Button color="primary" onClick={() => handleSettingsSave()} disabled={!hasFormChanged || formSaveInProgress}>
												{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
											</Button>
										</Col>
									</FormGroup>
								</>
							) : (
								<FaSpinner size={12} color="#ccc" className="fa-spin" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
