import React from 'react';

import { Input, Form, Label, Col, FormGroup } from 'reactstrap';
import { SettingsNotificationsDB } from 'api/settings/notifications';

interface Props {
	data: SettingsNotificationsDB;
	onChange: (fieldName: keyof SettingsNotificationsDB, value: any, type?: string) => void;
}

export const GeneralPreferencesSection = (props: Props) => {
	const { data, onChange } = props;

	return (
		<div className="notification-settings-general-preferences-section">
			<FormGroup tag="fieldset" row>
				<legend className="col-form-label col-sm-4">General Preferences</legend>
			</FormGroup>
			<Form>
				<FormGroup row>
					<Label for="from_name" sm={2}>
						From Name
					</Label>
					<Col sm={10}>
						<Input
							type="text"
							name="from_name"
							id="from_name"
							placeholder="Enter From Name"
							value={data.from_name != null ? data.from_name : ''}
							onChange={(e) => {
								onChange('from_name', e.target.value);
							}}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="from_email" sm={2}>
						From Email
					</Label>
					<Col sm={10}>
						<Input
							type="text"
							name="from_email"
							id="from_email"
							placeholder="Enter From Email"
							value={data.from_email != null ? data.from_email : ''}
							onChange={(e) => {
								onChange('from_email', e.target.value);
							}}
						/>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="reply_to_email" sm={2}>
						Reply To
					</Label>
					<Col sm={10}>
						<Input
							type="text"
							name="reply_to_email"
							id="reply_to_email"
							placeholder="Enter Email Address"
							value={data.reply_to_email != null ? data.reply_to_email : ''}
							onChange={(e) => {
								onChange('reply_to_email', e.target.value);
							}}
						/>
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
