import React from 'react';

import { Button, Row, Alert, Col, FormGroup, Input } from 'reactstrap';

import { LogicOperator } from '@mouseware/lib-core';
import {
	ReportNotificationRecipientConditionAdministratorRole,
	ReportNotificationRecipientConditionDatafield,
	ReportNotificationRecipientConditionValue,
} from 'api/reports';
import { FaPlus } from 'react-icons/fa';
import { RecipientConditionDatafield } from './RecipientConditionDatafield';
import { RecipientConditionAdministratorRole } from './RecipientConditionAdministratorRole';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	recipient: 'any-member' | 'administrator';
	onUpdate: (newValue: any) => void;
	conditions?: Array<
		ReportNotificationRecipientConditionDatafield | ReportNotificationRecipientConditionAdministratorRole | ReportNotificationRecipientConditionValue
	>;
	settingsDataFields: Array<SettingsDataField>;
}

export const RecipientConditions = (props: Props) => {
	const { recipient, onUpdate, conditions, settingsDataFields } = props;

	const handleAddClick = () => {
		const newConditions = conditions != null ? [...conditions] : [];

		if (recipient === 'any-member') {
			newConditions.push({ field_type: 'datafield', datafield_name: settingsDataFields[0].uid, logicOperator: LogicOperator.EQUAL_TO, value: '' });
		} else if (recipient === 'administrator') {
			newConditions.push({ field_type: 'role', logicOperator: LogicOperator.EQUAL_TO, value: '' });
		}

		onUpdate(newConditions);
	};

	const handleRemoveClick = (index: number) => {
		const newConditions = conditions != null ? [...conditions] : [];
		newConditions.splice(index, 1);
		onUpdate(newConditions);
	};

	const handleFieldChange = (
		index: number,
		fieldName:
			| keyof ReportNotificationRecipientConditionDatafield
			| keyof ReportNotificationRecipientConditionValue
			| keyof ReportNotificationRecipientConditionAdministratorRole,
		newValue: any
	) => {
		const newConditions = conditions != null ? [...conditions] : [];
		(newConditions as any)[index][fieldName] = newValue;
		onUpdate(newConditions);
	};

	return (
		<>
			{conditions != null && conditions.length > 0 ? (
				conditions.map((row, key) => {
					return (
						<Row key={`notifications-section-recipient-overlay-condition-${key}`}>
							<Col md={1}>{key === 0 ? 'Where' : 'and'}</Col>
							<Col md={4}>
								<FormGroup>
									<Input
										type="select"
										name={`inputfield_type-${key}`}
										id={`inputfield_type-${key}`}
										onChange={(e) => {
											handleFieldChange(key, 'field_type', e.target.value);
										}}
										defaultValue={row.field_type}
									>
										{['any-member'].includes(recipient) && <option value="datafield">Field</option>}
										{['any-member'].includes(recipient) && <option value="clearance">Clearance</option>}
										{['administrator'].includes(recipient) && <option value="role">Administrator Role</option>}
									</Input>
								</FormGroup>
							</Col>
							{row.field_type === 'datafield' ? (
								<RecipientConditionDatafield
									key={key}
									data={row}
									onUpdate={(fieldName, newValue) => handleFieldChange(key, fieldName, newValue)}
									onDelete={() => handleRemoveClick(key)}
									settingsDataFields={settingsDataFields}
								/>
							) : row.field_type === 'role' ? (
								<RecipientConditionAdministratorRole
									key={key}
									data={row}
									onUpdate={(fieldName, newValue) => handleFieldChange(key, fieldName, newValue)}
									onDelete={() => handleRemoveClick(key)}
								/>
							) : (
								<></>
							)}
						</Row>
					);
				})
			) : (
				<Alert color="secondary">
					<span>No conditions defined</span>
				</Alert>
			)}
			<div className="pull-right">
				<Button color="link" size="sm" disabled={conditions != null && conditions.length >= 5} onClick={() => handleAddClick()}>
					<FaPlus size={12} /> Add Condition
				</Button>
			</div>
		</>
	);
};
