import React from 'react';

import { useDropzone } from 'react-dropzone';
import { FileWithAttributes } from 'api/tempTypes';

interface Props {
	onDrop: (acceptedFiles: Array<FileWithAttributes>) => void;
	validTypes: string | Array<string>;
}

export const Dropzone = ({ onDrop, validTypes }: Props) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDrop as any, multiple: true });

	return (
		<div {...getRootProps()} className="dropzone">
			<input className="dropzone-input" {...getInputProps()} />
			<div className="dropzone-content">
				<i className="fa fa-cloud-upload" />
				{isDragActive ? <p>Release to drop the file here</p> : <p>Drag 'n' drop the file here, or click to select a file</p>}
			</div>
		</div>
	);
};
