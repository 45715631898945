import React, { useState, useEffect, useContext } from 'react';

import { Button, Col, FormGroup, Table, UncontrolledTooltip, Alert, Label, Input, InputGroup, InputGroupText } from 'reactstrap';

import { TableRecordDeletePrompt } from '../TableRecordDeletePrompt';
import { ClearanceFileExtensionOverlay } from './ClearanceFileExtensionOverlay';
import { FaSpinner } from 'react-icons/fa';
import { SettingsClearancesDB, ValidFileExtension } from 'api/settings/clearances';

interface Props {
	data: SettingsClearancesDB;
	onSave: (newData: SettingsClearancesDB) => void;
}

export const UploadSettingSection = (props: Props) => {
	const [data, setData] = useState<SettingsClearancesDB>(
		props.data != null ? props.data : { max_upload_size: 0, member_groups: [], types_list: [], valid_file_extensions: [] }
	);
	const [detailsData, setDetailsData] = useState<ValidFileExtension | null>(null);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [showRecordDeleteConfirm, setShowRecordDeleteConfirm] = useState(false);
	const [hasChanged, setHasChanged] = useState(false);
	const [tableRecordDeleteId, setTableRecordDeleteId] = useState<string | null>(null);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	/**
	 * This function will update the table record (clearance file extension settings) data from the caller (model)
	 * @param {*} inputData
	 */
	const handleTableRecordDetailsSave = (inputData: ValidFileExtension) => {
		let newData = data;

		return new Promise((resolve, reject) => {
			let update = null;

			// First we need to check if the uid already exists. If it does, this is a duplicate or an update
			for (const [index, record] of data.valid_file_extensions.entries()) {
				if (record.uid === inputData.uid) {
					if (detailsData == null) {
						// This is an add
						// Record already exists. Don't continue.
						reject('Record already exists');
						return;
					} else {
						// This is an update
						update = index;
					}
				}
			}

			if (update == null) {
				// This is an add
				newData.valid_file_extensions.push(inputData);
			} else {
				// This is an update
				newData.valid_file_extensions[update] = inputData;
			}

			resolve(null);
		}).then(() => {
			setData(newData);
			setShowDetailsModal(!showDetailsModal);
		});
	};

	/**
	 * This function will cancel/close the confirmation overlay and not remove a record
	 */
	const handlTableRecordDeleteConfirmCancel = () => {
		setTableRecordDeleteId(null);
		setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
	};

	/**
	 * This function handles the change of the value of the upload size
	 * @param {*} e
	 */
	const handleMaxUploadSizeChange = (newValue: number) => {
		let newData = { ...data };

		newData.max_upload_size = newValue;

		return Promise.resolve().then(() => {
			setData(newData);
		});
	};

	/**
	 * This function will trigger the confirmation overlay before deleting the row
	 * @param {*} id
	 */
	const handleTableRecordDeleteClick = (id: string) => {
		setTableRecordDeleteId(id);
		setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
	};

	/**
	 * This function will remove the record from the table and save the result to the react state
	 */
	const handleTableRecordDeleteOverlayConfirm = () => {
		let newData = { ...data };
		let rowToRemove = null;

		newData.valid_file_extensions.forEach((row, index) => {
			if (row.uid === tableRecordDeleteId) {
				rowToRemove = index;
			}
		});

		if (rowToRemove != null) {
			newData.valid_file_extensions.splice(rowToRemove, 1);
		}

		return Promise.resolve().then(() => {
			setData(newData);
			setTableRecordDeleteId(null);
			setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
		});
	};

	/**
	 * This function will cancel/close the confirmation overlay and not remove a record
	 */
	const handleTableRecordDeleteOverlayCancel = () => {
		setTableRecordDeleteId(null);
		setShowRecordDeleteConfirm(!showRecordDeleteConfirm);
	};

	/**
	 * This function will handle the toggle of the add/edit overlay
	 */
	const handleTableRecordDetailsModalToggle = () => {
		setShowDetailsModal(!showDetailsModal);
	};

	const handleSaveClick = () => {
		return Promise.resolve()
			.then(() => {
				return setFormSaveInProgress(true);
			})
			.then(() => {
				return props.onSave(data);
			})
			.then(() => {
				return setFormSaveInProgress(false);
			});
	};

	return (
		<div className="clearance-settings-upload-section">
			<ClearanceFileExtensionOverlay
				key={`clearance-file-extension-record-${detailsData == null ? 'add' : `edit-${detailsData.uid}`}`}
				show={showDetailsModal}
				editRecord={detailsData!}
				onClose={() => handleTableRecordDetailsModalToggle()}
				onSave={handleTableRecordDetailsSave}
			/>
			<TableRecordDeletePrompt
				show={showRecordDeleteConfirm}
				onConfirm={() => handleTableRecordDeleteOverlayConfirm()}
				onCancel={() => handleTableRecordDeleteOverlayCancel()}
			>
				This action cannot be undone.
			</TableRecordDeletePrompt>
			<FormGroup tag="fieldset" row>
				<legend className="col-form-label col-sm-4">Upload Settings</legend>
			</FormGroup>
			<FormGroup row>
				<Label for="max_upload_size" sm={2}>
					Max Upload Size
				</Label>
				<Col sm={2}>
					<InputGroup>
						<Input
							type="number"
							name="max_upload_size"
							id="max_upload_size"
							placeholder="Enter a size"
							value={data['max_upload_size']}
							onChange={(e) => handleMaxUploadSizeChange(parseInt(e.target.value))}
						/>
						<InputGroupText>MB</InputGroupText>
					</InputGroup>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for="file-extensions" sm={6}>
					Allowed File Extensions
				</Label>
			</FormGroup>
			<FormGroup row>
				<Col sm={12}>
					{data['valid_file_extensions'] != null && data['valid_file_extensions'].length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left">Name</th>
									<th className="">Extension</th>
									<th className="">Mime Type</th>
									<th className="text-right">Actions</th>
								</tr>
							</thead>
							<tbody>
								{data['valid_file_extensions'].map((row, key) => {
									const { uid, name, extension, attributes } = row;
									const { canDelete } = attributes;

									return (
										<tr key={`clearance-settings-file-extension-field-${uid}`}>
											<td>{name}</td>
											<td>{extension}</td>
											<td>{row['mime_type']}</td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="danger"
													id={`clearance-settings-file-extension-field-${key}-delete`}
													size="sm"
													type="button"
													disabled={true || (canDelete != null && canDelete !== true) ? true : false}
													onClick={() => handleTableRecordDeleteClick(uid)}
												>
													<i className="fa fa-times" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`clearance-settings-file-extension-field-${key}-delete`}>
													Delete
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No file extensions defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button
							className="btn-icon"
							id={`clearance-settings-file-extension-field-add`}
							color="info"
							size="sm"
							disabled={true}
							onClick={() => handleTableRecordDetailsModalToggle()}
						>
							<i className="fa fa-plus" />
						</Button>
						<UncontrolledTooltip delay={0} target={`clearance-settings-file-extension-field-add`}>
							Add Row
						</UncontrolledTooltip>
					</div>
				</Col>
			</FormGroup>
			<FormGroup check row>
				<Col sm={{ size: 10, offset: 2 }}>
					<Button color="primary" onClick={() => handleSaveClick()} disabled={formSaveInProgress}>
						{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
					</Button>
				</Col>
			</FormGroup>
		</div>
	);
};
