import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';

import { Input, Button, Card, CardHeader, CardBody, CardTitle, FormGroup, Label, Row, Col, UncontrolledTooltip } from 'reactstrap';

import { DenyReasonOverlay } from './partials/DenyReasonOverlay';
import toast from 'react-hot-toast';

import { setting, member } from '../../api';
import UserContext from '../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsClearancesType } from 'api/settings/clearances';
import { MemberDB, MemberDocumentApprovalDB, MemberHeaderDB } from 'api/members';
import { MemberDocumentDB } from 'api/members/document';
import { MemberDocumentAlreadyApprovedException } from 'api/exceptions/MemberDocumentAlreadyApprovedException';
import { MemberDocumentPreviouslyDeniedException } from 'api/exceptions/MemberDocumentPreviouslyDeniedException';
import { MemberDocumentAlreadyDeniedException } from 'api/exceptions/MemberDocumentAlreadyDeniedException';
import { MemberDocumentPreviouslyApprovedException } from 'api/exceptions/MemberDocumentPreviouslyApprovedException';
import { InvalidParametersException } from 'api/exceptions/InvalidParametersException';
import { useNavigate } from 'react-router-dom';
import { ForbiddenException } from 'api/exceptions/ForbiddenException';

interface Props {}

export const QuickApproval = (props: Props) => {
	const { jwt } = useContext(UserContext);
	let navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [clearanceHeaders, setClearanceHeaders] = useState<Array<SettingsClearancesType>>([]);
	const [memberHeaders, setMemberHeaders] = useState<Array<MemberHeaderDB>>([]);
	const [data, setData] = useState<Array<MemberDocumentApprovalDB>>([]);
	const [selectedDocument, setSelectedDocument] = useState<{
		memberData: MemberDB;
		docData: MemberDocumentDB;
		docURL: string;
	} | null>(null);
	const [isDenyOverlay, setIsDenyOverlay] = useState(false);

	const [isDocumentEditMode, setIsDocumentEditMode] = useState(false);
	const [docEditData, setDocEditData] = useState<MemberDocumentDB | null>(null);
	const [canSave, setCanSave] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [isApproveInProgress, setIsApproveInProgress] = useState(false);
	const [isDenyInProgress, setIsDenyInProgress] = useState(false);

	useEffect(() => {
		const getContents = async () => {
			try {
				const clearanceData = await setting.clearances.get(jwt);
				setClearanceHeaders(clearanceData.types_list);

				const memberData = await member.getHeaders(jwt);
				setMemberHeaders(memberData);

				const responseObj = await member.listPendingApproval(jwt);
				setData(responseObj);
			} catch (error) {
				if (error instanceof ForbiddenException) {
					toast.error(error.message);
				} else {
					Sentry.captureException(error);
					toast.error('Failed to load');
				}
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		Promise.resolve().then(async () => {
			if (data.length > 0) {
				setIsLoading(true);

				// Get the details
				try {
					const responseObj = await member.getPendingApproval(jwt, data[0].docID);

					setSelectedDocument(responseObj);
				} catch (error) {
					if (error instanceof MemberDocumentAlreadyApprovedException) {
						// Skip it
					} else {
						console.error(error);
						Sentry.captureException(error);
					}
				}
			} else {
				setSelectedDocument(null);
			}

			setIsLoading(false);
		});
	}, [data]);

	useEffect(() => {
		let canSave = true;

		if (docEditData == null) {
			return;
		}

		// Do the form validation
		if (docEditData.type == null || docEditData.certification_date == null) {
			canSave = false;
		}

		if (!clearanceHeaders.map((clearance) => clearance.uid).includes(docEditData.type)) {
			canSave = false;
		}

		const todaysDate = new Date();
		todaysDate.setHours(0, 0, 0, 0);

		if (todaysDate.getTime() < new Date(docEditData.certification_date).getTime()) {
			canSave = false;
		}

		setCanSave(canSave);
	}, [docEditData]);

	const handleApproveButtonClick = async () => {
		setIsApproveInProgress(true);

		try {
			await member.document.approve(jwt, selectedDocument!.memberData.docID, selectedDocument!.docData.docID);

			toast.success('Document approved');

			// Success. Move to the next one
			const newData = [...data];
			newData.splice(0, 1);
			setData(newData);
		} catch (err: any) {
			if (err instanceof MemberDocumentAlreadyApprovedException) {
				toast.error('Document has already been approved');
			} else if (err instanceof MemberDocumentPreviouslyDeniedException) {
				toast.error('Document has previously been denied. Cannot approve');
			} else {
				toast.error('Error approving');
				console.error(err);
				Sentry.captureException(err);
			}
		} finally {
			setIsApproveInProgress(false);
		}
	};

	const handleDenyButtonClick = () => {
		setIsDenyOverlay(true);
	};

	const handleDenySubmit = async (reasonText: string) => {
		setIsDenyInProgress(true);

		try {
			await member.document.deny(jwt, selectedDocument!.memberData.docID, selectedDocument!.docData.docID, reasonText);

			toast.success('Document denied');

			// Success. Move to the next one
			setIsDenyOverlay(false);
			const newData = [...data];
			newData.splice(0, 1);
			setData(newData);
		} catch (err: any) {
			if (err instanceof MemberDocumentAlreadyDeniedException) {
				toast.error('Document has already been denied');
			} else if (err instanceof MemberDocumentPreviouslyApprovedException) {
				toast.error('Document has previously been approved. Cannot deny');
			} else {
				toast.error('Error approving');
				console.error(err);
				Sentry.captureException(err);
			}
		} finally {
			setIsDenyInProgress(false);
		}
	};

	const handleNextButtonClick = () => {
		// Move to the next one
		setIsDenyOverlay(false);
		const newData = [...data];
		newData.splice(0, 1);
		setData(newData);
	};

	const handleDenyOverlayCancel = () => {
		setIsDenyOverlay(false);
	};

	const handleViewMemberDetailsClick = () => {
		navigate(`/administrator/member/${selectedDocument!.memberData.docID}`);
	};

	const handleToggleClearanceDataClick = () => {
		if (!isDocumentEditMode) {
			setDocEditData(selectedDocument!.docData);
			setIsDocumentEditMode(!isDocumentEditMode);
		} else {
			setIsDocumentEditMode(!isDocumentEditMode);
			setDocEditData(null);
		}
	};

	const handleDocEditInputFieldChange = async (fieldName: keyof MemberDocumentDB, newValue: any) => {
		let newDocument = { ...docEditData };

		(newDocument as any)[fieldName] = newValue;

		setDocEditData(newDocument as MemberDocumentDB);
	};

	const handleOnSaveClick = async () => {
		try {
			const response = await member.document.fix(jwt, selectedDocument!.memberData.docID, selectedDocument!.docData.docID, docEditData!);

			// Success. Update the parent data
			if (response === true) {
				toast.success('Document updated');
			}

			const newDocument = { ...selectedDocument };
			(newDocument as any).docData = docEditData;
			setSelectedDocument(newDocument as any);
			handleToggleClearanceDataClick();
		} catch (error) {
			if (error instanceof InvalidParametersException) {
				toast.error(error.message);
			} else {
				console.error(error);
				toast.error('Failed to update document');
			}
		}
	};

	return (
		<div className="content quick-approval">
			<DenyReasonOverlay
				isOpen={isDenyOverlay}
				onDeny={handleDenySubmit}
				onCancel={() => {
					handleDenyOverlayCancel();
				}}
			/>
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<Button
								className="pull-right"
								id={`quick-approval-deny-button`}
								color="danger"
								onClick={() => handleDenyButtonClick()}
								disabled={isLoading || selectedDocument == null || isDocumentEditMode === true || isDenyInProgress === true}
							>
								{isDenyInProgress === true ? (
									<FaSpinner size={12} className="fa-spin" />
								) : (
									<>
										<i className="fa fa-thumbs-down" /> {data != null && data.length > 1 ? 'Deny & Next' : 'Deny'}
									</>
								)}
							</Button>
							<Button
								className="pull-right"
								id={`quick-approval-next-button`}
								color="secondary"
								onClick={() => handleNextButtonClick()}
								disabled={isLoading || selectedDocument == null || (data != null && data.length === 1) || isDocumentEditMode === true}
							>
								<i className="fa fa-arrow-right" /> Next
							</Button>
							<Button
								className="pull-right"
								id={`quick-approval-approve-button`}
								color="success"
								onClick={() => handleApproveButtonClick()}
								disabled={isLoading || selectedDocument == null || isDocumentEditMode === true || isApproveInProgress === true}
							>
								{isApproveInProgress === true ? (
									<FaSpinner size={12} className="fa-spin" />
								) : (
									<>
										<i className="fa fa-thumbs-up" /> {data != null && data.length > 1 ? 'Approve & Next' : 'Approve'}
									</>
								)}
							</Button>

							<UncontrolledTooltip delay={0} target={`quick-approval-approve-button`}>
								Approve Document
							</UncontrolledTooltip>
							<UncontrolledTooltip delay={0} target={`quick-approval-deny-button`}>
								Reject Document
							</UncontrolledTooltip>
							<CardTitle tag="h4">Quick Approval</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								{isLoading ? (
									<Col md={12}>Loading...</Col>
								) : (
									<>
										{selectedDocument != null ? (
											<>
												<Col md={6}>
													<h6>Uploaded Document</h6>

													{selectedDocument.docURL != null ? (
														<>
															{selectedDocument.docURL != null ? (
																<iframe
																	title={'quick-approval-frame'}
																	style={{ border: '0px' }}
																	src={`${selectedDocument.docURL}`}
																></iframe>
															) : (
																<iframe
																	title={'quick-approval-frame'}
																	style={{ border: '0px' }}
																	src={`data:application/pdf;base64`}
																></iframe>
															)}
														</>
													) : (
														<div>No document available to view</div>
													)}
												</Col>
												<Col className={'information-data'} md={6}>
													<Row>
														<Col className={'align-self-center'}>
															<h6 className="m-0">User Information</h6>
														</Col>
														<Col>
															<Button
																className="btn-sm m-0 pull-right"
																id={'quick-approval-view-member-details-button'}
																color="link"
																onClick={() => handleViewMemberDetailsClick()}
																disabled={isLoading || selectedDocument == null}
															>
																<i className="fa fa-share" />
															</Button>
															<UncontrolledTooltip delay={0} target={'quick-approval-view-member-details-button'}>
																View Member Details
															</UncontrolledTooltip>
														</Col>
													</Row>
													<Row>
														<Col>
															{Object.keys(memberHeaders).map((header) => {
																const obj = memberHeaders[header as any];

																let val = selectedDocument.memberData[header];

																if (header === 'uid') {
																	val = selectedDocument.memberData[selectedDocument.memberData['uid']];
																}

																return (
																	<FormGroup row key={`quick-approve-user-info-${header}`}>
																		<Label sm={4}>
																			<strong>{(obj as any).name}</strong>
																		</Label>
																		<Col className={'data-value'} sm={8}>
																			{val}
																		</Col>
																	</FormGroup>
																);
															})}
														</Col>
													</Row>
													<Row>
														<Col className={'align-self-center'}>
															<h6 className="m-0">Clearance Data</h6>
														</Col>
														<Col>
															{isDocumentEditMode === true ? (
																<>
																	<Button
																		className="m-0 ml-1 pull-right"
																		size="sm"
																		color="link"
																		onClick={() => handleToggleClearanceDataClick()}
																	>
																		Cancel
																	</Button>
																	<Button
																		className="m-0 pull-right"
																		size="sm"
																		color="button"
																		disabled={!canSave || formSaveInProgress}
																		onClick={() => handleOnSaveClick()}
																	>
																		{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
																	</Button>
																</>
															) : (
																<>
																	<Button
																		id={'quick-approval-edit-document-button'}
																		className="m-0 pull-right"
																		size="sm"
																		color="link"
																		onClick={() => handleToggleClearanceDataClick()}
																		disabled={isLoading || selectedDocument == null}
																	>
																		<i className="fa fa-pencil" />
																	</Button>
																	<UncontrolledTooltip delay={0} target={'quick-approval-edit-document-button'}>
																		Edit Document
																	</UncontrolledTooltip>
																</>
															)}
														</Col>
													</Row>
													<Row>
														<Col>
															<FormGroup row key={`quick-approve-form-data-type`}>
																<Label sm={4}>
																	<strong>Type</strong>
																</Label>
																<Col className={'data-value'} sm={5}>
																	{isDocumentEditMode === true ? (
																		<>
																			<Input
																				type={'select'}
																				name={'fieldName'}
																				id={'fieldName'}
																				placeholder={'description'}
																				onChange={(e) => {
																					handleDocEditInputFieldChange('type', e.target.value);
																				}}
																				defaultValue={docEditData!.type}
																			>
																				{clearanceHeaders.map((option) => {
																					if (option.attributes.readonly) {
																						return null;
																					}

																					return (
																						<option key={option.uid} value={option.uid}>
																							{option.name}
																						</option>
																					);
																				})}
																			</Input>
																		</>
																	) : (
																		clearanceHeaders.filter(
																			(clearance) => clearance.uid === selectedDocument.docData.type,
																		)[0].name
																	)}
																</Col>
															</FormGroup>
															<FormGroup row key={`quick-approve-form-data-uploaded-date`}>
																<Label sm={4}>
																	<strong>Uploaded Date</strong>
																</Label>
																<Col className={'data-value'} sm={8}>
																	{(selectedDocument as any).docData.uploaded_date}
																</Col>
															</FormGroup>
															<FormGroup row key={`quick-approve-form-data-certification_date`}>
																<Label sm={4}>
																	<strong>Certification Date</strong>
																</Label>
																<Col className={'data-value'} sm={5}>
																	{isDocumentEditMode === true ? (
																		<>
																			<Input
																				type={'date'}
																				name={'fieldName'}
																				id={'fieldName'}
																				placeholder={'description'}
																				onChange={(e) => {
																					handleDocEditInputFieldChange('certification_date', e.target.value);
																				}}
																				value={docEditData!.certification_date as any}
																			/>
																		</>
																	) : (
																		(selectedDocument as any).docData.certification_date
																	)}
																</Col>
															</FormGroup>
														</Col>
													</Row>
												</Col>
											</>
										) : (
											<Col md={12}>No documents to review</Col>
										)}
									</>
								)}
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
