import React, { useState } from 'react';

import { Label, Input, Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { PostProcessingStepOverlay } from './post-processing-section/PostProcessingStepOverlay';
import { MemberImportTemplateDB, MemberImportTemplatePostProcessingStep } from 'api/members/templates';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	dynamicSettingsDataFields: Array<SettingsDataField>;
	handleInputFieldChange: (fieldName: keyof MemberImportTemplateDB, fieldValue: any, type?: string) => void;
	data: MemberImportTemplateDB;
}

export const PostProcessingSection = (props: Props) => {
	const { dynamicSettingsDataFields, handleInputFieldChange, data } = props;
	const [showFieldRowModal, setShowFieldRowModal] = useState(false);

	/**
	 * This function will handle the toggle of the add/edit overlay
	 */
	const handleShowFieldRowModalToggle = () => {
		// Toggle the modal display
		setShowFieldRowModal(!showFieldRowModal);
	};

	/**
	 * This function handles the entry remove button click
	 * @param uid the index into the fieldDataConditions array for the entry to remove
	 */
	const handleRowRemoveClick = (uid: number) => {
		let newData = { ...data };

		if (newData.post_processing_steps == null) {
			newData.post_processing_steps = [];
		}

		newData.post_processing_steps.splice(uid, 1);

		handleInputFieldChange('post_processing_steps', newData.post_processing_steps);
	};

	/**
	 * This function handles adding/editing row details save
	 * @param fieldName the name of the field to be updated
	 * @param fieldValue the value that is to be saved
	 */
	const handleFieldRowSave = (fieldRowData: MemberImportTemplatePostProcessingStep) => {
		let newData = { ...data };

		if (newData.post_processing_steps == null) {
			newData.post_processing_steps = [];
		}

		newData.post_processing_steps.push(fieldRowData);
		handleInputFieldChange('post_processing_steps', newData.post_processing_steps);
	};

	return (
		<>
			<PostProcessingStepOverlay
				show={showFieldRowModal}
				onSave={handleFieldRowSave}
				onClose={handleShowFieldRowModalToggle}
				dynamicSettingsDataFields={dynamicSettingsDataFields}
			/>
			<FormGroup row>
				<Col md={6}>
					<Label for="title">Steps</Label>
				</Col>
				<Col sm={12}>
					{data.post_processing_steps != null && data.post_processing_steps.length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left" style={{ width: '10%' }}>
										Step
									</th>
									<th className="text-left" style={{ width: '25%' }}>
										Title
									</th>
									<th className="text-left" style={{ width: '25%' }}>
										Excel Column
									</th>
									<th className="text-right" style={{ width: '40%' }}>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{data.post_processing_steps.map((row, key) => {
									return (
										<tr key={`template-details-post-processing-section-${key}`}>
											<td>{row.step}</td>
											<td>{row.title}</td>
											<td>{(row as any).excelColumnName}</td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="success"
													id={`template-details-post-processing-section-${key}-edit`}
													size="sm"
													type="button"
													disabled={true}
													// onClick={() => handleEditClick(key)}
												>
													<i className="fa fa-edit" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`template-details-post-processing-section-${key}-edit`}>
													Edit
												</UncontrolledTooltip>
												<Button
													className="btn-icon"
													color="danger"
													id={`template-details-post-processing-section-${key}-delete`}
													size="sm"
													type="button"
													onClick={() => handleRowRemoveClick(key)}
												>
													<i className="fa fa-times" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`template-details-post-processing-section-${key}-delete`}>
													Delete
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No steps defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button
							className="btn-icon"
							id={`template-details-post-processing-section-field-add`}
							color="info"
							size="sm"
							onClick={() => handleShowFieldRowModalToggle()}
							disabled={true || showFieldRowModal}
						>
							<i className="fa fa-plus" />
						</Button>
						<UncontrolledTooltip delay={0} target={`template-details-post-processing-section-field-add`}>
							Add Step
						</UncontrolledTooltip>
					</div>
				</Col>
			</FormGroup>
		</>
	);
};
