// Node modules
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	createRoutesFromChildren,
	createRoutesFromElements,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Types

// Exceptions

// Helpers
import { Frontend as FrontendLayout } from './layouts/Frontend';
import { Administrator as AdministratorLayout } from './layouts/Administrator';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss?v=1.2.0';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.captureConsoleIntegration(),
	],

	tracesSampleRate: 0.2,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
	createRoutesFromElements(
		<Route path="/">
			<Route path="app/*" element={<FrontendLayout />} />
			<Route path="administrator/*" element={<AdministratorLayout />} />
			<Route index element={<Navigate to={'app'} />} />
		</Route>,
	),
);

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
	<>
		<RouterProvider router={router} />
	</>,
);
