"use strict";
// Node modules
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTIVE_EVENTS = exports.EVENTS = void 0;
// Types
const types_js_1 = require("./types.js");
__exportStar(require("./types.js"), exports);
// Exceptions
// Helpers
exports.EVENTS = [
    types_js_1.Events.AUTH_LOGIN_ADMIN,
    types_js_1.Events.AUTH_LOGIN_MEMBER,
    types_js_1.Events.AUTH_PASSWORD_REQUEST_RESET,
    types_js_1.Events.AUTH_PASSWORD_RESET,
    types_js_1.Events.MEMBER_ADD,
    types_js_1.Events.MEMBER_UPDATE,
    types_js_1.Events.MEMBER_ARCHIVE,
    types_js_1.Events.MEMBER_UNARCHIVE,
    types_js_1.Events.DOCUMENT_ADD,
    types_js_1.Events.DOCUMENT_UPDATE,
    types_js_1.Events.DOCUMENT_APPROVE,
    types_js_1.Events.DOCUMENT_DENY,
    types_js_1.Events.DOCUMENT_EXPIRING,
    types_js_1.Events.DOCUMENT_EXPIRED,
    types_js_1.Events.SETTINGS_UPDATE,
];
exports.ACTIVE_EVENTS = [types_js_1.Events.DOCUMENT_ADD, types_js_1.Events.DOCUMENT_APPROVE, types_js_1.Events.DOCUMENT_DENY, types_js_1.Events.DOCUMENT_EXPIRING, types_js_1.Events.DOCUMENT_EXPIRED];
