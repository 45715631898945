"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidOrganizationException = void 0;
/**
 * This exception is used if the organization is not correctly specified
 */
class InvalidOrganizationException extends Error {
    /**
     * Optional organization id to include in the error
     */
    organizationID;
    /**
     * Optional parameter to include the content of the error
     */
    content;
    /**
     * Constructor
     * @param {string} message the error message
     * @param {object} content the content used for future debugging
     */
    constructor(message, organizationID, content) {
        super(message);
        this.organizationID = organizationID;
        this.content = content;
        // If we support a stack trace, capture it
        if ('captureStackTrace' in Error) {
            Error.captureStackTrace(this, this.constructor);
        }
        else {
            console.error(`${this.constructor.name} Does not support captureStackTrace()!`);
        }
    }
    /**
     * Returns the organization ID that caused the error
     */
    getOrganizationID() {
        return this.organizationID;
    }
    /**
     * Returns the content attribute of the class
     */
    getContent() {
        return this.content;
    }
}
exports.InvalidOrganizationException = InvalidOrganizationException;
