import React from 'react';

import { Alert } from 'reactstrap';
import { MemberImportTemplateField } from 'api/members/templates';

interface Props {
	columnMappings: Array<MemberImportTemplateField>;
}

export const ImportResults = (props: Props) => {
	const { columnMappings } = props;

	const getDataFieldColumn = (dataFieldUid: string) => {
		return columnMappings.filter((field) => {
			return field.dynamicFieldUID === dataFieldUid;
		})[0];
	};

	const getClearanceColumn = (clearanceUid: string) => {
		return columnMappings.filter((field) => {
			return field.dynamicFieldUID === clearanceUid;
		})[0];
	};

	return (
		<>
			<Alert color={'primary'}>Upload successful</Alert>
			{/* {Object.keys(selectColumns).map((fileName) => {
				const fileData = selectColumns[fileName];

				return (
					<Table key="member-upload-overlay-selet-column-table" responsive>
						<thead className="text-primary">
							<tr>
								<th>Spreadsheet Column</th>
								<th></th>
								<th>Selected Field</th>
							</tr>
						</thead>
						<tbody>
							{fileData.columns.map((row, key) => {
								const defaultDataField = getDataFieldColumn(row['uid-mapping']);
								const defaultClearance = getClearanceColumn(row['uid-mapping']);

								return (
									<tr key={`member-upload-overlay-selet-column-${key}`}>
										<td>{row['column-name']}</td>
										<td>=</td>
										<td>
											{defaultDataField != null
												? defaultDataField.name
												: defaultClearance != null
												? `${defaultClearance.name} Clearance`
												: 'Ignored'}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				);
			})} */}
		</>
	);
};
