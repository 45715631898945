import React, { useState, useEffect } from 'react';

import { Button, Input, FormGroup, Row, Col, Label } from 'reactstrap';

import { ConditionsSection } from './recipients-section-add/ConditionsSection';
import { FaSpinner } from 'react-icons/fa';
import { SettingsNotificationsListEntryRecipient } from 'api/settings/notifications';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	editData: SettingsNotificationsListEntryRecipient;
	onSave: (newRecord: SettingsNotificationsListEntryRecipient) => void;
	onCancel: () => void;
	dynamicDataFields: Array<SettingsDataField>;
	clearanceFields: Array<SettingsClearancesType>;
}

export const RecipientsSectionAddModify = (props: Props) => {
	const { editData, onSave, onCancel, dynamicDataFields, clearanceFields } = props;

	const [isEditMode, setIsEditMode] = useState(false);
	const [data, setData] = useState<SettingsNotificationsListEntryRecipient>({
		recipient_type: 'to',
		recipient: 'user',
	});
	const [canSave, setCanSave] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	useEffect(() => {
		if (editData != null) {
			setData(editData);
			setIsEditMode(true);
		}
	}, [editData]);

	useEffect(() => {
		let canSave = true;

		// Do the form validation
		if (data.recipient_type == null || (data as any).recipient_type === '' || (data.recipient_type !== 'to' && data.recipient_type !== 'cc')) {
			canSave = false;
		}

		if (
			data.recipient == null ||
			(data as any).recipient === '' ||
			(data.recipient !== 'user' && data.recipient !== 'any-member' && data.recipient !== 'administrator')
		) {
			canSave = false;
		}

		setCanSave(canSave);
	}, [data]);

	const handleAddModeToggle = () => {
		setIsEditMode(!isEditMode);

		if (editData != null) {
			onCancel();
		}
	};

	const handleInputFieldChange = async (fieldName: keyof SettingsNotificationsListEntryRecipient, newValue: any) => {
		let newData = { ...data };

		(newData as any)[fieldName] = newValue;

		setData(newData);
	};

	const handleOnSaveClick = () => {
		setFormSaveInProgress(true);
		onSave(data);
		setData({ recipient_type: 'to', recipient: 'user' });
		setIsEditMode(false);
		setFormSaveInProgress(false);
	};

	return isEditMode === true ? (
		<div
			style={{
				border: '1px solid #d6d8db',
				borderRadius: '5px',
				padding: '10px',
			}}
		>
			<Row>
				<Col md={2}>
					<FormGroup>
						<Input
							type="select"
							name="recipient_type"
							id="recipient_type"
							onChange={(e) => handleInputFieldChange('recipient_type', e.target.value)}
							defaultValue={data.recipient_type != null ? data.recipient_type : ''}
						>
							<option value="to">To</option>
							<option value="cc">CC</option>
						</Input>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup>
						<Input
							type="select"
							name="recipient"
							id="recipient"
							onChange={(e) => handleInputFieldChange('recipient', e.target.value)}
							defaultValue={data.recipient != null ? data.recipient : ''}
						>
							<option value="user">User</option>
							<option value="any-member">Any Member</option>
							<option value="administrator">Administrator</option>
						</Input>
					</FormGroup>
				</Col>
				<Col md={{ size: 3, offset: 4 }} className="d-flex justify-content-end">
					<Button color="button" size="sm" disabled={!canSave || formSaveInProgress} onClick={() => handleOnSaveClick()}>
						{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
					</Button>
					<Button color="link" size="sm" onClick={() => handleAddModeToggle()}>
						Cancel
					</Button>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<FormGroup>
						<Label for="conditions">Conditions</Label>
					</FormGroup>
				</Col>
			</Row>
			<ConditionsSection
				recipient={data.recipient}
				conditionsData={data.conditions != null ? data.conditions : []}
				dynamicDataFields={dynamicDataFields}
				clearanceFields={clearanceFields}
				handleInputFieldChange={(newValue) => handleInputFieldChange('conditions', newValue)}
				handleCanSaveChange={(newValue) => setCanSave(newValue)}
			/>
		</div>
	) : (
		<Button color="link" size="sm" onClick={() => handleAddModeToggle()}>
			<i className="fa fa-plus" /> Add Recipient
		</Button>
	);
};
