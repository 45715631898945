import React, { useContext, useEffect, useState } from 'react';

import { member } from '../../../../../api';
import UserContext from '../../../../UserContext';

import { DocumentPortal } from './DocumentPortal';
import { MemberDocumentDB } from 'api/members/document';

interface Props {
	uid: string;
	documentId: string;
}

export const ViewDocument = (props: Props) => {
	const { uid, documentId } = props;
	const { jwt } = useContext(UserContext);

	const [data, setData] = useState<MemberDocumentDB>();
	const [showWindowPortal, setShowWindowPortal] = useState(false);

	useEffect(() => {
		member.document.get(jwt, uid, documentId).then((response) => {
			setData(response);
		});
	}, []);

	return (
		<DocumentPortal width={600} height={600}>
			<iframe title="document-portal" width="100%" height="100%" style={{ border: '0px' }} src={`${data != null ? (data as any).location : ''}`}></iframe>
		</DocumentPortal>
	);
};
