import React from 'react';

import { Button, Input, FormGroup, Row, Col, UncontrolledTooltip, Alert } from 'reactstrap';

import { FieldConditionSelector } from './FieldConditionSelector';
import { SettingsDataField } from 'api/settings/general';
import { SettingsNotificationsListEntryRecipientCondition } from 'api/settings/notifications';
import { LogicOperator } from '@mouseware/lib-core';
import { ReportColumnCondition } from 'api/reports';

interface Props {
	onUpdate: (newValue: any) => void;
	selectedDataFields: SettingsDataField;
	conditions: Array<ReportColumnCondition>;
}

export const FieldConditions = (props: Props) => {
	const { onUpdate, selectedDataFields, conditions } = props;

	/**
	 * This function handles the "Add Condition" button click
	 */
	const handleAddClick = () => {
		const newConditions = conditions != null ? conditions : [];
		newConditions.push({ logicOperator: LogicOperator.EQUAL_TO, value: '' });
		onUpdate(newConditions);
	};

	/**
	 * This function handles the entry remove button click
	 * @param index the index into the fieldDataConditions array for the entry to remove
	 */
	const handleRemoveClick = (index: number) => {
		const newConditions = conditions;
		newConditions.splice(index, 1);
		onUpdate(newConditions);
	};

	/**
	 * This function handles changing any of the field fields in the table
	 * @param index the index of the entry to be updated
	 * @param fieldName the name of the field in the entry to be updated
	 * @param e the new value for this entry
	 */
	const handleFieldChange = (index: number, fieldName: string, newValue: any) => {
		const newConditions = conditions;
		(newConditions as any)[index][fieldName] = newValue;
		onUpdate(newConditions);
	};

	return (
		<>
			{conditions != null && conditions.length > 0 ? (
				selectedDataFields != null ? (
					<>
						{conditions.map((row, key) => {
							const { logicOperator, value } = row;

							return (
								<Row key={`column-attributes-section-details-overlay-condition-${key}`}>
									<Col md={1}>{key > 0 ? 'or' : ''}</Col>
									<Col md={4}>
										<FormGroup>
											<Input
												type="select"
												name={`inputLogicOperator-${key}`}
												id={`inputLogicOperator-${key}`}
												onChange={(e) => {
													handleFieldChange(key, 'logicOperator', e.target.value);
												}}
												defaultValue={logicOperator}
											>
												<option value="equal-to">Equal to</option>
												<option value="contains">Contains</option>
												<option value="starts-with">Starts with</option>
												<option value="ends-with">Ends with</option>
											</Input>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<FieldConditionSelector
												index={key}
												value={value}
												dataField={selectedDataFields}
												onUpdate={(newValue: any) => {
													handleFieldChange(key, 'value', newValue);
												}}
											/>
										</FormGroup>
									</Col>
									<Col md={1}>
										<FormGroup className="pull-right">
											<Button
												className="btn-icon"
												color="danger"
												id={`column-attributes-section-details-overlay-condition-${key}-delete`}
												size="sm"
												type="button"
												onClick={() => handleRemoveClick(key)}
											>
												<i className="fa fa-times" />
											</Button>
											<UncontrolledTooltip delay={0} target={`column-attributes-section-details-overlay-condition-${key}-delete`}>
												Remove
											</UncontrolledTooltip>
										</FormGroup>
									</Col>
								</Row>
							);
						})}
					</>
				) : (
					<Alert color="secondary">
						<span>Could not load conditions.</span>
					</Alert>
				)
			) : (
				<Alert color="secondary">
					<span>No conditions defined</span>
				</Alert>
			)}
			<div className="pull-right">
				<Button
					id={`column-attributes-section-details-overlay-condition-tooltip`}
					color="link"
					size="sm"
					disabled={selectedDataFields == null || (conditions != null && conditions.length >= 5)}
					onClick={() => handleAddClick()}
				>
					<i className="fa fa-plus" /> Add Condition
				</Button>
			</div>
		</>
	);
};
