import React from 'react';
import { Routes, Route, NavLink as RouterNavLink, Navigate } from 'react-router-dom';

import { Card, CardHeader, CardBody, Form, Nav, NavLink, NavItem, Col, Row, TabContent, TabPane } from 'reactstrap';

import { ClearancesTab } from './dashboard/ClearancesTab';
import { DetailsTab } from './dashboard/DetailsTab';

export const FrontendStep3 = () => {
	return (
		<Form action="" className="form" method="">
			<Card className="card-dashboard">
				<CardHeader>
					<CardHeader>
						<h3 className="header text-center">Dashboard</h3>
					</CardHeader>
				</CardHeader>
				<CardBody>
					<Row>
						<Col lg="3" md="3" sm="3" xs="4">
							<div className="nav-tabs-navigation verical-navs p-0">
								<div className="nav-tabs-wrapper">
									<Nav id="tabs" className="flex-column nav-stacked" role="tablist" tabs>
										<NavItem key={`details-nav-item`}>
											<NavLink tag={RouterNavLink} to={`details`} end>
												Personal Details
											</NavLink>
										</NavItem>
										<NavItem key={`clearances-nav-item`}>
											<NavLink tag={RouterNavLink} to={`clearances`} end>
												Clearances
											</NavLink>
										</NavItem>
									</Nav>
								</div>
							</div>
						</Col>
						<Col lg="9" md="9" sm="9" xs="8">
							<TabContent>
								<TabPane>
									<Routes>
										<Route index element={<Navigate to={`details`} />} />
										<Route path={`details`} element={<DetailsTab />} />
										<Route path={`clearances/*`} element={<ClearancesTab />} />
									</Routes>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Form>
	);
};
