import React, { useEffect, useState } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { AdminNavbar } from '../../components/Navbars/AdminNavbar';
import { Footer } from '../../components/Footer/Footer';
import { Sidebar } from '../../components/Sidebar/Sidebar';

import { Dashboard } from '../../components/Dashboard/Dashboard';
import { Members } from '../../components/Members/Members';
import { Notifications } from '../../components/Notifications/Notifications';
import { AdministratorUsers } from '../../components/Administrator/Users/Users';
import { AdministratorRoles } from '../../components/Administrator/Roles/Roles';
import { GeneralSettings } from '../../components/Settings/GeneralSettings';
import { ClearanceSettings } from '../../components/Settings/ClearanceSettings';
import { Logs } from '../../components/Settings/Logs';
import { NotificationSettings } from '../../components/Settings/NotificationSettings';
import { ReportSettings } from '../../components/Settings/ReportSettings';
import { Reports } from '../../components/Reports/Reports';
import { QuickApproval } from '../../components/QuickApproval';
import { Preferences } from '../../components/Preferences';
import { IconType } from 'react-icons';

import routes, { RouteEntry } from '../../routes';

export const AdminPage = () => {
	const [sidebarMini, setSidebarMini] = useState(false);

	useEffect(() => {
		if (navigator.platform.indexOf('Win') > -1) {
			document.documentElement.className += ' perfect-scrollbar-on';
			document.documentElement.classList.remove('perfect-scrollbar-off');
		}

		return () => {
			if (navigator.platform.indexOf('Win') > -1) {
				document.documentElement.className += ' perfect-scrollbar-off';
				document.documentElement.classList.remove('perfect-scrollbar-on');
			}
		};
	}, []);

	const getRoutes = (routes: Array<RouteEntry>): Array<React.ReactNode> => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views!);
			}

			return <Route path={prop.path} key={key} element={prop.component as any} />;
		});
	};

	const handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			setSidebarMini(false);
		} else {
			setSidebarMini(true);
		}
		document.body.classList.toggle('sidebar-mini');
	};

	return (
		<div className="wrapper">
			<Sidebar routes={routes} />
			<div className="main-panel">
				<AdminNavbar handleMiniClick={handleMiniClick} />
				<Routes>
					<Route path={'dashboard'} element={<Dashboard />} />
					<Route path={'administrators/users/*'} element={<AdministratorUsers />} />
					<Route path={'administrators/roles/*'} element={<AdministratorRoles />} />
					<Route path={'member/*'} element={<Members />} />
					<Route path={'notifications/*'} element={<Notifications />} />
					<Route path={'quick-approval'} element={<QuickApproval />} />
					<Route path={'report/*'} element={<Reports />} />
					<Route path={'setting/general/*'} element={<GeneralSettings />} />
					<Route path={'setting/clearance/*'} element={<ClearanceSettings />} />
					<Route path={'setting/log/*'} element={<Logs />} />
					<Route path={'setting/notification/*'} element={<NotificationSettings />} />
					<Route path={'setting/report/*'} element={<ReportSettings />} />
					<Route path={'preferences'} element={<Preferences />} />
					<Route index element={<Navigate to="dashboard" replace />} />
				</Routes>
				<Toaster position="bottom-right" />
				<Footer fluid />
			</div>
		</div>
	);
};
