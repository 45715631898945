import React, { useState, useContext } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';

import UserContext from '../UserContext';
import { CheckClearances } from './CheckClearances';
import { FrontendStep2 } from './MemberVerification';
import { FrontendStep3 } from './MemberDashboard';

import { AuthMember, frontend } from '../../api';

export const Frontend = () => {
	const navigate = useNavigate();
	const { signIn } = useContext(UserContext);

	const [userData, setUserData] = useState<{ bsaId?: string; dob?: string; loginToken?: string; verificationCodeEmail?: string }>({});

	const onVerifyStepClick = (data: { bsaId?: string; dob?: string; loginToken?: string; verificationCodeEmail?: string }) => {
		setUserData(data);
		navigate('/app/verify');
	};

	return (
		<div className="frontend-page">
			<Routes>
				<Route path="login" element={<CheckClearances onVerifyStepClick={onVerifyStepClick} />} />
				<Route path="verify" element={<FrontendStep2 data={userData} signIn={(data: AuthMember) => signIn(data)} />} />
				<Route path="dashboard/*" element={<FrontendStep3 />} />
				<Route index element={<Navigate to="dashboard" replace />} />
			</Routes>
		</div>
	);
};
