import React from 'react';

import { Input } from 'reactstrap';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	handleFieldChange: (newValue: any) => void;
	dataField: SettingsDataField;
	selectedValue: string | number;
}

export const SelectField = (props: Props) => {
	const { dataField, selectedValue, handleFieldChange } = props;
	const { uid, description, attributes } = dataField;

	// This is a local variable of the selectedValue prop... processing it here
	let parentSelectedValue = selectedValue;

	// Make sure we have a valid default value
	if (parentSelectedValue == null) {
		parentSelectedValue = '';
	}

	return (
		<Input
			type={'select'}
			name={uid}
			id={uid}
			placeholder={description}
			onChange={(e) => {
				handleFieldChange(e.target.value);
			}}
			value={parentSelectedValue}
		>
			<option value={null as any}>- Select Option -</option>
			{attributes.options != null &&
				attributes.options.map((option) => {
					return (
						<option key={option.uid} value={option.uid}>
							{option.name}
						</option>
					);
				})}
		</Input>
	);
};
