import { ReportDB } from 'api/reports';
import React from 'react';

import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

import { ReportType } from './ReportType';

interface Props {
	onChange: (fieldName: keyof ReportDB, newValue: any) => void;
	data: ReportDB;
}

export const GeneralSection = (props: Props) => {
	const { onChange, data } = props;

	return (
		<div className="add-report-general-attributes-section">
			<Row>
				<Col md={5}>
					<FormGroup>
						<Label for="reportTitle">Report Title</Label>
						<Input
							type="text"
							name="reportTitle"
							id="reportTitle"
							onChange={(e) => {
								onChange('title', e.target.value);
							}}
							value={data.title != null ? data.title : ''}
						/>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup>
						<Label for="outputFormat">Output Format</Label>
						<Input
							type="select"
							name="outputFormat"
							id="outputFormat"
							onChange={(e) => {
								onChange('outputFormat', e.target.value);
							}}
							value={data.outputFormat != null ? data.outputFormat : 'pdf'}
						>
							<option value={'pdf'}>PDF</option>
							<option value={'excel'}>Excel</option>
						</Input>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup>
						<Label for="includeArchived">Include Archived</Label>
						<Input
							type="select"
							name="includeArchived"
							id="includeArchived"
							onChange={(e) => {
								onChange('includeArchived', e.target.value);
							}}
							value={(data.includeArchived != null ? data.includeArchived : false) as any}
						>
							<option value={false as any}>No</option>
							<option value={true as any}>Yes</option>
						</Input>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup>
						<Label for="generate_key">Show Column Key</Label>
						<Input
							type="select"
							name="generate_key"
							id="generate_key"
							onChange={(e) => {
								onChange('generate_key', e.target.value === 'true' ? true : false);
							}}
							value={(data.generate_key != null ? data.generate_key : false) as any}
						>
							<option value={false as any}>No</option>
							<option value={true as any}>Yes</option>
						</Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md={12}>&nbsp;</Col>
			</Row>
			<ReportType data={data} onChange={onChange} />
		</div>
	);
};
