import React, { useState, useEffect, useContext } from 'react';

import { Col, CardTitle, FormGroup } from 'reactstrap';
import UserContext from '../../UserContext';

import { frontend } from '../../../api';
import { UnauthorizedException } from '../../../api/exceptions/UnauthorizedException';
import { useNavigate } from 'react-router-dom';

export const DetailsTab = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState<Array<{ name: string; value: string }>>([]);

	/**
	 * This function will retrieve the user profile data from the backend
	 */
	useEffect(() => {
		const getContents = async () => {
			try {
				if (user == null) {
					navigate('/app/login');
					return;
				}

				const response = await frontend.getProfile(user.token ?? '');
				setData(response);
				setIsLoaded(true);
			} catch (err) {
				if (err instanceof UnauthorizedException) {
					// Redirect to login
					navigate('/app/login');
				} else {
					// TODO: Handle better
					console.error('error');
					console.error(err);
				}
			}
		};

		getContents();
	}, []);

	return (
		<div className="details-tab">
			<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
				Personal Details
			</CardTitle>
			{!isLoaded ? (
				<>Loading...</>
			) : (
				Object.keys(data).map((field) => {
					return (
						<FormGroup row key={`member-dashboard-details-tab-field-${field}`}>
							<Col sm={3}>{data[field as any].name}</Col>
							<Col sm={9}>{data[field as any].value}</Col>
						</FormGroup>
					);
				})
			)}
		</div>
	);
};
