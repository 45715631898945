import React, { useState } from 'react';

import { Button, Modal, Row, Col, Form, Input, FormGroup, Label, ModalHeader } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { MemberImportDB } from 'api/members/import';
import { MemberImportTemplateField } from 'api/members/templates';

interface Props {
	show: boolean;
	onClose: () => void;
	onSave: (fieldRowData: MemberImportTemplateField) => void;

	dynamicSettingsDataFields: Array<SettingsDataField>;
	data?: MemberImportDB;
}

export const SettingsSectionRecordOverlay = (props: Props) => {
	const { show, onClose, onSave, dynamicSettingsDataFields } = props;
	const [data, setData] = useState<MemberImportTemplateField>(props.data ? props.data : ({} as any));
	const [hasFormChanged, setHasFormChanged] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	/**
	 * This function handles changing the value of any input fields
	 * @param fieldName the name of the field to be updated
	 * @param fieldValue the value that is to be saved
	 */
	const handleInputFieldChange = (fieldName: string, fieldValue: any) => {
		let newData = { ...data };
		(newData as any)[fieldName] = fieldValue;
		setData(newData);
		setHasFormChanged(true);
	};

	/**
	 * This function handles changing any of the field fields in the table
	 * @param uid the index of the entry to be updated
	 * @param fieldName the name of the field in the entry to be updated
	 * @param newValue the new value for this entry
	 */
	const handleRowInputFieldChange = (newValue: any) => {
		let newData = { ...data };

		(newData as any).excelColumnName = newValue;
		setData(newData);
		setHasFormChanged(true);
	};

	/**
	 * This function handles the "Save" button click
	 */
	const handleOnSaveClick = () => {
		setFormSaveInProgress(true);
		onSave(data);
		onClose();
		setData({} as any);
		setFormSaveInProgress(false);
	};

	return (
		<Modal isOpen={show} toggle={() => onClose()}>
			<ModalHeader toggle={() => onClose()}>Add Field</ModalHeader>
			<div className="modal-body">
				<Form>
					<FormGroup>
						<Label for="dynamicFieldUID">Name</Label>
						<Input
							type={'select'}
							name={'dynamicFieldUID'}
							id={'dynamicFieldUID'}
							onChange={(e) => {
								handleInputFieldChange('dynamicFieldUID', e.target.value);
							}}
							value={(data as any).dynamicFieldUID != null ? (data as any).dynamicFieldUID : ''}
						>
							<option value={''}>- Select Field -</option>
							{dynamicSettingsDataFields != null && dynamicSettingsDataFields.length > 0 ? (
								dynamicSettingsDataFields.map((item) => {
									return (
										<option key={`field-row-overlay-field-option-${item.uid}`} value={item.uid}>
											{item.name}
										</option>
									);
								})
							) : (
								<></>
							)}
						</Input>
					</FormGroup>
					<FormGroup>
						<Label for="excelColumnName">Excel Field Column Name</Label>
						<Row key={`field-row-overlay-excel-name`}>
							<Col md={10}>
								<FormGroup>
									<Input
										type={'text'}
										name={`field-row-overlay-excel-name`}
										id={`field-row-overlay-excel-name`}
										placeholder={'Enter Excel field name'}
										onChange={(e) => {
											handleRowInputFieldChange(e.target.value);
										}}
										value={(data as any).excelColumnName != null ? (data as any).excelColumnName : ''}
									/>
								</FormGroup>
							</Col>
						</Row>
					</FormGroup>
				</Form>
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="primary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						handleOnSaveClick();
					}}
					disabled={!hasFormChanged || formSaveInProgress}
				>
					Add
				</Button>
				<Button
					className="btn-round"
					color="secondary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						onClose();
					}}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
