// Node modules
import * as Sentry from '@sentry/react';

// Types
export interface MemberImportTemplateDB {
	docID?: string;
	title: string;
	fields: Array<MemberImportTemplateField>;
	skip_archive?: boolean; // This skips the function of archiving a member
	skip_unarchive?: boolean; // This skips the function of unarchiving a member
	pre_processing_steps?: Array<MemberImportTemplatePreProcessingStep>;
	post_processing_steps?: Array<MemberImportTemplatePostProcessingStep>;

	archived: null | Date; // The date that this template was archived
}

export interface MemberImportTemplateField {
	dynamicFieldUID: string;
	excelColumnName: string;
	onConflictSetValue?: 'new' | 'existing' | 'merge'; // Default "new". Merge only available for 'multiple' fields
	attributes?: { options?: Array<{ dynamicFieldUID: string; excelColumnName: string }> };
}

export interface MemberImportTemplatePreProcessingStep {
	step: number;
	title: string;
	excelColumnName: string;
	actions: Array<MemberImportTemplatePreProcessingStepAction>;
}

export interface MemberImportTemplatePreProcessingStepAction {
	logicalOperator: 'equal-to';
	value: string;
	action: 'replace' | 'remove';
	replace_value?: string;
}

export interface MemberImportTemplatePostProcessingStep {
	step: number;
	title: string;
	// TODO: Implement
}

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import { FailedToFetchException } from '../exceptions/FailedToFetchException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';

export default class Templates {
	async get(token: string, docID: string): Promise<MemberImportTemplateDB> {
		try {
			const response = await fetch(`/api/v1/admin/members/import/templates/${docID}`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.get(newToken, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async update(token: string, docID: string, data: MemberImportTemplateDB): Promise<MemberImportTemplateDB> {
		try {
			const response = await fetch(`/api/v1/admin/members/import/templates/${docID}`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(data),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.update(newToken, docID, data);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async archive(token: string, docID: string): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/members/import/templates/${docID}/archive`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.archive(newToken, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}
}
