import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import toast from 'react-hot-toast';

import { ViewDocument } from './ViewDocument';

import { member } from '../../../../../api';
import UserContext from '../../../../UserContext';
import { SettingsClearancesType } from 'api/settings/clearances';
import { FailedToFetchException } from 'api/exceptions/FailedToFetchException';

interface Props {
	uid: string;
	clearanceSettings: Array<SettingsClearancesType>;
	viewAllDocuments: boolean;
}

export const TableList = (props: Props) => {
	const { uid, clearanceSettings, viewAllDocuments } = props;
	const { jwt } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState([]);
	const [viewDocumentID, setViewDocumentID] = useState<string | null>(null);

	const loadDocumentList = async () => {
		try {
			const data = await member.document.list(jwt, uid);

			const dataArray: any = {};

			// Convert this into an array by document type
			data.map((document) => {
				if (dataArray[document.type] == null) {
					dataArray[document.type] = [];
				}

				dataArray[document.type].push(document);
			});

			// Sort the array based on certification date (DESC)
			Object.keys(dataArray).map((docType) => {
				dataArray[docType] = dataArray[docType].sort((a: any, b: any) => {
					const aDate = new Date(a.attributes.certification_date);
					const bDate = new Date(b.attributes.certification_date);

					if (aDate < bDate) {
						return 1;
					}
					if (aDate > bDate) {
						return -1;
					}
					return 0;
				});
			});

			// Save it
			setIsLoading(false);
			setData(dataArray);
		} catch (error) {
			if (error instanceof FailedToFetchException) {
				toast.error(error.message);
			} else {
				console.error(error);
				Sentry.captureException(error);
			}
		}
	};

	useEffect(() => {
		loadDocumentList();
	}, []);

	const handleViewButtonClick = (documentId: string) => {
		setViewDocumentID(documentId);
	};

	/**
	 * Handles the document approve button click
	 * @param {*} documentId
	 */
	const handleApproveButtonClick = (documentId: string) => {
		return member.document
			.approve(jwt, uid, documentId)
			.then((response) => {
				setIsLoading(true);
				setData([]);
				loadDocumentList();
			})
			.catch((error) => {
				console.error(error);
				Sentry.captureException(error);
			});
	};

	/**
	 * Render
	 */

	return (
		<div className="documents-table">
			{viewDocumentID != null ? <ViewDocument key={viewDocumentID} uid={uid} documentId={viewDocumentID} /> : <></>}
			{isLoading ? (
				<div>Retrieving Data...</div>
			) : (
				<Table responsive>
					<thead className="text-primary">
						<tr>
							<th>Type</th>
							<th>Uploaded</th>
							<th>Certification Date</th>
							<th>Approved</th>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td colSpan={5}>Loading...</td>
							</tr>
						) : (
							<>
								{data != null && Object.keys(data).length > 0 ? (
									<>
										{Object.keys(data).map((documentType) => {
											return (data as any)[documentType as any]
												.filter(
													(document: any) =>
														viewAllDocuments === true || (viewAllDocuments === false && document.attributes.denied_date == null),
												)
												.map((document: any, index: number) => {
													const { docID, type, attributes } = document;

													if (viewAllDocuments === false) {
														// We are only showing the most recent documents
														if (index > 0) {
															return;
														}
													}

													if (attributes.approved_date != null) {
														const date = new Date(attributes.approved_date);
														attributes.approved_date = `${(1 + date.getUTCMonth()).toString().padStart(2, '0')}/${date
															.getUTCDate()
															.toString()
															.padStart(2, '0')}/${date.getUTCFullYear()}`;
													}

													if (attributes.certification_date != null) {
														const date = new Date(attributes.certification_date);
														attributes.certification_date = `${(1 + date.getUTCMonth()).toString().padStart(2, '0')}/${date
															.getUTCDate()
															.toString()
															.padStart(2, '0')}/${date.getUTCFullYear()}`;
													}

													if (attributes.uploaded_date != null) {
														const date = new Date(attributes.uploaded_date);
														attributes.uploaded_date = `${(1 + date.getUTCMonth()).toString().padStart(2, '0')}/${date
															.getUTCDate()
															.toString()
															.padStart(2, '0')}/${date.getUTCFullYear()}`;
													}

													return (
														<tr key={`member-details-overlay-documents-tab-data-${docID}`}>
															<td>
																{type != null && clearanceSettings != null && clearanceSettings.length > 0
																	? clearanceSettings.filter((clearanceType) => {
																			return clearanceType.uid === type;
																		})[0].name
																	: 'Unknown'}
															</td>
															<td>{attributes['uploaded_date'] != null ? attributes['uploaded_date'] : 'N/A'}</td>
															<td>{attributes['certification_date'] != null ? attributes['certification_date'] : 'Unknown'}</td>
															<td>
																{attributes['approved_date'] != null ? (
																	<>
																		<span id={`documents-table-${documentType}-${index}-approved-date`}>
																			{attributes['approved_date']}
																		</span>
																		{attributes['approved_by'] != null ? (
																			<UncontrolledTooltip
																				delay={0}
																				target={`documents-table-${documentType}-${index}-approved-date`}
																			>
																				Approved by {attributes['approved_by']}
																			</UncontrolledTooltip>
																		) : (
																			<></>
																		)}
																	</>
																) : attributes['denied_date'] != null ? (
																	<>
																		<span id={`documents-table-${documentType}-${index}-denied-date`}>Denied</span>
																	</>
																) : (
																	<Button
																		color="link"
																		size="sm"
																		onClick={() => {
																			handleApproveButtonClick(docID);
																		}}
																	>
																		Approve
																	</Button>
																)}
															</td>
															<td className="text-right">
																<Button
																	className="btn-icon"
																	color="info"
																	id={`member-content-${documentType}-${index}-tooltip-like`}
																	size="sm"
																	type="button"
																	onClick={() => handleViewButtonClick(docID)}
																	disabled={attributes['file-exists'] === false}
																>
																	<i className="fa fa-eye" />
																</Button>
																<UncontrolledTooltip delay={0} target={`member-content-${documentType}-${index}-tooltip-like`}>
																	View Details
																</UncontrolledTooltip>
															</td>
														</tr>
													);
												});
										})}
									</>
								) : (
									<tr>
										<td colSpan={5}>No documents found</td>
									</tr>
								)}
							</>
						)}
					</tbody>
				</Table>
			)}
		</div>
	);
};
