import React, { useState, useEffect, useContext } from 'react';

import { Button, Modal, Form, Input, FormGroup, Label, Row, Col, Table, UncontrolledTooltip, Alert, ModalHeader } from 'reactstrap';

import { MemberGroupOverlayAddRequirement } from './MemberGroupOverlayAddRequirement';
import { FaTimes } from 'react-icons/fa';
import { MemberGroup, MemberGroupRequirement, SettingsClearancesDB, SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	show: boolean;
	editRecord: MemberGroup;
	onClose: () => void;
	onSave: (newData: MemberGroup) => void;
	clearanceList: Array<SettingsClearancesType>;
}

export const MemberGroupOverlay = (props: Props) => {
	const [data, setData] = useState<MemberGroup>(
		props.editRecord != null ? props.editRecord : { uid: '', name: '', requirements: [], attributes: {}, archived: null }
	);

	/**
	 *
	 */
	const handleOnCloseClick = () => {
		return Promise.resolve()
			.then(() => {
				setData({ uid: '', name: '', requirements: [], attributes: {}, archived: null });
			})
			.then(() => {
				props.onClose();
			});
	};

	/**
	 *
	 */
	const handleInputFieldChange = (fieldName: keyof MemberGroup, newValue: any) => {
		let newData = { ...data };

		(newData as any)[fieldName] = newValue;

		setData(newData);
	};

	/**
	 *
	 */
	const handleOnSaveClick = () => {
		let uidValue = data.uid;

		if (uidValue == null) {
			uidValue = data.name.replace(/\s+/g, '-').toLowerCase();
		}

		return Promise.resolve()
			.then(() => {
				return props.onSave({
					uid: uidValue,
					name: data.name,
					default: data.default === true ? true : undefined,
					requirements: data.requirements,
					attributes: {},
					archived: null,
				});
			})
			.then(() => {
				setData({ uid: '', name: '', requirements: [], attributes: {}, archived: null });
			});
	};

	/**
	 *
	 */
	const onAddRequirementSave = (requirementData: MemberGroupRequirement) => {
		const newData = { ...data };
		newData['requirements'].push(requirementData);

		setData(newData);
	};

	/**
	 * This function will remove the record from the table and save the result to the react state
	 */
	const handleTableRecordDeleteClick = (requirementId: string) => {
		let newData = { ...data };
		let rowToRemove = null;

		newData['requirements'].forEach((row, index) => {
			if (row.clearance === requirementId) {
				rowToRemove = index;
			}
		});

		if (rowToRemove != null) {
			newData['requirements'].splice(rowToRemove, 1);
		}

		setData(newData);
	};

	return (
		<Modal className="modal-lg member-group-overlay" isOpen={props.show} toggle={() => handleOnCloseClick()}>
			<ModalHeader toggle={() => handleOnCloseClick()}>{data.uid == null ? `Add` : `Edit`} Member Group</ModalHeader>
			<div className="modal-body">
				<Form>
					<Row>
						<Col md={6}>
							<FormGroup>
								<Label for="fieldName">Name</Label>
								<Input
									type="text"
									name="fieldName"
									id="fieldName"
									placeholder="Enter Name"
									onChange={(e) => handleInputFieldChange('name', e.target.value)}
									value={data['name']}
								/>
							</FormGroup>
						</Col>
						<Col md={{ size: 3, offset: 3 }}>
							<FormGroup>
								<Label for="fieldDefault">Default</Label>
								<Input
									type="select"
									name="fieldDefault"
									id="fieldDefault"
									onChange={(e) => handleInputFieldChange('default', e.target.value)}
									disabled={true}
									defaultValue={data['default'] || (false as any)}
								>
									<option value={true as any}>Yes</option>
									<option value={false as any}>No</option>
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<hr />
					{data.requirements != null && data.requirements.length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left">Clearance</th>
									<th>Required</th>
									<th className="text-right">Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.requirements.map((row, key) => {
									const { clearance, required } = row;

									const clearanceRecord = props.clearanceList.filter((clearanceRecord) => clearanceRecord.uid === clearance)[0];

									return (
										<tr key={`member-group-overlay-requirements-${clearance}`}>
											<td>{clearanceRecord.name}</td>
											<td>{required === true ? 'Yes' : 'No'}</td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="danger"
													id={`member-group-overlay-requirements-${key}-delete`}
													size="sm"
													type="button"
													disabled={true}
													onClick={() => handleTableRecordDeleteClick(clearance)}
												>
													<i className="fa fa-times" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`member-group-overlay-requirements-${key}-delete`}>
													Delete
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No requirements defined</span>
						</Alert>
					)}

					<MemberGroupOverlayAddRequirement onSave={onAddRequirementSave} clearanceList={props.clearanceList} />
				</Form>
			</div>
			<div className="modal-footer justify-content-center">
				<Button className="btn-round" color="primary" data-dismiss="modal" type="button" onClick={() => handleOnSaveClick()}>
					{data.uid == null ? `Add` : `Update`}
				</Button>
				<Button className="btn-round" color="secondary" data-dismiss="modal" type="button" onClick={() => handleOnCloseClick()}>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
