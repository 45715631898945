import React from 'react';

import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { AdministratorRoleDB } from 'api/administrators/roles';

interface Props {
	onChange: (fieldName: keyof AdministratorRoleDB, newValue: any) => void;
	data: AdministratorRoleDB;
}

export const GeneralSection = (props: Props) => {
	const { onChange, data } = props;

	return (
		<div className="general-section">
			<Row>
				<Col md={7}>
					<FormGroup>
						<Label for="name">Role Name</Label>
						<Input
							type="text"
							name="name"
							id="name"
							onChange={(e) => onChange('name', e.target.value)}
							value={data.name != null ? data.name : ''}
						/>
					</FormGroup>
				</Col>
			</Row>
		</div>
	);
};
