import React, { useState, useEffect } from 'react';

import { Button, Modal, Form, FormGroup, Input, ModalHeader } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

interface Props {
	isOpen: boolean;
	onDeny: (data: any) => void;
	onCancel: () => void;
}

export const DenyReasonOverlay = (props: Props) => {
	const { isOpen, onDeny, onCancel } = props;
	const [data, setData] = useState<any>({});
	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		if (data['deny-reason'] != null && data['deny-reason'].length !== 0) {
			setCanSubmit(false);
		} else {
			setCanSubmit(true);
		}
	}, [data]);

	/**
	 * This function will update the value in the React state
	 * @param {string} fieldName
	 * @param {*} newValue
	 * @param {string} dataType
	 */
	const handleInputFieldChange = (fieldName: string, newValue: any, dataType?: string) => {
		let newData = { ...data };
		let val = newValue;

		if (dataType != null) {
			if (dataType === 'int') {
				val = parseInt(val, 10);
			}
		}

		newData[fieldName] = val;

		setData(newData);
	};

	/**
	 * This function takes care of handling the deny button click
	 */
	const handleDenyButtonClick = () => {
		onDeny(data);
	};

	/**
	 *
	 */
	const handleCloseClick = () => {
		onCancel();
	};

	/**
	 * The render function for the React component
	 */
	return (
		<Modal className="deny-reason-overlay" isOpen={isOpen} toggle={() => handleCloseClick()}>
			<ModalHeader toggle={() => handleCloseClick()}>Deny Document</ModalHeader>
			<div className="modal-body" style={{ padding: 15 }}>
				<Form>
					<FormGroup key={`deny-reason-overlay-textarea`}>
						<Input
							type={'textarea'}
							placeholder={'Enter reason'}
							onChange={(e) => {
								handleInputFieldChange('deny-reason', e.target.value);
							}}
						/>
					</FormGroup>
				</Form>
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="danger"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						handleDenyButtonClick();
					}}
					disabled={canSubmit}
				>
					Deny
				</Button>
				<Button
					className="btn-round"
					color="secondary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						handleCloseClick();
					}}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
