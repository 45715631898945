import { AdministratorRoleDB } from 'api/administrators/roles';
import { AdministratorUserDB } from 'api/administrators/users';
import React, { useContext, useEffect, useState } from 'react';

import { Col, FormGroup, Label, Input, Form } from 'reactstrap';
import { validateEmailAddress } from '../../../../validator';

interface Props {
	onChange: (fieldName: keyof AdministratorUserDB, newValue: any) => void;
	setCanSave: (newValue: boolean) => void;
	data: AdministratorUserDB;
	roles: Array<AdministratorRoleDB>;
}

export const DetailsEdit = (props: Props) => {
	const { onChange, setCanSave, data, roles } = props;

	useEffect(() => {
		let canSave = true;

		if (data.name == null || data.name == '') {
			canSave = false;
		} else if (data.email == null || data.email == '') {
			canSave = false;
		} else if (validateEmailAddress(data.email) == false) {
			//Invalid
			canSave = false;
		} else if (data.role == null) {
			canSave = false;
		}

		setCanSave(canSave);
	}, [data]);

	return (
		<Form className="edit-form">
			<FormGroup row>
				<Label for={`field-name`} sm={3}>
					<strong>Name</strong>
				</Label>
				<Col sm={9}>
					<Input
						type={'text'}
						name={`field-name`}
						id={`field-name`}
						placeholder={'Full Name'}
						onChange={(e) => {
							onChange('name', e.target.value);
						}}
						value={data.name != null ? data.name : ''}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for={`field-email`} sm={3}>
					<strong>Email Address</strong>
				</Label>
				<Col sm={9}>
					<Input
						type={'text'}
						name={`field-email`}
						id={`field-email`}
						placeholder={'Email Address'}
						onChange={(e) => {
							onChange('email', e.target.value);
						}}
						value={data.email != null ? data.email : ''}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for={`field-role`} sm={3}>
					<strong>Role</strong>
				</Label>
				<Col sm={9}>
					<Input
						type={'select'}
						name={`field-role`}
						id={`field-role`}
						placeholder={'Role'}
						onChange={(e) => {
							onChange('role', e.target.value);
						}}
					>
						<option value={''}>- Select Role -</option>
						{roles != null && roles.length > 0 ? (
							roles.map((record) => {
								return (
									<option key={record.docID} value={record.docID} selected={data.role != null && record.docID === data.role}>
										{record.name}
									</option>
								);
							})
						) : (
							<></>
						)}
					</Input>
				</Col>
			</FormGroup>
		</Form>
	);
};
