import React, { useState, useEffect, useContext } from 'react';

import { Badge, CardTitle } from 'reactstrap';

import { member } from '../../../../api';
import UserContext from '../../../UserContext';
import { FaCopy } from 'react-icons/fa';

interface Props {
	uid: string;
}

export const HistoryTab = (props: Props) => {
	const { uid } = props;
	const { jwt } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [data, setData] = useState<Array<any>>([]);

	useEffect(() => {
		const getContent = async () => {
			try {
				// const history = await member.history.get(jwt, uid);
				// setData(history);
			} catch (e: any) {
				setErrorMessage(e.message);
			} finally {
				setIsLoading(false);
			}
		};

		getContent();
	}, []);

	return (
		<div className="history-tab">
			<CardTitle tag="h4" style={{ marginTop: 0, marginBottom: '.5rem' }}>
				History
			</CardTitle>
			{isLoading ? (
				<div>Retrieving Data...</div>
			) : errorMessage != null ? (
				<div>{errorMessage}</div>
			) : (
				<>
					{data != null && data.length > 0 ? (
						<div className="card-timeline card-plain">
							<ul className="timeline">
								{data.map((row, key) => {
									const { date, title, description } = row;

									return (
										<li key={`member-details-overlay-history-tab-data-${key}`} className={key % 2 === 0 ? 'timeline-inverted' : ''}>
											<div className="timeline-badge info">
												<FaCopy />
											</div>
											<div className="timeline-panel">
												<div className="timeline-heading">
													<Badge color="info" pill>
														{title}
													</Badge>
												</div>
												<div className="timeline-body">
													<p>{description}</p>
												</div>
												<h6>
													<i className="fa fa-clock-o" />
													{date}
												</h6>
											</div>
										</li>
									);
								})}
							</ul>
						</div>
					) : (
						<div>No data available.</div>
					)}
				</>
			)}
		</div>
	);
};
