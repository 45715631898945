// Node modules
import * as Sentry from '@sentry/react';

// Exceptions
import { UnauthorizedException } from './exceptions/UnauthorizedException';
import { InvalidParametersException } from './exceptions/InvalidParametersException';
import { ResourceNotFoundException } from './exceptions/ResourceNotFoundException';
import { ForbiddenException } from './exceptions/ForbiddenException';
import { InternalServerErrorException } from './exceptions/InternalServerErrorException';
import { RefreshTokenExpiredException } from './exceptions/RefreshTokenExpiredException';
import { PasswordResetRequiredException } from './exceptions/PasswordResetRequiredException';
import { PasswordExpiredException } from './exceptions/PasswordExpiredException';
import { AccountLockedException } from './exceptions/AccountLockedException';
import { AccountCannotLoginException } from './exceptions/AccountCannotLoginException';
import { FailedToFetchException } from './exceptions/FailedToFetchException';

// Helpers
import Login from './login';
import Administrators from './administrators';
import Dashboard from './dashboard';
import Members from './members';
import Notifications from './notifications';
import Reports from './reports';
import Preferences from './preferences';
import Settings from './settings';
import Statistics from './statistics';
import Frontend from './frontend';

export interface AuthUser {
	uid: string;
	name: string;
	organization: string;
	token?: string;
}

export interface AuthAdministrator extends AuthUser {
	role: string;
}

export interface AuthMember extends AuthUser {}

export interface ErrorResponse {
	code: number;
	message: string;
	errorCode: number;
	ref_id: string;
}

/**
 * This function returns the information about a user if they are logged in.
 * If there is no logged in user, null is returned.
 * @param {int} count the number of times this is recursively called
 * @param {int} maxCount the total number of times to recurse before giving up
 */
export const getAuthenticatedUser = async (count = 0, maxCount = 5): Promise<AuthMember> => {
	try {
		// We want to retrieve credentials from local storage
		const userAuthorizationToken = localStorage.getItem('user-authorization');

		if (userAuthorizationToken == null) {
			throw new UnauthorizedException('Unknown user auth token');
		}

		const response = await fetch(`/api/v1/frontend/heartbeat`, {
			headers: new Headers({
				Authorization: 'Basic ' + userAuthorizationToken,
				'Content-Type': 'application/json',
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			respObj.token = userAuthorizationToken;
			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			throw new UnauthorizedException('Unauthorized');
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 403) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			if (count < maxCount) {
				await new Promise((resolve) => {
					setTimeout(() => {
						resolve(null);
					}, 1000);
				});

				return getAuthenticatedUser(count + 1, maxCount);
			}

			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	} catch (err) {
		if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
			throw new FailedToFetchException();
		}

		throw err;
	}
};

/**
 * This function refreshes the users access token
 */
export const refreshAccessToken = async (refreshToken: string): Promise<{ access: AuthAdministrator; refresh: string }> => {
	try {
		const response = await fetch(`/api/v1/admin/auth/refresh`, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify({ grant_type: 'refresh_token', refresh_token: refreshToken }),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			return respObj as { access: AuthAdministrator; refresh: string };
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			throw new UnauthorizedException('Unauthorized');
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 404) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 461) {
			throw new RefreshTokenExpiredException(respObj.message);
		} else if (response.status === 471) {
			throw new PasswordResetRequiredException(respObj.message);
		} else if (response.status === 472) {
			throw new PasswordExpiredException(respObj.message);
		} else if (response.status === 481) {
			throw new AccountLockedException(respObj.message);
		} else if (response.status === 482) {
			throw new AccountCannotLoginException(respObj.message);
		} else if (response.status === 500) {
			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	} catch (error) {
		if (error instanceof UnauthorizedException) {
			// Known error. Just throw it
		} else if (error instanceof RefreshTokenExpiredException) {
			// Known error. Just throw it
		} else if (error instanceof ResourceNotFoundException) {
			// Known error. Just throw it
		} else if (error instanceof TypeError && error.message === 'Failed to fetch') {
			throw new FailedToFetchException();
		} else {
			console.error(error);
			console.error('HIT refreshAccessToken() Error');
			Sentry.captureException(error);
		}

		throw error;
	}
};

/**
 * Re-authenticates the user if the refresh token exists
 * @return {string} the new access token
 */
export const retryUnauthorizedRequestAfterRefresh = async (): Promise<string> => {
	const localStorageString = localStorage.getItem('admin-user-authorization');

	if (localStorageString == null) {
		throw new UnauthorizedException('Unknown auth token');
	}

	const { token, refresh } = JSON.parse(localStorageString);

	if (refresh == null) {
		throw new UnauthorizedException('Request response returned unauthorized');
	}

	try {
		const response = await fetch(`/api/v1/admin/auth/refresh`, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
			body: JSON.stringify({ grant_type: 'refresh_token', refresh_token: refresh }),
		});

		if (response.status !== 200) {
			throw new UnauthorizedException('Request response returned unauthorized');
		}

		const serverResponseMessage = await response.text();
		const responseObj = await JSON.parse(serverResponseMessage);

		localStorage.setItem('admin-user-authorization', JSON.stringify({ token: responseObj.access.token, refresh: responseObj.refresh }));
		window.dispatchEvent(new Event('localStorageItemUpdate'));

		return responseObj.access.token;
	} catch (error) {
		if (error instanceof UnauthorizedException) {
			// Skip
		} else if (error instanceof TypeError && error.message === 'Failed to fetch') {
			throw new FailedToFetchException();
		} else {
			console.error('HIT Error');
			Sentry.captureException(error);
		}

		throw error;
	}
};

/**
 * This function returns the information about a user if they are logged in.
 * If there is no logged in user, null is returned.
 * @param {int} count the number of times this is recursively called
 * @param {int} maxCount the total number of times to recurse before giving up
 */
export const getAuthenticatedAdminUser = async (count = 0, maxCount = 5): Promise<AuthAdministrator> => {
	try {
		// We want to retrieve credentials from local storage
		const localStorageString = localStorage.getItem('admin-user-authorization');

		if (localStorageString == null) {
			throw new UnauthorizedException('Unknown admin auth token');
		}

		const { token, refresh } = JSON.parse(localStorageString);

		if (token == null) {
			throw new UnauthorizedException('Unknown token');
		}

		const response = await fetch(`/api/v1/admin/heartbeat`, {
			headers: new Headers({
				Authorization: 'Basic ' + token,
				'Content-Type': 'application/json',
			}),
		});

		// Check the response
		const respObj = await response.json();

		if (response.status === 200) {
			respObj.token = token;

			return respObj;
		} else if (response.status === 400) {
			throw new InvalidParametersException(respObj.message);
		} else if (response.status === 401) {
			if (refresh != null) {
				// Try getting a new token
				const userObj = await refreshAccessToken(refresh);

				localStorage.setItem('admin-user-authorization', JSON.stringify({ token: userObj.access.token, refresh: userObj.refresh }));
				return userObj.access;
			} else {
				throw new UnauthorizedException('Unauthorized');
			}
		} else if (response.status === 403) {
			throw new ForbiddenException(respObj.message);
		} else if (response.status === 403) {
			throw new ResourceNotFoundException(respObj.message);
		} else if (response.status === 500) {
			if (count < maxCount) {
				await new Promise((resolve) => {
					setTimeout(() => {
						resolve(null);
					}, 1000);
				});

				return getAuthenticatedAdminUser(count + 1, maxCount);
			}

			throw new InternalServerErrorException(respObj.message);
		} else {
			const error = new Error('Unknown error');
			Sentry.captureException(error);
			throw error;
		}
	} catch (err) {
		if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message === 'Load failed')) {
			throw new FailedToFetchException();
		} else if (err instanceof ResourceNotFoundException) {
			throw err;
		} else {
			throw err;
		}
	}
};

/**
 * This function clears an autenticated admin user if they exist
 */
export const clearAuthenticatedAdminUser = () => {
	// We want to clear the credentials from local storage
	localStorage.removeItem('admin-user-authorization');
};

/**
 * Login API interface
 */
const loginActions = new Login();
export const login = loginActions;

/**
 * Administrators API interface
 */
const administratorsActions = new Administrators();
export const administrators = administratorsActions;

/**
 * Dashboard API interface
 */
const dashboardActions = new Dashboard();
export const dashboard = dashboardActions;

/**
 * Member API interface
 */
const memberActions = new Members();
export const member = memberActions;

/**
 * Notifications API interface
 */
const notificationActions = new Notifications();
export const notification = notificationActions;

/**
 * Reports API interface
 */
const reportActions = new Reports();
export const report = reportActions;

/**
 * Setting API interface
 */
const settingActions = new Settings();
export const setting = settingActions;

/**
 * Setting API interface
 */
const statisticActions = new Statistics();
export const statistics = statisticActions;

/**
 * Administrator Preferences API interface
 */
const preferencesActions = new Preferences();
export const preferences = preferencesActions;

/**
 * Frontend API interface
 */
const frontendActions = new Frontend();
export const frontend = frontendActions;

/**
 * This is the default function that should never be called.
 */
export default () => {
	console.error('Hit Remote Server API default function');
};
