import React, { useState } from 'react';

import { Label, Input, Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { SettingsSectionRecordOverlay } from './settings-section/SettingsSectionRecordOverlay';
import { SettingsDataField } from 'api/settings/general';
import { MemberImportTemplateDB, MemberImportTemplateField } from 'api/members/templates';

interface Props {
	dynamicSettingsDataFields: Array<SettingsDataField>;
	handleInputFieldChange: (fieldName: keyof MemberImportTemplateDB, fieldValue: any, type?: string) => void;
	data: MemberImportTemplateDB;
}

export const SettingsSection = (props: Props) => {
	const { dynamicSettingsDataFields, handleInputFieldChange, data } = props;
	const [showFieldRowModal, setShowFieldRowModal] = useState(false);

	const handleShowFieldRowModalToggle = () => {
		// Toggle the modal display
		setShowFieldRowModal(!showFieldRowModal);
	};

	const handleRowRemoveClick = (uid: number) => {
		let newData = { ...data };

		newData.fields.splice(uid, 1);

		handleInputFieldChange('fields', newData.fields);
	};

	const handleFieldRowSave = (fieldRowData: MemberImportTemplateField) => {
		let newData = { ...data };

		if (newData.fields == null) {
			newData.fields = [];
		}

		newData.fields.push(fieldRowData);
		handleInputFieldChange('fields', newData.fields);
	};

	return (
		<>
			<SettingsSectionRecordOverlay
				show={showFieldRowModal}
				onSave={handleFieldRowSave}
				onClose={handleShowFieldRowModalToggle}
				dynamicSettingsDataFields={dynamicSettingsDataFields}
			/>
			<FormGroup row>
				<Col md={6}>
					<FormGroup>
						<Label for="title">Title</Label>
						<Input
							type={'text'}
							name={'title'}
							id={'title'}
							onChange={(e) => {
								handleInputFieldChange('title', e.target.value);
							}}
							value={data.title != null ? data.title : ''}
						/>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup>
						<Label for="skip_archive">Skip Archive</Label>
						<Input
							type={'select'}
							name={'skip_archive'}
							id={'skip_archive'}
							onChange={(e) => {
								handleInputFieldChange('skip_archive', e.target.value, 'boolean');
							}}
							value={data.skip_unarchive ? data.skip_archive : (true as any)}
						>
							<option value={true as any}>Yes</option>
							<option value={false as any}>No</option>
						</Input>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup>
						<Label for="skip_unarchive">Skip Unarchive</Label>
						<Input
							type={'select'}
							name={'skip_unarchive'}
							id={'skip_unarchive'}
							onChange={(e) => {
								handleInputFieldChange('skip_unarchive', e.target.value, 'boolean');
							}}
							value={data.skip_unarchive ? data.skip_unarchive : (true as any)}
						>
							<option value={true as any}>Yes</option>
							<option value={false as any}>No</option>
						</Input>
					</FormGroup>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Col md={6}>
					<Label for="title">Fields</Label>
				</Col>
				<Col sm={12}>
					{data.fields != null && data.fields.length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left" style={{ width: '15%' }}>
										Field
									</th>
									<th className="text-left" style={{ width: '65%' }}>
										Excel Column Name
									</th>
									<th className="text-right" style={{ width: '20%' }}>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{data.fields.map((row, key) => {
									const fieldName = dynamicSettingsDataFields.filter((field) => field.uid === row.dynamicFieldUID)[0];

									return (
										<tr key={`template-details-field-section-${key}`}>
											<td>{fieldName != null ? fieldName.name : 'Unknown'}</td>
											<td>{row.excelColumnName != null ? row.excelColumnName : 'Unknown'}</td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="success"
													id={`template-details-field-section-${key}-edit`}
													size="sm"
													type="button"
													disabled={true}
													// onClick={() => handleEditClick(key)}
												>
													<i className="fa fa-edit" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`template-details-field-section-${key}-edit`}>
													Edit
												</UncontrolledTooltip>
												<Button
													className="btn-icon"
													color="danger"
													id={`template-details-field-section-${key}-delete`}
													size="sm"
													type="button"
													onClick={() => handleRowRemoveClick(key)}
												>
													<i className="fa fa-times" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`template-details-field-section-${key}-delete`}>
													Delete
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No fields defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button
							className="btn-icon"
							id={`template-details-field-section-field-add`}
							color="info"
							size="sm"
							onClick={() => handleShowFieldRowModalToggle()}
							disabled={showFieldRowModal}
						>
							<i className="fa fa-plus" />
						</Button>
						<UncontrolledTooltip delay={0} target={`template-details-field-section-field-add`}>
							Add Field
						</UncontrolledTooltip>
					</div>
				</Col>
			</FormGroup>
		</>
	);
};
