import React, { useState, useContext, useEffect } from 'react';

import { Alert, Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupText, InputGroup } from 'reactstrap';

import { login } from '../../../api';
import UserContext from '../../UserContext';

import { LoginUnsuccessfulException } from '../../../api/exceptions/LoginUnsuccessfulException';
import { FaKey, FaSpinner, FaUser } from 'react-icons/fa';

interface Props {
	onSubmit: (loginToken: string) => void;
}

export const LoginForm = (props: Props) => {
	const { onSubmit } = props;
	const { setUserCredentials } = useContext(UserContext);

	const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
	const [messageState, setMessageState] = useState('info');
	const [message, setMessage] = useState<string | null>(null);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		return () => {
			// Reset the state
			setFormSubmitInProgress(false);
		};
	}, []);

	/**
	 * Handles processing the login request
	 * @param {*} event the event object
	 */
	async function handleLoginFormAction(event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();

		try {
			// Check if we have valid input
			// TODO: Implement better checks
			if (username === '' || password === '') {
				throw new Error('Please enter your username and password');
			}

			// Set the processing state
			setMessageState('info');
			setMessage(null);
			setFormSubmitInProgress(true);

			// Send to server
			const loginToken = await login.login(username, password);

			setFormSubmitInProgress(false);

			// Send the response back to the parent
			onSubmit(loginToken);
		} catch (error) {
			setMessageState('danger');

			if (error instanceof LoginUnsuccessfulException) {
				setMessage(error.message);
			} else {
				setMessage('Invalid credentials');
			}

			setFormSubmitInProgress(false);
		}
	}

	return (
		<Form action="" className="form" method="" onSubmit={(e) => handleLoginFormAction(e)}>
			<Card className="card-login">
				<CardHeader>
					<CardHeader>
						<h3 className="header text-center">Login</h3>
					</CardHeader>
				</CardHeader>
				<CardBody>
					{message != null && (
						<Alert color={messageState}>
							<span>{message}</span>
						</Alert>
					)}
					<InputGroup>
						<InputGroupText style={{ background: 'none' }}>
							<FaUser color="#66615b" />
						</InputGroupText>
						<Input placeholder="Email Address" type="text" onChange={(e) => setUsername(e.target.value)} />
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{ background: 'none' }}>
							<FaKey color="#66615b" />
						</InputGroupText>
						<Input placeholder="Password" type="password" autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
					</InputGroup>
				</CardBody>
				<CardFooter>
					<Button
						type="submit"
						block
						className="btn-round mb-3"
						color="warning"
						onClick={(e) => handleLoginFormAction(e)}
						disabled={formSubmitInProgress === true}
					>
						{formSubmitInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Login'}
					</Button>
					<div style={{ textAlign: 'center' }}>
						<a href="/administrator/forgot" style={{ color: '#000' }}>
							Forgot password
						</a>
					</div>
				</CardFooter>
			</Card>
		</Form>
	);
};
