"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceAlreadyExistsException = void 0;
/**
 * This exception is used if the resource already exists and a new one is trying to be added
 */
class ResourceAlreadyExistsException extends Error {
    /**
     * Optional parameter to include the resources ID
     */
    resourceID;
    /**
     * Constructor
     * @param {string} message the error message
     * @param {string} resourceID the resource ID
     */
    constructor(message, resourceID) {
        super(message);
        this.resourceID = resourceID;
        // If we support a stack trace, capture it
        if ('captureStackTrace' in Error) {
            Error.captureStackTrace(this, this.constructor);
        }
        else {
            console.error(`${this.constructor.name} Does not support captureStackTrace()!`);
        }
    }
    /**
     * Returns the resources ID attribute of the class
     */
    getResourceID() {
        return this.resourceID;
    }
}
exports.ResourceAlreadyExistsException = ResourceAlreadyExistsException;
