import React from 'react';
import {
	FaSortAlphaDownAlt,
	FaSortAlphaUpAlt,
	FaSortAmountDownAlt,
	FaSortAmountUpAlt,
	FaSortDown,
	FaSortNumericDown,
	FaSortNumericUp,
	FaSortUp,
} from 'react-icons/fa';

const classnames = require('classnames');

import './TableSorting.scss';

interface THSorterProps {
	type?: 'alpha' | 'amount' | 'numeric';
	sortBy?: boolean;
	sortDir?: 'asc' | 'desc';
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	onClick: () => void;
}

export const THSorter = (props: THSorterProps) => {
	const { type, sortBy, sortDir, className, style, children, onClick } = props;

	return (
		<th className={classnames(className, 'table-th-sorter')} style={style} onClick={onClick}>
			<div className="d-flex align-items-center gap-1">
				<div>{children}</div>
				<div className="sort-icons">
					{sortBy === true ? (
						<>
							{sortDir != null ? (
								sortDir === 'asc' ? (
									<>
										{type === 'alpha' ? (
											<FaSortAlphaUpAlt />
										) : type === 'amount' ? (
											<FaSortAmountUpAlt />
										) : type === 'numeric' ? (
											<FaSortNumericUp />
										) : (
											<FaSortUp />
										)}
									</>
								) : (
									<>
										{type === 'alpha' ? (
											<FaSortAlphaDownAlt />
										) : type === 'amount' ? (
											<FaSortAmountDownAlt />
										) : type === 'numeric' ? (
											<FaSortNumericDown />
										) : (
											<FaSortDown />
										)}
									</>
								)
							) : (
								<></>
							)}
						</>
					) : (
						<></>
					)}
				</div>
			</div>
		</th>
	);
};

interface THSorterTitleProps {
	children: React.ReactNode;
}

export const THSorterTitle = (props: THSorterTitleProps) => {
	const { children } = props;

	return <>{children}</>;
};

interface THSorterMenuProps {
	children: React.ReactNode;
}

export const THSorterMenu = (props: THSorterMenuProps) => {
	const { children } = props;

	return <>{children}</>;
};
