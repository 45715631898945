import React from 'react';
import { FaSpinner } from 'react-icons/fa';

interface Props {}

export const FullPageLoading = (props: Props) => {
	return (
		<div className="full-page-loading">
			<FaSpinner size={12} className="fa-spin" />
		</div>
	);
};
