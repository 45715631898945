import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';

import { Card, CardHeader, CardBody, CardTitle, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { UploadSettingSection } from './partials/clearance-settings/UploadSettingSection';
import { TypeSettingSection } from './partials/clearance-settings/TypeSettingSection';
import { MemberGroupSection } from './partials/clearance-settings/MemberGroupSection';

import { setting } from '../../api';
import UserContext from '../UserContext';
import toast from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa';
import { SettingsClearancesDB } from 'api/settings/clearances';
import { ForbiddenException } from 'api/exceptions/ForbiddenException';

interface Props {}

export const ClearanceSettings = (props: Props) => {
	const { jwt } = useContext(UserContext);

	const [activeTab, setActiveTab] = useState('type-setting');
	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState<SettingsClearancesDB>({ max_upload_size: 0, member_groups: [], types_list: [], valid_file_extensions: [] });

	useEffect(() => {
		const getContents = async () => {
			try {
				const response = await setting.clearances.get(jwt);

				// Filter any clearance types or member groups that are archived
				response.types_list = response.types_list.filter((entry) => entry.archived == null);
				response.member_groups = response.member_groups.filter((entry) => entry.archived == null);
				setData(response);
			} catch (error) {
				if (error instanceof ForbiddenException) {
					toast.error(error.message);
				} else {
					console.error('error');
					console.error(error);
					Sentry.captureException(error);
					toast.error('Failed to load');
				}
			} finally {
				setIsLoaded(true);
			}
		};

		getContents();
	}, []);

	/**
	 * This function handles the entire form save
	 */
	const handleSettingsSave = async (data: SettingsClearancesDB) => {
		try {
			await setting.clearances.update(jwt, data);
			toast.success('Saved settings');
		} catch (error) {
			// TODO: Handle better
			console.error('error');
			console.error(error);
			toast.error('Failed to save settings');
		}
	};

	/**
	 * This function handles the click of a details tab
	 */
	const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabName: 'type-setting' | 'upload-setting' | 'member-group') => {
		setActiveTab(tabName);

		// We don't want to have the actual <a> perform an action
		e.preventDefault();
	};

	/**
	 * The render function for the React component
	 */
	return (
		<div className="content">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Clearance Settings</CardTitle>
						</CardHeader>
						<CardBody>
							{isLoaded ? (
								<>
									<div className="nav-tabs-navigation">
										<div className="nav-tabs-wrapper">
											<Nav id="tabs" role="tablist" tabs>
												<NavItem>
													<NavLink
														data-toggle="tab"
														href="#"
														role="tab"
														className={activeTab === 'type-setting' ? 'active' : ''}
														onClick={(e) => handleTabClick(e, 'type-setting')}
													>
														Types
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														data-toggle="tab"
														href="#"
														role="tab"
														className={activeTab === 'upload-setting' ? 'active' : ''}
														onClick={(e) => handleTabClick(e, 'upload-setting')}
													>
														Upload Settings
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														data-toggle="tab"
														href="#"
														role="tab"
														className={activeTab === 'member-group' ? 'active' : ''}
														onClick={(e) => handleTabClick(e, 'member-group')}
													>
														Member Group
													</NavLink>
												</NavItem>
											</Nav>
										</div>
									</div>
									<TabContent activeTab={activeTab}>
										<TabPane tabId="type-setting" role="tabpanel">
											<TypeSettingSection data={data} onSave={handleSettingsSave} />
										</TabPane>
										<TabPane tabId="upload-setting" role="tabpanel">
											<UploadSettingSection data={data} onSave={handleSettingsSave} />
										</TabPane>
										<TabPane tabId="member-group" role="tabpanel">
											<MemberGroupSection data={data} onSave={handleSettingsSave} />
										</TabPane>
									</TabContent>
								</>
							) : (
								<FaSpinner size={12} color="#ccc" className="fa-spin" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
