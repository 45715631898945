import React from 'react';

import { Input } from 'reactstrap';
import { SettingsDataField } from 'api/settings/general';
import { InputType } from 'reactstrap/types/lib/Input';

interface Props {
	settingsDataField: SettingsDataField;
	value: string | number;
	fieldName: string;
	onChange: (newValue: string | number) => void;
	type: InputType;
}

export const InputField = (props: Props) => {
	const { settingsDataField, value, onChange, fieldName, type } = props;
	const { description } = settingsDataField;

	return (
		<Input
			type={type}
			name={fieldName}
			id={fieldName}
			placeholder={description}
			onChange={(e) => onChange(e.target.value)}
			value={value != null ? value : ''}
		/>
	);
};
