import React from 'react';

import { Input, FormGroup, Row, Col, Label } from 'reactstrap';
import { ReportDB } from 'api/reports';

interface Props {
	onChange: (fieldName: keyof ReportDB, newValue: any) => void;
	data: ReportDB;
}

export const ReportType = (props: Props) => {
	const { onChange, data } = props;

	return (
		<>
			<Row>
				<Col md={3}>
					<FormGroup>
						<Label for="type">Report Type</Label>
						<Input
							type="select"
							name="type"
							onChange={(e) => {
								onChange('type', e.target.value);
							}}
							value={data.type != null ? data.type : 'ad-hoc'}
						>
							<option value={'ad-hoc'}>Ad-hoc</option>
							<option value={'recurring'}>Recurring</option>
						</Input>
					</FormGroup>
				</Col>
				<Col md={3}>
					{data.type === 'recurring' ? (
						<FormGroup>
							<Label for="frequency">Frequency</Label>
							<Input
								type="select"
								name="frequency"
								id="frequency"
								onChange={(e) => {
									onChange('frequency', e.target.value);
								}}
								value={data.frequency != null ? data.frequency : 'daily'}
							>
								<option value={'daily'}>Daily</option>
								<option value={'weekly'}>Weekly</option>
								<option value={'monthly'}>Monthly</option>
								<option value={'quarterly'}>Quarterly</option>
								<option value={'annually'}>Annually</option>
								<option value={'every-weekday'}>Every Weekday</option>
							</Input>
						</FormGroup>
					) : (
						<></>
					)}
				</Col>
				<Col md={3}>
					{data.type === 'recurring' && data.frequency === 'weekly' ? (
						<FormGroup>
							<Label for="dayOfWeek">Day of Week</Label>
							<Input
								type="select"
								name="dayOfWeek"
								id="dayOfWeek"
								onChange={(e) => {
									onChange('dayOfWeek', e.target.value);
								}}
								defaultValue={'monday'}
							>
								<option value={'monday'}>Monday</option>
								<option value={'tuesday'}>Tuesday</option>
								<option value={'wednesday'}>Wednesday</option>
								<option value={'thursday'}>Thursday</option>
								<option value={'friday'}>Friday</option>
								<option value={'saturday'}>Saturday</option>
								<option value={'sunday'}>Sunday</option>
							</Input>
						</FormGroup>
					) : data.type === 'recurring' && data.frequency === 'monthly' ? (
						<FormGroup>
							<Label for="dayOfMonth">Day of Month</Label>
							<Input
								type="number"
								name="dayOfMonth"
								id="dayOfMonth"
								onChange={(e) => {
									onChange('dayOfMonth', Number(e.target.value));
								}}
								max={`31`}
								value={data.dayOfMonth != null ? data.dayOfMonth : 1}
							/>
						</FormGroup>
					) : data.type === 'recurring' && data.frequency === 'annually' ? (
						<FormGroup>
							<Label for="dayOfYear">Day of Year</Label>
							<Input
								type="number"
								name="dayOfYear"
								id="dayOfYear"
								onChange={(e) => {
									onChange('dayOfYear', Number(e.target.value));
								}}
								max={`365`}
								value={data.dayOfYear != null ? data.dayOfYear : 1}
							/>
						</FormGroup>
					) : (
						<></>
					)}
				</Col>
			</Row>
		</>
	);
};
