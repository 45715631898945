import React from 'react';

import { FormGroup, Row, Col, Label, Input } from 'reactstrap';

import { FieldConditions } from './FieldConditions/FieldConditions';
import { ReportColumn, ReportDB } from 'api/reports';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	onUpdate: (fieldName: keyof ReportColumn, newValue: any) => void;
	data: ReportColumn;
	settingsDataFields: Array<SettingsDataField>;
}

export const DataFieldTab = (props: Props) => {
	const { onUpdate, settingsDataFields, data } = props;

	return (
		<>
			<Row>
				<Col md={4}>
					<FormGroup>
						<Label for="fieldColumn">Field</Label>
						<Input
							type="select"
							name="fieldColumn"
							id="fieldColumn"
							onChange={(e) => onUpdate('column', e.target.value)}
							value={data.column != null ? data.column : ''}
						>
							<option value={''}>- Select Column -</option>
							{settingsDataFields != null && settingsDataFields.length > 0 ? (
								settingsDataFields.map((item) => {
									return (
										<option key={`column-attributes-section-details-overlay-field-option-${item.uid}`} value={item.uid}>
											{item.name}
										</option>
									);
								})
							) : (
								<></>
							)}
						</Input>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup>
						<Label for="show">Show</Label>
						<Input
							type="select"
							name="show"
							id="show"
							onChange={(e) => onUpdate('show', e.target.value === 'true')}
							defaultValue={(data.show != null ? data.show : true) as any}
						>
							<option value={true as any}>Yes</option>
							<option value={false as any}>No</option>
						</Input>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup>
						<Label for="name">Override Name</Label>
						<Input
							type="text"
							name="name"
							id="name"
							onChange={(e) => onUpdate('name', e.target.value === 'true')}
							value={data.name != null ? data.name : ''}
						/>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup>
						<Label for="sort">Sort</Label>
						<Input
							type="select"
							name="sort"
							id="sort"
							onChange={(e) => onUpdate('sort', e.target.value)}
							defaultValue={(data.sort != null ? data.sort : null) as any}
						>
							<option value={null as any}>None</option>
							<option value="asc">Ascending</option>
							<option value="desc">Descending</option>
						</Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<FormGroup>
						<Label for="conditions">Conditions</Label>
						<FieldConditions
							onUpdate={(newValue: any) => {
								onUpdate('conditions', newValue);
							}}
							selectedDataFields={data.column != null ? settingsDataFields.filter((field) => field.uid === data.column)[0] : (null as any)}
							conditions={data.conditions}
						/>
					</FormGroup>
				</Col>
			</Row>
		</>
	);
};
