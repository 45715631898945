import React, { useState } from 'react';

import { Input, Badge, Button } from 'reactstrap';
import { SettingsDataField } from 'api/settings/general';
import { InputType } from 'reactstrap/types/lib/Input';

interface Props {
	detailsField: SettingsDataField;
	fieldName: string;
	inputValue: any;
	type: InputType;
	handleFieldChange: (newValue: any) => void;
	handleInputFieldMultipleAddMode: (detailsFieldUID: string, state: boolean) => void;
}

export const TabDataEditModeInputField = (props: Props) => {
	const { detailsField, inputValue, fieldName, type, handleFieldChange, handleInputFieldMultipleAddMode } = props;
	const { description, attributes } = detailsField;
	const [inAddMode, setInAddMode] = useState(false);
	const [addModeInputValue, setAddModeInputValue] = useState<Array<any> | null>([]);

	// This is a local variable of the inputValue prop... processing it here
	let parentInputValue = inputValue;

	// Check if this supports multiple values or not
	if (attributes.multiple === true) {
		// Make sure we have a valid default value
		if (parentInputValue == null) {
			parentInputValue = [];
		}

		/**
		 * This function will handle the pill "X" click
		 * @param {*} e
		 * @param {*} valObjId
		 */
		const handleRemovePillClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, valObjId: any) => {
			e.preventDefault();

			handleFieldChange(parentInputValue.filter((val: any) => val !== valObjId));
		};

		/**
		 * This function will toggle the add pill mode
		 */
		const handleToggleAddPillModeClick = () => {
			if (inAddMode === true) {
				setAddModeInputValue(null);
				setInAddMode(false);
				handleInputFieldMultipleAddMode(detailsField.uid, false);
			} else {
				setInAddMode(true);
				handleInputFieldMultipleAddMode(detailsField.uid, true);
			}
		};

		/**
		 * This function will handle the change of the input select
		 */
		const handleInputFieldChange = (inputVal: any) => {
			setAddModeInputValue(inputVal);
		};

		/**
		 * This function will process the selected value and add it to the parent value set
		 */
		const handleAddPillValueClick = () => {
			if (addModeInputValue != null) {
				parentInputValue.push(addModeInputValue);

				return Promise.resolve()
					.then(() => {
						return handleFieldChange(parentInputValue);
					})
					.then(() => {
						setAddModeInputValue(null);
						setInAddMode(false);
						handleInputFieldMultipleAddMode(detailsField.uid, false);
					});
			}
		};

		return (
			<div className="tab-data-edit-mode-input-field">
				<div className="pill-section">
					{Array.isArray(parentInputValue) && parentInputValue.length > 0 ? (
						parentInputValue.map((valueObj) => {
							return (
								<Badge key={`tab-data-edit-mode-input-field-pill-badge-${valueObj}`} className="pill" color="primary" pill>
									{valueObj}
									<Button className="close" color="link" onClick={(e) => handleRemovePillClick(e, valueObj)}>
										<i className="fa fa-close" />
									</Button>
								</Badge>
							);
						})
					) : (
						<div className="no-values">Click add to update this field</div>
					)}
					{inAddMode === true ? (
						<Button
							className="add-button"
							color="link"
							size="sm"
							onClick={() => {
								handleToggleAddPillModeClick();
							}}
						>
							Cancel
						</Button>
					) : (
						<Button
							className="add-button"
							color="link"
							size="sm"
							onClick={() => {
								handleToggleAddPillModeClick();
							}}
							disabled={false}
						>
							<i className="fa fa-plus" /> Add
						</Button>
					)}
				</div>
				<div className="add-section">
					{inAddMode === true ? (
						<>
							<Input
								type={type}
								name={fieldName}
								id={fieldName}
								placeholder={description}
								onChange={(e) => {
									handleInputFieldChange(e.target.value);
								}}
							/>
							<Button
								className="add-button"
								color="primary"
								size="sm"
								onClick={() => {
									handleAddPillValueClick();
								}}
								disabled={addModeInputValue == null}
							>
								Add
							</Button>
						</>
					) : (
						<></>
					)}
				</div>
			</div>
		);
	} else {
		// Make sure we have a valid default value
		if (parentInputValue == null) {
			parentInputValue = '';
		}

		return (
			<Input
				type={type}
				name={fieldName}
				id={fieldName}
				placeholder={description}
				onChange={(e) => {
					handleFieldChange(e.target.value);
				}}
				value={parentInputValue}
			/>
		);
	}
};
