import React from 'react';

import { InputField } from './InputField';
import { SelectField } from './SelectField';
import { GroupField } from './GroupField';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	index: number;
	settingsDataField: SettingsDataField;
	value: string | number | Date | boolean | Array<string | number> | object;
	onUpdate: (newValue: string | number | Array<string | number> | object) => void;
}

export const FieldConditionSelector = (props: Props) => {
	const { index, settingsDataField, value, onUpdate } = props;
	const { uid, attributes } = settingsDataField;
	const fieldName = `field-condition-selector-${index}-${uid}`;

	if (attributes.type == null) {
		return null;
	}

	// Figure out what type of field we need to handle
	if (attributes.type === 'string') {
		return <InputField settingsDataField={settingsDataField} fieldName={fieldName} type="text" value={value as string | number} onChange={onUpdate} />;
	} else if (attributes.type === 'date') {
		let genVal = value;
		if (genVal != null && typeof genVal === 'string' && genVal.length > 0) {
			const parts = genVal.toString().match(/^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/);

			if (parts) {
				// yyyy-mm-ddd
				genVal = `${parts[1]}-${parts[2]}-${parts[3]}`;
			}
		}

		return <InputField settingsDataField={settingsDataField} fieldName={fieldName} type={'date'} value={value as string | number} onChange={onUpdate} />;
	} else if (attributes.type === 'int') {
		const handleNumberChange = (newValue: string | number) => {
			onUpdate(typeof newValue !== 'number' ? parseInt(newValue) : newValue);
		};

		return (
			<InputField
				settingsDataField={settingsDataField}
				fieldName={fieldName}
				type={'number'}
				value={value as string | number}
				onChange={handleNumberChange}
			/>
		);
	} else if (attributes.type === 'dropdown') {
		let selectedValue: string | number = '';

		if (attributes.options != null) {
			if (attributes.multiple === true) {
				if (value != null && Array.isArray(value)) {
					for (const val of value) {
						const selectedOption = attributes.options!.filter((option) => option.uid === val)[0];

						if (selectedOption != null) {
							selectedValue = selectedOption.uid;
						}
					}
				} else {
					const selectedOption = attributes.options!.filter((option) => option.uid === value)[0];

					if (selectedOption != null) {
						selectedValue = selectedOption.uid;
					}
				}
			} else {
				const selectedOption = attributes.options.filter((option) => option.uid === value)[0];

				if (selectedOption != null) {
					selectedValue = selectedOption.uid;
				}
			}
		}

		return <SelectField settingsDataField={settingsDataField} fieldName={fieldName} value={selectedValue} onChange={onUpdate} />;
	} else if (attributes.type === 'email') {
		return <InputField settingsDataField={settingsDataField} fieldName={fieldName} type={'text'} value={value as string} onChange={onUpdate} />;
	} else if (attributes.type === 'group') {
		return <GroupField settingsDataField={settingsDataField} value={value} onChange={onUpdate} />;
	} else {
		// TODO: Implement
	}

	return null;
};
