import React from 'react';

import { Button, Input, FormGroup, Col, UncontrolledTooltip } from 'reactstrap';

import { ReportNotificationRecipientConditionAdministratorRole } from 'api/reports';

interface Props {
	data: ReportNotificationRecipientConditionAdministratorRole;
	onUpdate: (fieldName: keyof ReportNotificationRecipientConditionAdministratorRole, newValue: any) => void;
	onDelete: () => void;
}

export const RecipientConditionAdministratorRole = (props: Props) => {
	const { data, onUpdate, onDelete } = props;

	const key = 1;

	return (
		<>
			<Col md={1}>{key > 0 ? 'or' : ''}</Col>
			<Col md={4}>
				<FormGroup>
					<Input
						type="select"
						name={`inputLogicOperator-${key}`}
						id={`inputLogicOperator-${key}`}
						onChange={(e) => {
							onUpdate('logicOperator', e.target.value);
						}}
						defaultValue={data.logicOperator}
					>
						<option value="equal-to">Equal to</option>
						<option value="contains">Contains</option>
						<option value="starts-with">Starts with</option>
						<option value="ends-with">Ends with</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={1}>
				<FormGroup className="pull-right">
					<Button
						className="btn-icon"
						color="danger"
						id={`notifications-section-recipient-overlay-condition-delete`}
						size="sm"
						type="button"
						onClick={onDelete}
					>
						<i className="fa fa-times" />
					</Button>
					<UncontrolledTooltip delay={0} target={`notifications-section-recipient-overlay-condition-delete`}>
						Remove
					</UncontrolledTooltip>
				</FormGroup>
			</Col>
		</>
	);
};
