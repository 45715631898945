import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';

import { Button, Alert } from 'reactstrap';

import UserContext from '../../../UserContext';
import toast from 'react-hot-toast';

import { frontend } from '../../../../api';

import { DocumentUpload as UploadDocument } from '../../../DocumentUpload';
import { useNavigate } from 'react-router-dom';
import { InvalidParametersException } from '../../../../api/exceptions/InvalidParametersException';
import { UnauthorizedException } from '../../../../api/exceptions/UnauthorizedException';
import { ForbiddenException } from '../../../../api/exceptions/ForbiddenException';
import { ResourceNotFoundException } from '../../../../api/exceptions/ResourceNotFoundException';
import { InternalServerErrorException } from '../../../../api/exceptions/InternalServerErrorException';
import { FailedToFetchException } from '../../../../api/exceptions/FailedToFetchException';

export const DocumentUpload = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const [clearanceSettings, setClearanceSettings] = useState<
		Array<{ docID: string; name: string; certification_date: string; approved?: boolean | undefined; readonly?: boolean | undefined }>
	>([]);
	const [uploadResultAlert, setUploadResultAlert] = useState({ show: false, status: 'danger', message: 'Failed to upload' });

	useEffect(() => {
		const getContents = async () => {
			try {
				const clearanceSettings = await frontend.getClearanceSettings(user!.token!);
				setClearanceSettings(clearanceSettings);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					navigate('/app/login');
				} else if (error instanceof FailedToFetchException) {
					toast.error(error.message);
				} else {
					console.error('error');
					console.error(error);
					Sentry.captureException(error);
				}
			}
		};

		getContents();
	}, []);

	const handleConfirmUploadStepClick = async (formData: FormData) => {
		try {
			const data = await frontend.uploadClearance(user != null && user.token != null ? user.token : '', formData);
			if (data != null) {
				setUploadResultAlert({ show: true, status: 'success', message: 'Successfully uploaded file.' });
			}
		} catch (err: any) {
			if (err instanceof InvalidParametersException) {
				setUploadResultAlert({ show: true, status: 'danger', message: err.message != null ? err.message : uploadResultAlert.message });
			} else if (err instanceof UnauthorizedException) {
				setUploadResultAlert({ show: true, status: 'danger', message: 'Session expired. Please login and try again.' });
			} else if (err instanceof ForbiddenException) {
				setUploadResultAlert({ show: true, status: 'danger', message: err.message != null ? err.message : uploadResultAlert.message });
				Sentry.captureException(err);
			} else if (err instanceof ResourceNotFoundException) {
				setUploadResultAlert({ show: true, status: 'danger', message: err.message != null ? err.message : uploadResultAlert.message });
				Sentry.captureException(err);
			} else if (err instanceof InternalServerErrorException) {
				setUploadResultAlert({ show: true, status: 'danger', message: 'Failed to upload. Please try again.' });
			} else if (err instanceof FailedToFetchException) {
				setUploadResultAlert({ show: true, status: 'danger', message: 'Failed to upload. Please try again.' });
			} else {
				setUploadResultAlert({ show: true, status: 'danger', message: err.message != null ? err.message : uploadResultAlert.message });
				Sentry.captureException(err);
			}
		}
	};

	const handleUploadResultAlertClick = () => {
		setUploadResultAlert({ show: false, status: 'danger', message: 'Failed to upload' });
	};

	return (
		<div className="clearances-tab-document-upload">
			<Alert color={uploadResultAlert.status} isOpen={uploadResultAlert.show} toggle={handleUploadResultAlertClick} fade={true}>
				<span>{uploadResultAlert.message}</span>
			</Alert>

			<UploadDocument
				key={'clearances-tab-document-upload-upload-document'}
				clearanceSettings={clearanceSettings}
				validFileTypes={'image/*'}
				handleUploadFiles={handleConfirmUploadStepClick}
			/>
			<div className="back-button">
				<Button color="link" size="sm" onClick={() => navigate('..')}>
					Back
				</Button>
			</div>
		</div>
	);
};
