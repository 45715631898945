import React, { useContext, useEffect, useState } from 'react';

import { Col, FormGroup, Label, Input, Form } from 'reactstrap';
import { validateEmailAddress } from '../../../../validator';
import { AdministratorUserDB } from 'api/administrators/users';
import { AdministratorRoleDB } from 'api/administrators/roles';

interface Props {
	data: AdministratorUserDB;
	roles: Array<AdministratorRoleDB>;
}

export const DetailsView = (props: Props) => {
	const { data, roles } = props;

	let roleName = 'Unknown';

	if (data.role != null && roles != null && roles.length > 0) {
		const roleObj = roles.filter((role) => role.docID === data.role)[0];

		if (roleObj != null) {
			roleName = roleObj.name;
		}
	}
	/**
	 * The render function for the React component
	 */
	return (
		<Form className="edit-form">
			<FormGroup row>
				<Label for={`field-name`} sm={3}>
					<strong>Name</strong>
				</Label>
				<Col sm={9}>{data.name != null ? data.name : ''}</Col>
			</FormGroup>
			<FormGroup row>
				<Label for={`field-email`} sm={3}>
					<strong>Email Address</strong>
				</Label>
				<Col sm={9}>{data.email != null ? data.email : ''}</Col>
			</FormGroup>
			<FormGroup row>
				<Label for={`field-role`} sm={3}>
					<strong>Role</strong>
				</Label>
				<Col sm={9}>{roleName}</Col>
			</FormGroup>
		</Form>
	);
};
