export class MemberDocumentPreviouslyDeniedException extends Error {
	/**
	 * Constructor
	 * @param {string} message the error message
	 */
	constructor(message: string) {
		super(message);

		// If we support a stack trace, capture it
		if ('captureStackTrace' in Error) {
			Error.captureStackTrace(this, this.constructor);
		} else {
			console.error(`MemberDocumentPreviouslyDeniedException Does not support captureStackTrace()!`);
		}
	}
}
