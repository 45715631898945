import React, { useState } from 'react';

import { Form, Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { DataFieldRecordOverlay } from './DataFieldRecordOverlay';
import { TableRecordDeletePrompt } from '../TableRecordDeletePrompt';
import { SettingsDataField, SettingsGeneralDB } from 'api/settings/general';

interface Props {
	data: SettingsGeneralDB;
	onChange: (newData: any) => void;
}

export const DataFieldSection = (props: Props) => {
	const { data, onChange } = props;
	const [showTableRecordDetailsModal, setShowTableRecordDetailsModal] = useState(false);
	const [tableRecordDetailsData, setTableRecordDetailsData] = useState<SettingsDataField | null>(null);
	const [tableRecordDeleteId, setTableRecordDeleteId] = useState<string | null>(null);

	const handleTableRecordDetailsModalToggle = () => {
		setShowTableRecordDetailsModal(!showTableRecordDetailsModal);
	};

	const handleTableRecordDeleteClick = (id: string) => {
		setTableRecordDeleteId(id);
	};

	const handlTableRecordDeleteConfirm = () => {
		let rowToRemove = null;

		data.data_fields.forEach((row, index) => {
			if (row.uid === tableRecordDeleteId) {
				rowToRemove = index;
			}
		});

		if (rowToRemove != null) {
			data.data_fields.splice(rowToRemove, 1);
		}

		onChange(data);
		setTableRecordDeleteId(null);
	};

	const handlTableRecordDeleteConfirmCancel = () => {
		setTableRecordDeleteId(null);
	};

	const handleTableRecordDetailsSave = (inputData: SettingsDataField) => {
		let update = null;

		// First we need to check if the uid already exists. If it does, this is an update
		data.data_fields.forEach((record, index) => {
			if (record.uid === inputData.uid) {
				// This is an update
				update = index;
			}
		});

		if (update == null) {
			// This is an add
			data.data_fields.push(inputData);
		} else {
			// This is an update
			data.data_fields[update] = inputData;
		}

		onChange(data.data_fields);
		setTableRecordDetailsData(null);
		handleTableRecordDetailsModalToggle();
	};

	return (
		<div className="clearance-settings-upload-section">
			<DataFieldRecordOverlay
				key={`settings-data-field-record-${tableRecordDetailsData == null ? 'add' : `edit-${tableRecordDetailsData.uid}`}`}
				show={showTableRecordDetailsModal}
				editRecord={tableRecordDetailsData!}
				onClose={() => handleTableRecordDetailsModalToggle()}
				onSave={handleTableRecordDetailsSave}
			/>
			<TableRecordDeletePrompt
				show={tableRecordDeleteId != null}
				onConfirm={() => {
					handlTableRecordDeleteConfirm();
				}}
				onCancel={() => {
					handlTableRecordDeleteConfirmCancel();
				}}
			>
				This action cannot be undone and all data associated with the field will no longer be accessible.
			</TableRecordDeletePrompt>
			<Form>
				<FormGroup row>
					<Col sm={12}>
						{data.data_fields != null && data.data_fields.length > 0 ? (
							<Table responsive style={{ marginBottom: 0 }}>
								<thead className="text-primary">
									<tr>
										<th className="text-left">Name</th>
										<th className="text-left">Description</th>
										<th className="text-center">Primary</th>
										<th className="text-right">Actions</th>
									</tr>
								</thead>
								<tbody>
									{data.data_fields.map((row, key) => {
										const { uid, name, description, primary, attributes } = row;
										const { canDelete, canModify } = attributes;

										return (
											<tr key={`general-settings-data-field-${uid}`}>
												<td>{name}</td>
												<td>{description}</td>
												<td className="text-center">{primary === true ? 'Yes' : ''}</td>
												<td className="text-right">
													<Button
														className="btn-icon"
														color="success"
														id={`general-settings-data-field-${key}-edit`}
														size="sm"
														type="button"
														// disabled={canModify != null && canModify !== true ? true : false}
														disabled
													>
														<i className="fa fa-edit" />
													</Button>{' '}
													<UncontrolledTooltip delay={0} target={`general-settings-data-field-${key}-edit`}>
														Edit
													</UncontrolledTooltip>
													<Button
														className="btn-icon"
														color="danger"
														id={`general-settings-data-field-${key}-delete`}
														size="sm"
														type="button"
														disabled={true || (canDelete != null && canDelete !== true) ? true : false}
														onClick={() => handleTableRecordDeleteClick(uid)}
													>
														<i className="fa fa-times" />
													</Button>{' '}
													<UncontrolledTooltip delay={0} target={`general-settings-data-field-${key}-delete`}>
														Delete
													</UncontrolledTooltip>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						) : (
							<Alert color="secondary">
								<span>No fields defined</span>
							</Alert>
						)}

						<div className="pull-right">
							<Button
								className="btn-icon"
								id={`general-settings-data-field-add`}
								color="info"
								size="sm"
								onClick={() => handleTableRecordDetailsModalToggle()}
							>
								<i className="fa fa-plus" />
							</Button>
							<UncontrolledTooltip delay={0} target={`general-settings-data-field-add`}>
								Add Row
							</UncontrolledTooltip>
						</div>
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
