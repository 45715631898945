import React, { useState, useContext, useEffect } from 'react';

import { Alert, Button, Card, CardHeader, CardBody, CardFooter, Form, Input, InputGroupText, InputGroup, Container, Col, Row } from 'reactstrap';

import { login } from '../../api';
import UserContext from '../UserContext';
import { FaKey, FaSpinner, FaUser } from 'react-icons/fa';

interface Props {
	callback: () => void;
}

export const LoginOverlay = (props: Props) => {
	const { callback } = props;
	const { setDisplayUserLoginOverlay } = useContext(UserContext);

	const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
	const [messageState, setMessageState] = useState('info');
	const [message, setMessage] = useState<string | null>(null);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	async function handleLoginFormAction(event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();

		try {
			// Check if we have valid input
			// TODO: Implement better checks
			if (username === '' || password === '') {
				throw new Error('Please enter your username and password');
			}

			// Set the processing state
			setMessageState('info');
			setMessage(null);
			setFormSubmitInProgress(true);

			// Send to server
			// const loginResponse = await login.login(username, password);
			// await setUserCredentials(loginResponse);

			// Callback
			if (callback != null) {
				callback();
			}

			// Close the window
			return setDisplayUserLoginOverlay(false);
		} catch (error: any) {
			setMessageState('danger');
			setMessage(error.message);
			setFormSubmitInProgress(false);
		}
	}

	return (
		<div className="login-overlay">
			<Container>
				<Row>
					<Col className="ml-auto mr-auto" lg="4" md="6">
						<Form action="" className="form" method="" onSubmit={(e) => handleLoginFormAction(e)}>
							<Card className="card-login">
								<CardHeader>
									<CardHeader>
										<h3 className="header text-center">Login</h3>
									</CardHeader>
								</CardHeader>
								<CardBody>
									{message != null && (
										<Alert color={messageState}>
											<span>{message}</span>
										</Alert>
									)}
									<InputGroup style={{ background: 'none' }}>
										<InputGroupText>
											<FaUser color="#66615b" />
										</InputGroupText>
										<Input placeholder="Email Address" type="text" onChange={(e) => setUsername(e.target.value)} />
									</InputGroup>
									<InputGroup style={{ background: 'none' }}>
										<InputGroupText>
											<FaKey color="#66615b" />
										</InputGroupText>
										<Input placeholder="Password" type="password" autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
									</InputGroup>
								</CardBody>
								<CardFooter>
									<Button
										type="submit"
										block
										className="btn-round mb-3"
										color="warning"
										onClick={(e) => handleLoginFormAction(e)}
										disabled={formSubmitInProgress === true}
									>
										{formSubmitInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Login'}
									</Button>
								</CardFooter>
							</Card>
						</Form>
					</Col>
				</Row>
			</Container>
			<div className="backdrop"></div>
		</div>
	);
};
