import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { RoleDetails } from './partials/RoleDetails';
import { RolesTable } from './partials/RolesTable';

export const AdministratorRoles = () => {
	return (
		<div className="content administrators-roles-page">
			<Routes>
				<Route path={'add'} element={<RoleDetails />} />
				<Route path={':uid'} element={<RoleDetails />} />
				<Route index element={<RolesTable />} />
			</Routes>
		</div>
	);
};
