import React from 'react';

import { TabDataEditModeInputField } from './TabDataEditModeInputField';
import { TabDataEditModeSelectField } from './TabDataEditModeSelectField';
import { TabDataEditModeGroupField } from './TabDataEditModeGroupField';

// Excpetions
import { MemberDataRenderException } from '../../../../../api/exceptions/MemberDataRenderException';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	detailsField: SettingsDataField;
	detailsFieldValue: any;
	handleInputFieldChange: (fieldName: string, newValue: any, dataType?: string, isPrimaryField?: boolean) => void;
	handleInputFieldMultipleAddMode: (detailsFieldUID: string, state: boolean) => void;
}

export const TabDataEditMode = (props: Props) => {
	const { detailsField, detailsFieldValue, handleInputFieldChange, handleInputFieldMultipleAddMode } = props;
	const { uid, attributes } = detailsField;
	const fieldName = `member-details-overlay-details-tab-data-edit-${uid}`;

	/**
	 * Abstract the parent input field change to make it easier for the child components
	 * @param {*} newValue
	 */
	const handleEditModeFieldChange = (newValue: any) => {
		handleInputFieldChange(detailsField.uid, newValue, detailsField.attributes.type, detailsField.primary === true ? true : false);
	};

	// Figure out what type of field we need to handle
	if (attributes != null && attributes.type != null) {
		if (attributes.type === 'string') {
			return (
				<TabDataEditModeInputField
					detailsField={detailsField}
					fieldName={fieldName}
					type={'text'}
					inputValue={detailsFieldValue}
					handleFieldChange={handleEditModeFieldChange}
					handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
				/>
			);
		} else if (attributes.type === 'date') {
			let value = null;
			if (detailsFieldValue != null && detailsFieldValue.length > 0) {
				const parts = detailsFieldValue.toString().match(/^(\d{4})[-/]?(\d{2})[-/]?(\d{2})$/);

				if (parts) {
					// yyyy-mm-ddd
					value = `${parts[1]}-${parts[2]}-${parts[3]}`;
				}
			} else {
				value = '';
			}

			return (
				<TabDataEditModeInputField
					detailsField={detailsField}
					fieldName={fieldName}
					type={'date'}
					inputValue={value}
					handleFieldChange={handleEditModeFieldChange}
					handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
				/>
			);
		} else if (attributes.type === 'int') {
			return (
				<TabDataEditModeInputField
					detailsField={detailsField}
					fieldName={fieldName}
					type={'number'}
					inputValue={detailsFieldValue}
					handleFieldChange={handleEditModeFieldChange}
					handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
				/>
			);
		} else if (attributes.type === 'dropdown') {
			let selectedValue: string | Array<any> = '';

			if (attributes.multiple === true) {
				selectedValue = [];
			}

			if (attributes.options != null) {
				if (attributes.multiple === true) {
					if (detailsFieldValue != null && Array.isArray(detailsFieldValue) === true) {
						detailsFieldValue.forEach((val: any) => {
							const selectedOption = attributes.options!.filter((option) => option.uid === val)[0];

							if (selectedOption != null) {
								(selectedValue as Array<any>).push({ uid: selectedOption.uid, name: selectedOption.name });
							}
						});
					}
				} else {
					const selectedOption = attributes.options.filter((option) => option.uid === detailsFieldValue)[0];

					if (selectedOption != null) {
						selectedValue = selectedOption.uid;
					}
				}
			}

			return (
				<TabDataEditModeSelectField
					fieldName={fieldName}
					detailsField={detailsField}
					selectedValue={selectedValue}
					handleFieldChange={handleEditModeFieldChange}
					handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
				/>
			);
		} else if (attributes.type === 'email') {
			return (
				<TabDataEditModeInputField
					detailsField={detailsField}
					fieldName={fieldName}
					type={'text'}
					inputValue={detailsFieldValue}
					handleFieldChange={handleEditModeFieldChange}
					handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
				/>
			);
		} else if (attributes.type === 'group') {
			return (
				<TabDataEditModeGroupField
					detailsField={detailsField}
					inputValue={detailsFieldValue}
					handleFieldChange={handleEditModeFieldChange}
					handleInputFieldMultipleAddMode={handleInputFieldMultipleAddMode}
				/>
			);
		} else {
			throw new MemberDataRenderException(`TabDataEditMode(): Unknown detailsField type for ${detailsField.uid}`);
		}
	}

	return null;
};
