// Node modules

// Types
import { SettingsClearancesDB } from './clearances';
import { SettingsGeneralDB } from './general';
import { SettingsReportsDB } from './reports';
import { SettingsNotificationsDB } from './notifications';

// Exceptions

// Helpers
import SettingsGeneral from './general';
import SettingsClearances from './clearances';
import SettingsReports from './reports';
import SettingsNotifications from './notifications';

/**
 * Database Settings Collection
 */
export interface SettingsDB {
	clearances: SettingsClearancesDB;
	general: SettingsGeneralDB;
	notifications: SettingsNotificationsDB;
	reports: SettingsReportsDB;
}

/**
 * Common settings entry for object attributes
 */
export interface SettingAttributesEntry {
	order?: number; // Default is "null" meaning no order (or bottom of the list if there are ordered items)
	canModify?: boolean; // Default is "true" meaning that an item can be modified
	canDelete?: boolean; // Default is "true" meaning that an item can be deleted
}

export default class Settings {
	clearances: SettingsClearances;
	general: SettingsGeneral;
	notifications: SettingsNotifications;
	reports: SettingsReports;

	constructor() {
		this.clearances = new SettingsClearances();

		this.general = new SettingsGeneral();

		this.notifications = new SettingsNotifications();

		this.reports = new SettingsReports();
	}
}
