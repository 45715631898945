import React, { useState } from 'react';

import { Button, Modal, Row, Col, Form, Input, FormGroup, Label, ModalHeader } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import { MemberImportTemplatePreProcessingStep } from 'api/members/templates';

interface Props {
	show: boolean;
	onClose: () => void;
	onSave: (fieldRowData: MemberImportTemplatePreProcessingStep) => void;
	data?: any;
}

export const PreProcessingStepOverlay = (props: Props) => {
	const { show, onClose, onSave } = props;
	const [data, setData] = useState(props.data ? props.data : {});
	const [hasFormChanged, setHasFormChanged] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);

	const handleInputFieldChange = (fieldName: string, fieldValue: any) => {
		let newData = { ...data };
		newData[fieldName] = fieldValue;
		setData(newData);
		setHasFormChanged(true);
	};

	const handleRowInputFieldChange = (newValue: any) => {
		let newData = { ...data };

		newData.excelColumnName = newValue;
		setData(newData);
		setHasFormChanged(true);
	};

	const handleOnSaveClick = () => {
		setFormSaveInProgress(true);
		onSave(data);
		onClose();
		setData({});
		setFormSaveInProgress(false);
	};

	return (
		<Modal isOpen={show} toggle={() => onClose()}>
			<ModalHeader toggle={() => onClose()}>Add Field</ModalHeader>
			<div className="modal-body">
				<Form>
					<FormGroup>
						<Label for="step">Step</Label>
						<Row key={`pre-processing-step-overlay-step`}>
							<Col md={12}>
								<FormGroup>
									<Input
										type={'number'}
										name={`pre-processing-step-overlay-step`}
										id={`pre-processing-step-overlay-step`}
										placeholder={'Enter step number'}
										onChange={(e) => {
											handleInputFieldChange('step', e.target.value);
										}}
										value={data.step != null ? data.step : ''}
									/>
								</FormGroup>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Label for="title">Title</Label>
						<Row key={`pre-processing-step-overlay-title`}>
							<Col md={12}>
								<FormGroup>
									<Input
										type={'text'}
										name={`pre-processing-step-overlay-title`}
										id={`pre-processing-step-overlay-title`}
										placeholder={'Enter step title'}
										onChange={(e) => {
											handleInputFieldChange('title', e.target.value);
										}}
										value={data.title != null ? data.title : ''}
									/>
								</FormGroup>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Label for="excelColumnName">Excel Field Column Name</Label>
						<Row key={`pre-processing-step-overlay-excel-name`}>
							<Col md={12}>
								<FormGroup>
									<Input
										type={'text'}
										name={`pre-processing-step-overlay-excel-name`}
										id={`pre-processing-step-overlay-excel-name`}
										placeholder={'Enter Excel column name'}
										onChange={(e) => {
											handleInputFieldChange('excelColumnName', e.target.value);
										}}
										value={data.excelColumnName != null ? data.excelColumnName : ''}
									/>
								</FormGroup>
							</Col>
						</Row>
					</FormGroup>
				</Form>
			</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="primary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						handleOnSaveClick();
					}}
					disabled={!hasFormChanged || formSaveInProgress}
				>
					Add
				</Button>
				<Button
					className="btn-round"
					color="secondary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						onClose();
					}}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
