import React, { useState, useEffect } from 'react';
import { Routes, Route, NavLink as RouterNavLink, generatePath, useParams, useNavigate, useLocation } from 'react-router-dom';

import { Form, Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { TableRecordDeletePrompt } from '../TableRecordDeletePrompt';
import { NotificationRecordOverlay } from './notification-section-helpers/NotificationRecordOverlay';
import { SettingsNotificationsDB, SettingsNotificationsListEntry } from 'api/settings/notifications';

interface Props {
	data: SettingsNotificationsDB;
	onChange: (fieldName: keyof SettingsNotificationsDB, value: any, type?: string) => void;
}

export const NotificationSection = (props: Props) => {
	const { data, onChange } = props;
	const { hash: locationHash, pathname: locationPathname, search: locationSearch } = useLocation();
	const { action: routerAction } = useParams<{ action: string }>();
	let navigate = useNavigate();

	const [showTableRecordDetailsModal, setShowTableRecordDetailsModal] = useState(false);
	const [tableRecordDetailsData, setTableRecordDetailsData] = useState<SettingsNotificationsListEntry | null>(null);
	const [tableRecordDeleteId, setTableRecordDeleteId] = useState<string | null>(null);

	useEffect(() => {
		if (routerAction === 'add') {
			setShowTableRecordDetailsModal(true);
		} else if (routerAction != null && routerAction !== '') {
			if (data != null && data.notification_list != null) {
				const recordIndex = data.notification_list.findIndex((record) => record.uid === routerAction);

				if (recordIndex > -1) {
					setShowTableRecordDetailsModal(true);
					setTableRecordDetailsData(data.notification_list[recordIndex]);
				}
			}
		}
	}, [routerAction]);

	/**
	 * This function will handle the toggle of the add/edit overlay
	 */
	const handleTableRecordDetailsModalToggle = () => {
		if (showTableRecordDetailsModal === true && tableRecordDetailsData !== null) {
			setTableRecordDetailsData(null);

			setShowTableRecordDetailsModal(false);

			let newUrl = `${locationPathname}`;
			const index = newUrl.indexOf(routerAction ?? '');
			if (index > -1) {
				newUrl = newUrl.substring(0, index - 1);
			}

			navigate(`${newUrl}`);
		} else if (showTableRecordDetailsModal === true) {
			setShowTableRecordDetailsModal(false);
			navigate('../notification');
		} else {
			setShowTableRecordDetailsModal(true);
		}
	};

	/**
	 * This function will trigger the add/edit overlay and pass in the data to edit
	 * @param {*} id
	 */
	const handleTableRecordEditClick = (record: SettingsNotificationsListEntry) => {
		setTableRecordDetailsData(record);
		navigate(`${locationPathname}/${record.uid}`);
	};

	/**
	 * This function will trigger the confirmation overlay before deleting the row
	 * @param {*} id
	 */
	const handleTableRecordDeleteClick = (id: string) => {
		setTableRecordDeleteId(id);
	};

	/**
	 * This function will remove the record from the table and save the result to the react state
	 */
	const handlTableRecordDeleteConfirm = () => {
		let rowToRemove = null;

		data.notification_list.forEach((row, index) => {
			if (row.uid === tableRecordDeleteId) {
				rowToRemove = index;
			}
		});

		if (rowToRemove != null) {
			data.notification_list.splice(rowToRemove, 1);
		}

		onChange('notification_list', data.notification_list);
		setTableRecordDeleteId(null);
	};

	/**
	 * This function will cancel/close the confirmation overlay and not remove a record
	 */
	const handlTableRecordDeleteConfirmCancel = () => {
		setTableRecordDeleteId(null);
	};

	/**
	 * This function will update the table record (notification settings) data from the caller (model)
	 * @param {*} inputData
	 */
	const handleTableRecordDetailsSave = (inputData: SettingsNotificationsListEntry) => {
		let update = null;

		// First we need to check if the uid already exists. If it does, this is an update
		data.notification_list.forEach((record, index) => {
			if (record.uid === inputData.uid) {
				// This is an update
				update = index;
			}
		});

		if (update == null) {
			// This is an add
			data.notification_list.push(inputData);
		} else {
			// This is an update
			data.notification_list[update] = inputData;
		}

		onChange('notification_list', data.notification_list);
		setTableRecordDetailsData(null);
		handleTableRecordDetailsModalToggle();
	};

	return (
		<div className="notification-settings-notification-section">
			<NotificationRecordOverlay
				key={`notification-notification-record-${tableRecordDetailsData == null ? 'add' : `edit-${tableRecordDetailsData.uid}`}`}
				show={showTableRecordDetailsModal}
				editRecord={tableRecordDetailsData!}
				onClose={() => handleTableRecordDetailsModalToggle()}
				onSave={handleTableRecordDetailsSave}
			/>
			<TableRecordDeletePrompt
				show={tableRecordDeleteId != null}
				onConfirm={() => {
					handlTableRecordDeleteConfirm();
				}}
				onCancel={() => {
					handlTableRecordDeleteConfirmCancel();
				}}
			>
				This action cannot be undone.
			</TableRecordDeletePrompt>
			<FormGroup tag="fieldset" row>
				<legend className="col-form-label col-sm-4">Notifications</legend>
			</FormGroup>
			<Form>
				<FormGroup row>
					<Col sm={12}>
						{data.notification_list != null && data.notification_list.length > 0 ? (
							<Table responsive style={{ marginBottom: 0 }}>
								<thead className="text-primary">
									<tr>
										<th className="text-left">Name</th>
										<th className="text-center">Event</th>
										<th className="text-center">To</th>
										<th className="text-right">Actions</th>
									</tr>
								</thead>
								<tbody>
									{data.notification_list.map((row, key) => {
										const { uid, name, event, attributes } = row;
										const { canDelete, canModify } = attributes != null ? attributes : { canDelete: true, canModify: true };

										return (
											<tr key={`notification-settings-notification-section-data-field-${uid}`}>
												<td>{name}</td>
												<td className="text-center">{event}</td>
												<td className="text-center">{'to'}</td>
												<td className="text-right">
													<Button
														className="btn-icon"
														color="success"
														id={`notification-settings-notification-section-data-field-${key}-edit`}
														size="sm"
														type="button"
														disabled={canModify != null && canModify !== true ? true : false}
														onClick={() => handleTableRecordEditClick(row)}
													>
														<i className="fa fa-edit" />
													</Button>{' '}
													<UncontrolledTooltip delay={0} target={`notification-settings-notification-section-data-field-${key}-edit`}>
														Edit
													</UncontrolledTooltip>
													<Button
														className="btn-icon"
														color="danger"
														id={`notification-settings-notification-section-data-field-${key}-delete`}
														size="sm"
														type="button"
														disabled={canDelete != null && canDelete !== true ? true : false}
														onClick={() => handleTableRecordDeleteClick(uid)}
													>
														<i className="fa fa-times" />
													</Button>{' '}
													<UncontrolledTooltip
														delay={0}
														target={`notification-settings-notification-section-data-field-${key}-delete`}
													>
														Delete
													</UncontrolledTooltip>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						) : (
							<Alert color="secondary">
								<span>No fields defined</span>
							</Alert>
						)}

						<div className="pull-right">
							<Button
								tag={RouterNavLink}
								to={`${locationPathname}/add`}
								exact
								className="btn-icon"
								id={`general-settings-data-field-add`}
								color="info"
								size="sm"
							>
								<i className="fa fa-plus" />
							</Button>
							<UncontrolledTooltip delay={0} target={`general-settings-data-field-add`}>
								Add Row
							</UncontrolledTooltip>
						</div>
					</Col>
				</FormGroup>
			</Form>
		</div>
	);
};
