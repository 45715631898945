import React from 'react';

import { Input, FormGroup, Label, Row, Col } from 'reactstrap';

import { RecipientsSection } from './settings-tab/RecipientsSection';

import { ACTIVE_EVENTS } from '@mouseware/lib-events';
import { SettingsNotificationsListEntry } from 'api/settings/notifications';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	data: SettingsNotificationsListEntry;
	dynamicDataFields: Array<SettingsDataField>;
	clearanceFields: Array<SettingsClearancesType>;
	handleInputFieldChange: (fieldName: keyof SettingsNotificationsListEntry, newValue: any) => void;
}

export const SettingsTab = (props: Props) => {
	const { data, dynamicDataFields, clearanceFields, handleInputFieldChange } = props;

	return (
		<>
			<Row>
				<Col md={5}>
					<FormGroup>
						<Label for="name">Name</Label>
						<Input
							type="text"
							name="name"
							id="name"
							placeholder="Enter Name"
							onChange={(e) => handleInputFieldChange('name', e.target.value)}
							value={data.name != null ? data.name : ''}
						/>
					</FormGroup>
				</Col>
				<Col md={4}>
					<FormGroup>
						<Label for="event">Event</Label>
						<Input
							type="select"
							name="event"
							id="event"
							onChange={(e) => handleInputFieldChange('event', e.target.value)}
							defaultValue={data.event != null ? data.event : ''}
						>
							<option value={null as any}>- Select -</option>
							{ACTIVE_EVENTS != null ? (
								ACTIVE_EVENTS.map((event) => {
									return (
										<option key={event} value={event}>
											{event}
										</option>
									);
								})
							) : (
								<></>
							)}
						</Input>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup>
						<Label for="require_approval">Require Approval</Label>
						<Input
							type="select"
							name="require_approval"
							id="require_approval"
							onChange={(e) => handleInputFieldChange('require_approval', e.target.value === 'true')}
							defaultValue={data.require_approval != null ? data.require_approval : (false as any)}
						>
							<option value={true as any}>Yes</option>
							<option value={false as any}>No</option>
						</Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<FormGroup>
						<Label for="conditions">Recipients</Label>
						<RecipientsSection
							recipientsData={data.recipients != null ? data.recipients : []}
							dynamicDataFields={dynamicDataFields}
							clearanceFields={clearanceFields}
							handleInputFieldChange={(newValue) => handleInputFieldChange('recipients', newValue)}
						/>
					</FormGroup>
				</Col>
			</Row>
		</>
	);
};
