"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionNotImplementedException = void 0;
/**
 * This exception is used a function is called that has not been implemented
 */
class FunctionNotImplementedException extends Error {
    /**
     * Optional function name to include in the error
     */
    functionName;
    /**
     * Constructor
     * @param {string} message the error message
     * @param {string} functionName the name of the function
     */
    constructor(message, functionName) {
        super(message);
        this.functionName = functionName;
        // If we support a stack trace, capture it
        if ('captureStackTrace' in Error) {
            Error.captureStackTrace(this, this.constructor);
        }
        else {
            console.error(`${this.constructor.name} Does not support captureStackTrace()!`);
        }
    }
    /**
     * Returns the function name that caused the error
     */
    getFunctionName() {
        return this.functionName;
    }
}
exports.FunctionNotImplementedException = FunctionNotImplementedException;
