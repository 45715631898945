import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { UnauthorizedException } from '../api/exceptions/UnauthorizedException';

import { Container, Col, Row } from 'reactstrap';

import { FrontendNavbar } from '../components/Frontend/Topbar';
import { UserContext } from '../components/UserContext';
import { ErrorHandler } from './ErrorHandler';
import { Frontend as FrontendPage } from '../components/Frontend';
import { FullPageLoading } from '../components/FullPageLoading';

import { AuthUser, getAuthenticatedUser } from '../api';

export const Frontend = () => {
	const navigate = useNavigate();
	const { hash, pathname, search: locationSearch } = useLocation();
	const [isCheckingUserAuthenticated, setIsCheckingUserAuthenticated] = useState(true);
	const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
	const [user, setUser] = useState<AuthUser | null>(null);
	const [jwt, setJWT] = useState<string | null>(null);
	const [backgroundColor, setBackgroundColor] = useState('black');
	const [activeColor, setActiveColor] = useState('info');
	const [sidebarMini, setSidebarMini] = useState(false);

	useEffect(() => {
		const getContents = async () => {
			try {
				const user = await getAuthenticatedUser();

				if (user != null) {
					setUser(user);
					setJWT(user.token!);
					setIsCheckingUserAuthenticated(false);
					setIsUserAuthenticated(true);
				} else {
					navigate('/app/login');
					setIsCheckingUserAuthenticated(false);
					setIsUserAuthenticated(false);
				}
			} catch (err) {
				if (err instanceof UnauthorizedException) {
					navigate({ pathname: `/app/login` }); // TODO: Support URL encoding

					setIsCheckingUserAuthenticated(false);
					setIsUserAuthenticated(false);
				}
			}
		};

		getContents();
	}, []);

	const handleLoginResult = async (data: AuthUser) => {
		if (data != null) {
			localStorage.setItem('user-authorization', data.token!);
			setIsUserAuthenticated(true);
			setUser(data);
			setJWT(data.token!);
			// This was successful so lets redirect to the url
			// Default is the dashboard
			let redirectUrl = '/app/dashboard';

			if (location != null) {
				const search = new URLSearchParams(locationSearch);
				const base64encodedUrl = await search.get('loc');

				if (base64encodedUrl != null) {
					try {
						if (typeof atob === 'function') {
							redirectUrl = atob(base64encodedUrl);
						} else {
							let base64Buffer = Buffer.from(base64encodedUrl, 'base64');
							redirectUrl = base64Buffer.toString('ascii');
						}
					} catch (err) {
						if (err instanceof ReferenceError) {
							// This means the buffer wasn't defined. Ignore.
							console.warn('Reference Error', err.message);
						} else {
							throw err;
						}
					}
				}
			}

			navigate(redirectUrl);
		}
	};

	return (
		<UserContext.Provider
			value={{
				isUserAuthenticated,
				user: user!,
				signIn: handleLoginResult,
				jwt: jwt!,
				setDisplayUserLoginOverlay: () => {},
				setUserCredentials: () => {},
			}}
		>
			<FrontendNavbar />
			{isCheckingUserAuthenticated ? (
				<FullPageLoading />
			) : (
				<div className="wrapper wrapper-full-page">
					<div className="full-page section-image">
						<Container>
							<Row className="justify-content-center">
								<Col className={`ml-auto mr-auto ${user == null ? 'col-md-6 col-lg-6' : ''}`}>
									<ErrorHandler>
										<Routes>
											<Route path="*" element={<FrontendPage />} />
										</Routes>
									</ErrorHandler>
								</Col>
							</Row>
						</Container>
						<footer className={'footer'}>
							<Container>
								<Row>
									<div className="credits ml-auto">
										<span className="copyright">
											<a href={`${process.env.REACT_APP_TERMS_OF_USE_URL}`} style={{ color: '#fff' }} target="_blank">
												Terms
											</a>{' '}
											| &copy; {new Date().getUTCFullYear()} Mouseware Designs
										</span>
									</div>
								</Row>
							</Container>
						</footer>

						<Toaster position="bottom-right" />
						<div
							className="full-page-background"
							style={{
								backgroundImage: `url(${process.env.REACT_APP_FRONTEND_BACKGROUND_IMAGE || '/img/bg/default-background.jpg'})`,
							}}
						/>
					</div>
				</div>
			)}
		</UserContext.Provider>
	);
};
