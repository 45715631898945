import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { UserDetails } from './UserDetails';
import { UsersTable } from './partials/UsersTable';

export const AdministratorUsers = () => {
	return (
		<div className="content administrators-users-page">
			<Routes>
				<Route path={'add'} element={<UserDetails />} />
				<Route path={':uid'} element={<UserDetails />} />
				<Route index element={<UsersTable />} />
			</Routes>
		</div>
	);
};
