import React, { useState } from 'react';

import { Input, Badge, Button } from 'reactstrap';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	detailsField: SettingsDataField;
	fieldName: string;
	selectedValue: any;
	handleFieldChange: (newValue: any) => void;
	handleInputFieldMultipleAddMode: (detailsFieldUID: string, state: boolean) => void;
}

export const TabDataEditModeSelectField = (props: Props) => {
	const { detailsField, selectedValue, fieldName, handleFieldChange, handleInputFieldMultipleAddMode } = props;
	const { description, attributes } = detailsField;
	const [inAddMode, setInAddMode] = useState(false);
	const [addModeSelectedValue, setAddModeSelectedValue] = useState<Array<any> | null>([]);

	// This is a local variable of the selectedValue prop... processing it here
	let parentSelectedValue = selectedValue;

	// Check if this supports multiple values or not
	if (attributes.multiple === true) {
		// Make sure we have a valid default value
		if (parentSelectedValue == null) {
			parentSelectedValue = [];
		}

		/**
		 * This function will handle the pill "X" click
		 * @param {*} e
		 * @param {*} valObjId
		 */
		const handleRemovePillClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, valObjId: any) => {
			e.preventDefault();

			const newValueArray = selectedValue.filter((val: any) => val.uid !== valObjId);

			handleFieldChange(
				newValueArray.map((val: any) => {
					return val.uid;
				})
			);
		};

		/**
		 * This function will toggle the add pill mode
		 */
		const handleToggleAddPillModeClick = () => {
			if (inAddMode === true) {
				setAddModeSelectedValue(null);
				setInAddMode(false);
				handleInputFieldMultipleAddMode(detailsField.uid, false);
			} else {
				setInAddMode(true);
				handleInputFieldMultipleAddMode(detailsField.uid, true);
			}
		};

		/**
		 * This function will handle the change of the input select
		 */
		const handleInputSelectChange = (inputVal: any) => {
			setAddModeSelectedValue(inputVal || null);
		};

		/**
		 * This function will process the selected value and add it to the parent value set
		 */
		const handleAddPillValueClick = () => {
			const newObj = attributes.options!.filter((option) => {
				return option.uid === (addModeSelectedValue as any);
			})[0];

			if (newObj != null) {
				parentSelectedValue.push(newObj);

				return Promise.resolve()
					.then(() => {
						return handleFieldChange(
							parentSelectedValue.map((val: any) => {
								return val.uid;
							})
						);
					})
					.then(() => {
						setAddModeSelectedValue(null);
						setInAddMode(false);
						handleInputFieldMultipleAddMode(detailsField.uid, false);
					});
			}
		};

		// Filter out the names to just have a list of UIDs
		const selectedValueUIDs = parentSelectedValue.map((val: any) => {
			return val.uid;
		});

		return (
			<div className="tab-data-edit-mode-select-field">
				<div className="pill-section">
					{Array.isArray(parentSelectedValue) && parentSelectedValue.length > 0 ? (
						parentSelectedValue.map((valueObj) => {
							return (
								<Badge key={`tab-data-edit-mode-select-field-pill-badge-${valueObj.uid}`} className="pill" color="primary" pill>
									{valueObj.name}
									<Button
										className="close"
										color="link"
										onClick={(e) => {
											handleRemovePillClick(e, valueObj.uid);
										}}
									>
										<i className="fa fa-close" />
									</Button>
								</Badge>
							);
						})
					) : (
						<div className="no-values">Click add to update this field</div>
					)}
					{inAddMode === true ? (
						<Button
							className="add-button"
							color="link"
							size="sm"
							onClick={() => {
								handleToggleAddPillModeClick();
							}}
						>
							Cancel
						</Button>
					) : (
						<Button
							className="add-button"
							color="link"
							size="sm"
							onClick={() => {
								handleToggleAddPillModeClick();
							}}
							disabled={
								attributes.options!.filter((option) => {
									return !selectedValueUIDs.includes(option.uid);
								}).length === 0
							}
						>
							<i className="fa fa-plus" /> Add
						</Button>
					)}
				</div>
				<div className="add-section">
					{inAddMode === true ? (
						<>
							<Input
								type={'select'}
								name={fieldName}
								id={fieldName}
								placeholder={description}
								onChange={(e) => {
									handleInputSelectChange(e.target.value);
								}}
							>
								<option value={''}>- Select Option -</option>
								{attributes
									.options!.filter((option) => {
										return !selectedValueUIDs.includes(option.uid);
									})
									.map((option) => {
										return (
											<option key={option.uid} value={option.uid}>
												{option.name}
											</option>
										);
									})}
							</Input>
							<Button
								className="add-button"
								color="primary"
								size="sm"
								onClick={() => {
									handleAddPillValueClick();
								}}
								disabled={addModeSelectedValue == null}
							>
								Add
							</Button>
						</>
					) : (
						<></>
					)}
				</div>
			</div>
		);
	} else {
		// Make sure we have a valid default value
		if (parentSelectedValue == null) {
			parentSelectedValue = '';
		}

		return (
			<Input
				type={'select'}
				name={fieldName}
				id={fieldName}
				placeholder={description}
				onChange={(e) => {
					handleFieldChange(e.target.value);
				}}
				defaultValue={parentSelectedValue}
			>
				<option value={null as any}>- Select Option -</option>
				{attributes.options!.map((option) => {
					return (
						<option key={option.uid} value={option.uid}>
							{option.name}
						</option>
					);
				})}
			</Input>
		);
	}
};
