import React, { useContext, useEffect, useState } from 'react';
import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, Nav, Container } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';

const classnames = require('classnames');

import { Search } from './partials/Search';

import UserContext from '../UserContext';
import { clearAuthenticatedAdminUser } from '../../api';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './AdminNavbar.scss';

interface Props {
	handleMiniClick: () => void;
}

export const AdminNavbar = (props: Props) => {
	const { handleMiniClick } = props;
	const { user } = useContext(UserContext);

	const [collapseOpen, setCollapseOpen] = useState(false);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [color, setColor] = useState('navbar-transparent');

	useEffect(() => {
		(e: any) => {
			window.addEventListener('resize', updateColor);

			if (
				window.outerWidth < 993 &&
				e.history.location.pathname !== e.location.pathname &&
				document.documentElement.className.indexOf('nav-open') !== -1
			) {
				document.documentElement.classList.toggle('nav-open');
			}

			return () => {
				window.removeEventListener('resize', updateColor);
			};
		};
	}, []);

	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	const updateColor = () => {
		if (window.innerWidth < 993 && collapseOpen) {
			setColor('bg-white');
		} else {
			setColor('navbar-transparent');
		}
	};

	// this function opens and closes the sidebar on small devices
	const toggleSidebar = () => {
		document.documentElement.classList.toggle('nav-open');
	};

	// this function opens and closes the collapse on small devices
	// it also adds navbar-transparent class to the navbar when closed
	// ad bg-white when opened
	const toggleCollapse = () => {
		let newState = {
			collapseOpen: !collapseOpen,
		};
		if (!collapseOpen) {
			setColor('bg-white');
		} else {
			setColor('navbar-transparent');
		}
	};

	const handleLogoutClick = () => {
		clearAuthenticatedAdminUser();

		// user = null;
		// uid = null;
		// jwt = null;
	};

	return (
		<div className="admin-top-nav-bar">
			<Navbar className={classnames('navbar-absolute fixed-top', color)} expand="lg">
				<div className="navbar-wrapper">
					<div className="navbar-minimize">
						<Button className="btn-icon btn-round" color="default" id="minimizeSidebar" onClick={handleMiniClick}>
							{sidebarOpen ? <FaAngleRight size={24} /> : <FaAngleLeft size={24} />}
						</Button>
					</div>
					<div
						className={classnames('navbar-toggle', {
							toggled: sidebarOpen,
						})}
					>
						<button className="navbar-toggler" type="button" onClick={toggleSidebar}>
							<span className="navbar-toggler-bar bar1" />
							<span className="navbar-toggler-bar bar2" />
							<span className="navbar-toggler-bar bar3" />
						</button>
					</div>
					<NavbarBrand href="#" onClick={(e) => e.preventDefault()}>
						<span className="d-none d-md-block">
							Clearance Management Admin<sup>BETA</sup>
						</span>
						<span className="d-block d-md-none">Clearance Admin</span>
					</NavbarBrand>
				</div>
				<Collapse className="justify-content-end" navbar isOpen={collapseOpen}>
					<Search />
					<Nav navbar>
						<UncontrolledDropdown nav>
							<DropdownToggle aria-haspopup={true} color="default" data-toggle="dropdown" id="navbarDropdownMenuLink" nav>
								<div className="d-flex gap-2 align-items-center media">
									<span className="avatar avatar-sm rounded-circle">
										<i className="fa fa-user" style={{ top: '0px' }}></i>
									</span>
									<div className="ml-2 d-none d-lg-block media">
										<span className="mb-0 text-sm font-weight-bold">
											<strong>{user != null ? user.name : 'My Account'}</strong>
										</span>
									</div>
								</div>
							</DropdownToggle>
							<DropdownMenu persist aria-labelledby="navbarDropdownMenuLink" end>
								<DropdownItem tag={RouterNavLink} to={`/administrator/preferences`} className="d-flex align-items-center gap-1">
									<i className="fa fa-user"></i>
									<span>Preferences</span>
								</DropdownItem>
								{/* <DropdownItem
											href="#"
											onClick={(e) => {
												e.preventDefault();
												handleLogoutClick();
											}}
										>
											<FaUser color="#66615b" />
											<span>Logout</span>
										</DropdownItem> */}
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
};
