import React from 'react';
import { Container, Row } from 'reactstrap';

interface Props {
	default?: boolean;
	fluid?: boolean;
}

export const Footer = (props: Props) => {
	return (
		<footer className={'footer' + (props.default ? ' footer-default' : '')}>
			<Container fluid={props.fluid ? true : false}>
				<Row>
					<div className="credits ml-auto">
						<span className="copyright">
							<a href={`${process.env.REACT_APP_TERMS_OF_USE_URL}`} style={{ color: '#2c2c2c' }} target="_blank">
								Terms
							</a>{' '}
							| &copy; {new Date().getUTCFullYear()}, made with <i className="fa fa-heart heart" /> by Mouseware Designs
						</span>
					</div>
				</Row>
			</Container>
		</footer>
	);
};
