import React, { useState, useContext, useEffect } from 'react';
import { Routes, Route, NavLink as RouterNavLink, useParams, Navigate, useNavigate } from 'react-router-dom';

import { Button, Col, FormGroup, TabPane, TabContent, NavItem, NavLink, Nav } from 'reactstrap';

import { SettingsSection } from './template-details/SettingsSection';
import { PreProcessingSection } from './template-details/PreProcessingSection';
import { PostProcessingSection } from './template-details/PostProcessingSection';

import { member } from '../../../../api';
import UserContext from '../../../UserContext';
import toast from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { MemberImportTemplateDB } from 'api/members/templates';

interface Props {
	dynamicSettingsDataFields: Array<SettingsDataField>;
}

export const TemplateDetails = (props: Props) => {
	const navigate = useNavigate();
	const { jwt } = useContext(UserContext);
	const { dynamicSettingsDataFields } = props;
	let { templateID } = useParams<{ templateID: string }>();

	const [isLoading, setIsLoading] = useState(true);
	const [isEditMode, setIsEditMode] = useState(false);
	const [data, setData] = useState<MemberImportTemplateDB>({ title: '', fields: [], archived: null });
	const [hasFormChanged, setHasFormChanged] = useState(false);
	const [formSaveInProgress, setFormSaveInProgress] = useState(false);
	const [canSave, setCanSave] = useState(false);

	useEffect(() => {
		const getContents = async () => {
			if (templateID === 'add') {
				setIsEditMode(true);
				setIsLoading(false);
				return;
			}

			setIsLoading(true);
			try {
				const response = await member.import.templates.get(jwt, templateID ?? '');
				setData(response);

				setIsEditMode(false);
				setIsLoading(false);
			} catch (err) {
				// TODO: Handle better
				console.error('error');
				console.error(err);
			}
		};

		getContents();
	}, []);

	useEffect(() => {
		let canSaveForm = true;

		if (data == null || data.title == null || data.title === '') {
			canSaveForm = false;
		}

		setCanSave(canSaveForm);
	}, [data]);

	const handleInputFieldChange = (fieldName: keyof MemberImportTemplateDB, fieldValue: any, type?: string) => {
		const newData = { ...data };

		if (type === 'boolean') {
			(newData as any)[fieldName] = fieldValue === 'true';
		} else {
			(newData as any)[fieldName] = fieldValue;
		}

		setData(newData);
		setHasFormChanged(true);
	};

	const handleOnSaveClick = async () => {
		setFormSaveInProgress(true);
		try {
			if (data.docID != null) {
				await member.import.templates.update(jwt, data.docID, data);

				toast.success('Successfully updated template');

				setHasFormChanged(false);
			} else {
				const resp = await member.import.createTemplate(jwt, data);

				toast.success('Successfully created template');

				setHasFormChanged(false);
				navigate('..');
			}
		} catch (err) {
			// TODO: Handle better
			console.error('error');
			console.error(err);
		} finally {
			setFormSaveInProgress(false);
		}
	};

	const handleOnCancelClick = () => {
		navigate('..');
	};

	return (
		<>
			<div className="nav-tabs-navigation">
				<div className="nav-tabs-wrapper">
					<Nav id="tabs" role="tablist" tabs>
						<NavItem key={`settings-nav-item`}>
							<NavLink tag={RouterNavLink} to={`settings`} end>
								Settings
							</NavLink>
						</NavItem>
						<NavItem key={`pre-processing-nav-item`}>
							<NavLink tag={RouterNavLink} to={`pre-processing`} end>
								Pre-Processing
							</NavLink>
						</NavItem>
						<NavItem key={`post-processing-nav-item`}>
							<NavLink tag={RouterNavLink} to={`post-processing`} end>
								Post-Processing
							</NavLink>
						</NavItem>
					</Nav>
				</div>
			</div>
			<TabContent>
				<TabPane>
					{isLoading ? (
						<></>
					) : (
						<Routes>
							<Route
								path={`settings`}
								element={
									<SettingsSection
										dynamicSettingsDataFields={dynamicSettingsDataFields}
										data={data}
										handleInputFieldChange={handleInputFieldChange}
									/>
								}
							/>
							<Route
								path={`pre-processing`}
								element={
									<PreProcessingSection
										dynamicSettingsDataFields={dynamicSettingsDataFields}
										data={data}
										handleInputFieldChange={handleInputFieldChange}
									/>
								}
							/>
							<Route
								path={`post-processing`}
								element={
									<PostProcessingSection
										dynamicSettingsDataFields={dynamicSettingsDataFields}
										data={data}
										handleInputFieldChange={handleInputFieldChange}
									/>
								}
							/>
							<Route index element={<Navigate to={`settings`} />} />
						</Routes>
					)}
				</TabPane>
			</TabContent>
			<hr />
			<FormGroup check row>
				<Col sm={{ size: 10, offset: 2 }}>
					<Button color="primary" onClick={() => handleOnSaveClick()} disabled={!hasFormChanged || !canSave || formSaveInProgress}>
						{formSaveInProgress ? <FaSpinner size={12} className="fa-spin" /> : 'Save'}
					</Button>
					<Button color="secondary" className="pull-right" onClick={() => handleOnCancelClick()} disabled={formSaveInProgress}>
						Cancel
					</Button>
				</Col>
			</FormGroup>
		</>
	);
};
