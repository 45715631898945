import React from 'react';

import { Button, Modal, ModalHeader } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

interface Props {
	show: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	children: React.ReactNode;
}

export const TableRecordDeletePrompt = (props: Props) => {
	const { show, onConfirm, onCancel, children } = props;

	return (
		<Modal isOpen={show}>
			<ModalHeader toggle={() => onCancel()}>Are you sure you want to delete the field?</ModalHeader>
			<div className="modal-body">{children}</div>
			<div className="modal-footer justify-content-center">
				<Button
					className="btn-round"
					color="danger"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						onConfirm();
					}}
				>
					Delete
				</Button>
				<Button
					className="btn-round"
					color="secondary"
					data-dismiss="modal"
					type="button"
					onClick={() => {
						onCancel();
					}}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};
