import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Nav, NavItem, NavLink } from 'reactstrap';

import { setting, member } from '../../../api';
import UserContext from '../../UserContext';

import { UploadBulkMemberFile } from './import-helpers/UploadBulkMemberFile';
import { ImportResults } from './import-helpers/ImportResults';
import { PreviewData } from './import-helpers/PreviewData';
import { FaSpinner } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { MemberImportDB } from 'api/members/import';
import { SettingsClearancesType } from 'api/settings/clearances';

interface Props {
	memberImportRootPath: string;
	dynamicSettingsDataFields: Array<SettingsDataField>;
	importTemplateID?: string;
}

export const Import = (props: Props) => {
	const { jwt } = useContext(UserContext);
	const { memberImportRootPath, dynamicSettingsDataFields, importTemplateID } = props;
	const { importID } = useParams<{ importID: string }>();

	const [isLoading, setIsLoading] = useState(false);
	const [stepState, setStepState] = useState(1);
	const [importData, setImportData] = useState<MemberImportDB>();
	const [clearanceSettingsFields, setClearanceSettingsFields] = useState<Array<SettingsClearancesType>>([]);
	const [errorMessage, setErrorMessage] = useState(null);

	const importPollTimer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		return () => {
			if (importPollTimer.current) {
				clearInterval(importPollTimer.current);
				importPollTimer.current = null;
			}
		};
	}, []);

	useEffect(() => {
		const getContents = async () => {
			setIsLoading(true);

			try {
				const clearancesResp = await setting.clearances.get(jwt);
				setClearanceSettingsFields(clearancesResp.types_list);

				const importResp = await member.import.get(jwt, importID ?? '');
				if (importResp != null) {
					if (importResp.processing_state === 'file-processed') {
						setStepState(2);
					} else if (importResp.processing_state === 'executed') {
						setStepState(3);
					} else if (importResp.processing_state === 'generating-backup') {
						await member.import.getBackupStatus(jwt, importResp.docID);
					}

					setImportData(importResp);
				}

				if (importPollTimer.current == null) {
					const timer = setInterval(() => {
						pollUpdate();
					}, 5000);

					importPollTimer.current = timer;
				}
			} catch (err: any) {
				setErrorMessage(err.message);

				if (importPollTimer.current != null) {
					clearInterval(importPollTimer.current);
					importPollTimer.current = null;
				}
			} finally {
				setIsLoading(false);
			}
		};

		if (importID != null) {
			if (importData == null || importData.docID == null) {
				// This came from an import URL. Go get the import data
				getContents();
			} else if (importData.docID !== importID) {
				// Something changed
			}
		}
	}, [importID]);

	const pollUpdate = async () => {
		try {
			const importResp = await member.import.get(jwt, importID ?? '');
			if (importResp != null) {
				if (importResp.processing_state === 'file-processed') {
					setStepState(2);
				} else if (importResp.processing_state === 'executed') {
					setStepState(3);
				} else if (importResp.processing_state === 'generating-backup') {
					await member.import.getBackupStatus(jwt, importResp.docID);
				}

				setImportData(importResp);
			}
		} catch (err: any) {
			setErrorMessage(err.message);

			if (importPollTimer.current != null) {
				clearInterval(importPollTimer.current);
				importPollTimer.current = null;
			}
		}
	};

	const handleColumnConfirmation = async () => {
		try {
			await member.import.confirmMemberImport(jwt, importData!.docID);

			const newImportData = { ...importData };

			if (importData == null || importData.skip_db_backup == null || importData.skip_db_backup === false) {
				newImportData.processing_state = 'processing-import';
			} else {
				newImportData.processing_state = 'generating-backup';
			}
			setImportData(newImportData as MemberImportDB);
			window.scrollTo(0, 0);
		} catch (err) {
			// TODO: Handle better
			console.error('error');
			console.error(err);
		}
	};

	const processingStateVisual = (state: string) => {
		switch (state) {
			case 'created':
				return 'Created';
			case 'processing-file':
				return 'Processing Import File';
			case 'file-processed':
				return 'Import File Processed';
			case 'generating-backup':
				return 'Generating Backup';
			case 'backup-generated':
				return 'Backup Generated';
			case 'processing-import':
				return 'Processing Import';
			case 'executed':
				return 'Import Executed';
			case 'failed':
				return 'Import Failed';
		}
	};

	return (
		<>
			<Nav pills className="d-flex justify-content-center mb-2">
				<NavItem>
					<NavLink style={stepState >= 1 ? { backgroundColor: 'rgba(108, 117, 125, .2)' } : {}}>Upload</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={stepState >= 2 ? { backgroundColor: 'rgba(108, 117, 125, .2)' } : {}}>Preview Data</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={stepState >= 3 ? { backgroundColor: 'rgba(108, 117, 125, .2)' } : {}}>Results</NavLink>
				</NavItem>
			</Nav>
			{errorMessage != null ? (
				<>{errorMessage}</>
			) : isLoading ? (
				<div className="file-processing">
					<div className="processing-content">
						<FaSpinner size={12} className="fa-spin" />
					</div>
				</div>
			) : importData == null || importData.docID == null ? (
				<UploadBulkMemberFile
					templateID={importTemplateID!}
					memberImportRootPath={memberImportRootPath}
					validFileTypes={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
				/>
			) : importData.docID != null ? (
				<>
					{['created', 'processing-file', 'generating-backup', 'backup-generated', 'processing-import'].includes(importData.processing_state) ? ( // Waiting for it to be picked up for processing
						<div className="file-processing">
							<div className="processing-content">
								<FaSpinner size={12} className="fa-spin" />
							</div>
						</div>
					) : importData.processing_state === 'file-processed' ? ( // Need user input to confirm
						<PreviewData
							clearanceSettingsFields={clearanceSettingsFields}
							dynamicSettingsDataFields={dynamicSettingsDataFields}
							columnMappings={importData.template_fields || []}
							onConfirm={handleColumnConfirmation}
							data={importData}
						/>
					) : importData.processing_state === 'executed' ? ( // Success
						<ImportResults columnMappings={importData.template_fields || []} />
					) : importData.processing_state === 'failed' ? (
						<>
							<div className="file-processing">
								<div className="processing-content">Failed to process</div>
							</div>
							{importData.failed_reason != null ? <div style={{ width: '100%', textAlign: 'center' }}>{importData.failed_reason}</div> : <></>}
						</>
					) : (
						<div className="file-processing">
							<div className="processing-content">???</div>
						</div>
					)}
					{!['file-processed', 'executed', 'failed'].includes(importData.processing_state) && (
						<div style={{ width: '100%', textAlign: 'center' }}>{processingStateVisual(importData.processing_state)}</div>
					)}
				</>
			) : (
				<div className="file-processing">
					<div className="processing-content">
						<i className="fa fa-help" />
					</div>
				</div>
			)}
		</>
	);
};
