import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';

import { ImportTemplates } from './members-import/ImportTemplates';
import { Import } from './members-import/Import';

import { setting } from '../../api';
import UserContext from '../UserContext';
import { FaSpinner } from 'react-icons/fa';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';

export const MembersImport = () => {
	const { hash: locationHash, pathname: locationPathname, search: locationSearch } = useLocation();
	const { jwt } = useContext(UserContext);

	const [isLoaded, setIsLoaded] = useState(false);
	const [dynamicSettingsDataFields, setDynamicSettingsDataFields] = useState<Array<SettingsDataField>>([]);
	const [clearanceFields, setClearanceFields] = useState<Array<SettingsClearancesType>>([]);

	/**
	 * This function will retrieve the notifications data from the backend
	 */
	useEffect(() => {
		Promise.resolve()
			.then(() => {
				return setting.general
					.get(jwt)
					.then((data) => {
						setDynamicSettingsDataFields(data.data_fields);
					})
					.catch((e) => {
						// TODO: Handle better
						console.error('error');
						console.error(e);
					});
			})
			.then(() => {
				return setting.clearances
					.get(jwt)
					.then((data) => {
						setClearanceFields(data.types_list);
					})
					.catch((e) => {
						// TODO: Handle better
						console.error('error');
						console.error(e);
					});
			})
			.then(() => {
				setIsLoaded(true);
			});
	}, []);

	return (
		<div className="content members-table">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Member Import Templates</CardTitle>
						</CardHeader>
						<CardBody>
							{isLoaded ? (
								<Routes>
									<Route
										path={`templates/*`}
										element={
											<ImportTemplates
												memberImportRootPath={locationPathname}
												clearanceFields={clearanceFields}
												dynamicSettingsDataFields={dynamicSettingsDataFields}
											/>
										}
									/>
									<Route
										path={`:importID/*`}
										element={<Import memberImportRootPath={locationPathname} dynamicSettingsDataFields={dynamicSettingsDataFields} />}
									/>
									<Route index element={<Navigate to={`templates`} />} />
								</Routes>
							) : (
								<FaSpinner size={12} color="#ccc" className="fa-spin" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
