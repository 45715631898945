import React from 'react';

import { Input, FormGroup, Col } from 'reactstrap';
import { SettingsNotificationsListEntryRecipientCondition } from 'api/settings/notifications';

interface Props {
	data: SettingsNotificationsListEntryRecipientCondition;
	onChange: (fieldName: keyof SettingsNotificationsListEntryRecipientCondition, newValue: any) => void;
}

export const FieldTypeValue = (props: Props) => {
	const { data, onChange } = props;

	return (
		<>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name={`field`}
						id={`field`}
						onChange={(e) => {
							onChange('field', e.target.value);
						}}
						value={data != null && data.field != null ? data.field : 'document-expiration'}
						disabled={true}
					>
						<option value="document-expiration">Document Expiration</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name={`logicOperator`}
						id={`logicOperator`}
						onChange={(e) => {
							onChange('logicOperator', e.target.value);
						}}
						defaultValue={data != null && data.logicOperator != null ? data.logicOperator : 'equal-to'}
						disabled={true}
					>
						<option value="equal-to">Equal to</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}>
				<FormGroup>
					<Input
						type="select"
						name="value_type"
						id="value_type"
						onChange={(e) => {
							onChange('value_type', e.target.value);
						}}
						defaultValue={data != null && data.value_type != null ? data.value_type : 'value'}
						disabled={true}
					>
						<option value="value">Value</option>
					</Input>
				</FormGroup>
			</Col>
			<Col md={2}>
				<FormGroup>
					{data != null && data.field != null && data.field === 'document-expiration' ? (
						<Input
							type="number"
							name="value"
							id="value"
							placeholder="Days Before"
							onChange={(e) => {
								onChange('value', e.target.value !== '' ? parseInt(e.target.value) : null);
							}}
							value={data != null && data.value != null ? data.value : ''}
						/>
					) : (
						<></>
					)}
				</FormGroup>
			</Col>
		</>
	);
};
