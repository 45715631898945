// Node modules

// Types

// Exceptions

// Helpers

export const numberToIntlFormat = (number: number): string => {
	const formatter = new Intl.NumberFormat('en-US', { style: 'decimal' });

	return formatter.format(number);
};
