import React, { useState } from 'react';
import * as Sentry from '@sentry/react';

import { Row, Col, FormGroup, Label, Input, Table, Button, UncontrolledTooltip, Alert, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ReportDB, ReportNotificationCCRecipient, ReportNotificationToRecipient } from 'api/reports';
import { FaEdit, FaPlus, FaTimes } from 'react-icons/fa';
import { RecipientDetailsOverlay } from './RecipientDetailsOverlay/RecipientDetailsOverlay';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	onChange: (fieldName: keyof ReportDB, newValue: any) => void;
	data: ReportDB;
	settingsDataFields: Array<SettingsDataField>;
}

export const NotificationsSection = (props: Props) => {
	const { onChange, data, settingsDataFields } = props;

	const [showModalOverlay, setShowModalOverlay] = useState<boolean>(false);
	const [editRecord, setEditRecord] = useState<ReportNotificationToRecipient | ReportNotificationCCRecipient | undefined>(undefined);

	const [popoverField, setPopoverField] = useState<boolean>(false);

	const handleShowModalOverlayToggle = () => {
		if (showModalOverlay === true) {
			setEditRecord(undefined);
		}

		setShowModalOverlay(!showModalOverlay);
	};

	const handleEditClick = (index: number) => {
		setEditRecord(data.recipients![index]);
		handleShowModalOverlayToggle();
	};

	const handleRemoveClick = (index: number) => {
		const newData = [...data.recipients!];
		newData.splice(index, 1);
		onChange('recipients', newData);
	};

	const handleFieldDetailsSave = (record: ReportNotificationToRecipient | ReportNotificationCCRecipient) => {
		const newData = data.recipients != null ? [...data.recipients] : [];

		// Check to see if there is an edit
		if (editRecord != null) {
			const existingFieldRecordIndex = newData
				.map((e) => `${e.recipient_type}-${e.recipient}`)
				.indexOf(`${editRecord.recipient_type}-${editRecord.recipient}`);
			if (existingFieldRecordIndex >= 0) {
				newData[existingFieldRecordIndex] = record;
			}
		} else {
			// Verify this field doesn't exist
			const existingFieldRecordIndex = newData.map((e) => JSON.stringify(e)).indexOf(JSON.stringify(record));

			if (existingFieldRecordIndex === -1) {
				newData.push(record);
			} else {
				Sentry.captureMessage(`NotificationsSection.handleFieldDetailsSave() new record already exists "${record.recipient_type}-${record.recipient}"`);
			}
		}

		onChange('recipients', newData);

		handleShowModalOverlayToggle();
	};

	return (
		<div className="add-report-notification-section">
			<Row>
				<Col sm={12}>
					{data.recipients != null && data.recipients.length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left">Show</th>
									<th className="text-left">Type</th>
									<th className="text-left">Conditions</th>
									<th className="text-right">Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.recipients.map((row, key) => {
									const { recipient_type, recipient, conditions } = row;

									return (
										<tr key={`notification-recipient-section-${key}`}>
											<td>{recipient_type}</td>
											<td>{recipient}</td>
											<td></td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="success"
													id={`notification-recipient-section-${key}-edit`}
													size="sm"
													type="button"
													onClick={() => handleEditClick(key)}
												>
													<FaEdit size={12} />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`notification-recipient-section-${key}-edit`}>
													Edit
												</UncontrolledTooltip>
												<Button
													className="btn-icon"
													color="danger"
													id={`notification-recipient-section-${key}-delete`}
													size="sm"
													type="button"
													onClick={() => handleRemoveClick(key)}
												>
													<FaTimes size={12} />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`notification-recipient-section-${key}-delete`}>
													Delete
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No recipients defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button
							className="btn-icon"
							id={`notification-recipient-section-field-add`}
							color="info"
							size="sm"
							onClick={() => handleShowModalOverlayToggle()}
							disabled={showModalOverlay}
						>
							<FaPlus size={12} />
						</Button>
						<UncontrolledTooltip delay={0} target={`notification-recipient-section-field-add`}>
							Add Recipient
						</UncontrolledTooltip>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col md={10}>
					<FormGroup>
						<Label for="subject">Email Subject</Label>
						<Input
							type="text"
							name="subject"
							id="subject"
							onChange={(e) => {
								onChange('subject', e.target.value);
							}}
							value={data.subject != null ? data.subject : ''}
						/>
					</FormGroup>
				</Col>
				<Col md={2}>
					<FormGroup>
						<Label for="notification_requires_approval">Require Approval</Label>
						<Input
							type="select"
							name="notification_requires_approval"
							id="notification_requires_approval"
							onChange={(e) => {
								onChange('notification_requires_approval', e.target.value === 'true');
							}}
							value={(data.notification_requires_approval != null ? data.notification_requires_approval : false) as any}
						>
							<option value={false as any}>No</option>
							<option value={true as any}>Yes</option>
						</Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<FormGroup>
						<Label for="body">Email Body</Label>
						<CKEditor
							editor={ClassicEditor}
							config={{
								toolbar: [
									'heading',
									'|',
									'bold',
									'italic',
									'|',
									'link',
									'|',
									'outdent',
									'indent',
									'|',
									'bulletedList',
									'numberedList',
									'insertTable',
									'|',
									'blockQuote',
									'|',
									'undo',
									'redo',
								],
							}}
							data={data.body != null ? data.body : ''}
							onChange={(event: any, editor: any) => {
								const data = editor.getData();

								onChange('body', data);
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="pull-right variables-button">
						<Button color="link" id={`notification-message-variables`} type="button" size="sm">
							Variables
						</Button>
					</div>
					<UncontrolledPopover
						popperClassName="notification-message-variables-popover"
						placement="left"
						trigger="focus"
						isOpen={popoverField}
						target="notification-message-variables"
						toggle={() => setPopoverField(!popoverField)}
					>
						<PopoverHeader>Available Fields</PopoverHeader>
						<PopoverBody>
							<strong>[Date]</strong> Today's date in the MM/DD/YYYY format
						</PopoverBody>
					</UncontrolledPopover>
				</Col>
			</Row>
			<RecipientDetailsOverlay
				show={showModalOverlay}
				onSave={handleFieldDetailsSave}
				onClose={handleShowModalOverlayToggle}
				editData={editRecord}
				settingsDataFields={settingsDataFields}
			/>
		</div>
	);
};
