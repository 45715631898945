import React, { useState } from 'react';

const classNames = require('classnames');

import { Button, Modal } from 'reactstrap';
import { FaSpinner, FaTrashAlt } from 'react-icons/fa';
import { IconType } from 'react-icons';

interface Props {
	show: boolean;

	iconColor?: 'black' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger';
	icon?: IconType;

	title?: string;

	primaryActionColor?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger';
	primaryActionText?: string;
	primaryActionDisabled?: boolean;
	primaryActionCallback?: () => void;

	secondaryActionColor?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger';
	secondaryActionText?: string;
	secondaryActionDisabled?: boolean;
	secondaryActionCallback?: () => void;

	className?: string;
	children?: React.ReactNode;
}

export const ModalOverlay = (props: Props) => {
	const {
		show,
		iconColor,
		icon: Icon,
		title,
		primaryActionColor,
		primaryActionText,
		primaryActionDisabled,
		primaryActionCallback,
		secondaryActionColor,
		secondaryActionText,
		secondaryActionDisabled,
		secondaryActionCallback,
		className,
		children,
	} = props;

	const [isPrimaryActionProcessing, setIsPrimaryActionProcessing] = useState<boolean>(false);

	const handlePrimaryActionClick = async () => {
		if (!primaryActionCallback) {
			return;
		}

		setIsPrimaryActionProcessing(true);
		await primaryActionCallback();
		setIsPrimaryActionProcessing(false);
	};

	return (
		<Modal isOpen={show} keyboard={true}>
			<div className="d-flex flex-column align-items-center modal-body">
				{Icon ? <Icon size={48} className={classNames('mb-2', { [`text-${iconColor || 'black'}`]: !iconColor || iconColor != 'black' })} /> : <></>}
				{title && <h5 className="mb-2">{title}</h5>}
				<div className={className}>{children}</div>
			</div>
			{primaryActionCallback && secondaryActionCallback && (
				<div className="modal-footer justify-content-center">
					{primaryActionCallback != null && (
						<Button
							type="button"
							className="btn-round"
							color={primaryActionColor || 'primary'}
							data-dismiss="modal"
							disabled={primaryActionDisabled || isPrimaryActionProcessing}
							onClick={handlePrimaryActionClick}
						>
							{!isPrimaryActionProcessing ? primaryActionText ? primaryActionText : 'Continue' : <FaSpinner size={12} className="fa-spin" />}
						</Button>
					)}
					{secondaryActionCallback != null && (
						<Button
							type="button"
							className="btn-round"
							color={secondaryActionColor || 'secondary'}
							data-dismiss="modal"
							disabled={secondaryActionDisabled}
							onClick={secondaryActionCallback}
						>
							{secondaryActionText ? secondaryActionText : 'Cancel'}
						</Button>
					)}
				</div>
			)}
		</Modal>
	);
};

interface ModalOverlayDeleteProps {
	show: boolean;

	title?: string;

	primaryActionCallback?: () => void;

	secondaryActionCallback?: () => void;

	children?: React.ReactNode;
}

export const ModalOverlayDelete = (props: ModalOverlayDeleteProps) => {
	return (
		<ModalOverlay
			{...props}
			icon={FaTrashAlt}
			primaryActionColor="danger"
			primaryActionText="Yes, Delete"
			secondaryActionColor="secondary"
			secondaryActionText="No, Cancel"
		/>
	);
};
