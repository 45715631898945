import React from 'react';

import { FieldConditionSelector } from './FieldConditionSelector';
import { SettingsDataField } from 'api/settings/general';

interface Props {
	settingsDataField: SettingsDataField;
	value: string | object | number | boolean;
	onChange: (newValue: string | object | number) => void;
}

export const GroupField = (props: Props) => {
	const { settingsDataField, value, onChange } = props;
	const { attributes } = settingsDataField;

	// Check if this supports multiple values or not
	if (attributes.multiple === true) {
		const handleInputFieldChange = (fieldName: string, newValue: any) => {
			const newVal: any = { ...(value as object) };
			newVal[fieldName] = newValue;

			onChange(newVal);
		};

		if (settingsDataField.attributes.options == null) {
			return <></>;
		}

		return (
			<>
				{settingsDataField.attributes.options.map((groupField, key) => {
					return (
						<span key={`report-group-field-${groupField.uid}`}>
							<FieldConditionSelector
								index={key}
								settingsDataField={groupField as SettingsDataField}
								value={value != null ? (value as any)[groupField.uid] : null}
								onUpdate={(newValue) => handleInputFieldChange(groupField.uid, newValue)}
							/>
						</span>
					);
				})}
			</>
		);
	} else {
		return <>Not currently supported</>;
	}
};
