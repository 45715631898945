import React, { useState } from 'react';
import * as Sentry from '@sentry/react';

import { Button, Col, FormGroup, Table, UncontrolledTooltip, Alert } from 'reactstrap';

import { ColumnDetailsOverlay } from './ColumnDetailsOverlay/ColumnDetailsOverlay';
import { ReportColumn, ReportColumnCondition, ReportDB } from 'api/reports';
import { SettingsDataField } from 'api/settings/general';
import { SettingsClearancesType } from 'api/settings/clearances';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

interface Props {
	onChange: (newValue: any) => void;
	data: Array<ReportColumn>;
	settingsDataFields: Array<SettingsDataField>;
	settingsClearances: Array<SettingsClearancesType>;
}

export const ColumnAttributesSection = (props: Props) => {
	const { onChange, settingsDataFields, settingsClearances, data } = props;

	const [showModalOverlay, setShowModalOverlay] = useState<boolean>(false);
	const [editRecord, setEditRecord] = useState<ReportColumn | undefined>(undefined);

	/**
	 * This function will handle toggling the field modal overlay
	 */
	const handleShowModalOverlayToggle = () => {
		if (showModalOverlay === true) {
			setEditRecord(undefined);
		}

		setShowModalOverlay(!showModalOverlay);
	};

	/**
	 * This function handles the field edit button click
	 * @param index the index into the data.columns array for the field to edit
	 */
	const handleEditClick = (index: number) => {
		setEditRecord(data[index]);
		handleShowModalOverlayToggle();
	};

	/**
	 * This function handles the field remove button click
	 * @param index the index into the data.columns array for the field to remove
	 */
	const handleRemoveClick = (index: number) => {
		const newColumns = [...data];
		newColumns.splice(index, 1);
		onChange(newColumns);
	};

	/**
	 * This function will handle toggling the add field modal overlay
	 */
	const handleFieldDetailsSave = (fieldData: ReportColumn) => {
		const newColumns = [...data];

		// Check to see if there is an edit
		if (editRecord != null) {
			const existingFieldRecordIndex = newColumns.map((e) => `${e.type}-${e.column}`).indexOf(`${editRecord.type}-${editRecord.column}`);
			if (existingFieldRecordIndex >= 0) {
				// Check the sorting
				if (fieldData.sort != null) {
					for (const column of newColumns) {
						if (column.sort != null) {
							delete column.sort;
						}
					}
				}

				newColumns[existingFieldRecordIndex] = fieldData;
			}
		} else {
			// Verify this field doesn't exist
			const existingFieldRecordIndex = newColumns.map((e) => `${e.type}-${e.column}`).indexOf(`${fieldData.type}-${fieldData.column}`);

			if (existingFieldRecordIndex === -1) {
				// Check the sorting
				if (fieldData.sort != null) {
					for (const column of newColumns) {
						if (column.sort != null) {
							delete column.sort;
						}
					}
				}

				newColumns.push(fieldData);
			} else {
				Sentry.captureMessage(`ColumnAttributesSection.handleFieldDetailsSave() new record already exists "${fieldData.type}-${fieldData.column}"`);
			}
		}

		onChange(newColumns);

		handleShowModalOverlayToggle();
	};

	/**
	 * This function will turn the array of conditions into a human readable string
	 * @param {*} conditionsArray the array object of conditions
	 */
	const generateFieldConditionsDisplayString = (rowObjData: SettingsDataField | SettingsClearancesType, conditionsArray: Array<ReportColumnCondition>) => {
		const strings = conditionsArray.map((condition) => {
			let operator = null;
			if (condition.logicOperator === 'equal-to') {
				operator = 'Equal To';
			} else if (condition.logicOperator === 'contains') {
				operator = 'Contains';
			} else if (condition.logicOperator === 'starts-with') {
				operator = 'Starts With';
			} else if (condition.logicOperator === 'ends-with') {
				operator = 'Ends With';
			}

			if (condition.value != null && typeof condition.value === 'object') {
				// TODO: This function does not handle the group fields correctly. This NEEDS to be fixed!!!
				if (rowObjData == null) {
					return '';
				}

				const multiples = Object.keys(condition.value).map((conditionIndex: any) => {
					const obj = (rowObjData as any).attributes.options.filter((option: any) => option.uid === conditionIndex)[0];

					if (obj != null) {
						if (obj.attributes.type === 'dropdown') {
							const val = obj.attributes.options.filter((option: any) => option.uid === condition.value[conditionIndex])[0];

							return `${obj.name} "${val != null ? val.name : condition.value[conditionIndex]}"`;
						} else {
							return `${obj.name} "${condition.value[conditionIndex]}"`;
						}
					}

					return '';
				});

				return `${operator} ${condition.value != null ? multiples.join(' and ') : 'Nothing'}`;
			} else {
				return `${operator} ${condition.value != null ? `"${condition.value}"` : 'Nothing'}`;
			}
		});

		return strings.join(', or ');
	};

	return (
		<div className="reports-column-attributes-section">
			<ColumnDetailsOverlay
				show={showModalOverlay}
				onSave={handleFieldDetailsSave}
				settingsDataFields={settingsDataFields.filter((field) => {
					if (data != null) {
						const indx = data.findIndex((column) => column.type === 'field' && field.uid === column.column);

						if (indx !== -1 && (editRecord == null || (editRecord != null && field.uid !== editRecord.column))) {
							return false;
						}
					}

					return true;
				})}
				settingsClearances={settingsClearances}
				onClose={handleShowModalOverlayToggle}
				editData={editRecord}
			/>
			<FormGroup row>
				<Col sm={12}>
					{data != null && data.length > 0 ? (
						<Table responsive style={{ marginBottom: 0 }}>
							<thead className="text-primary">
								<tr>
									<th className="text-left">Show</th>
									<th className="text-left">Type</th>
									<th className="text-left">Field</th>
									<th className="text-left">Conditions</th>
									<th className="text-right">Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.map((row, key) => {
									const { type, column, conditions, show, name } = row;
									let rowObjData = null;
									let displayType = null;

									if (type === 'field') {
										displayType = 'Field';
										rowObjData = settingsDataFields.filter((field) => field.uid === column)[0];
									} else if (type === 'clearance') {
										displayType = 'Clearance';
										rowObjData = settingsClearances.filter((clearance) => clearance.uid === column)[0];
									}

									return (
										<tr key={`column-attributes-section-${key}`}>
											<td>{show == null || show === true ? 'Yes' : 'No'}</td>
											<td>{displayType != null ? displayType : 'Unknown'}</td>
											<td>
												{row.sort != null ? row.sort === 'asc' ? <FaSortUp /> : row.sort === 'desc' ? <FaSortDown /> : <></> : <></>}
												{rowObjData != null ? (
													name != null && name !== '' ? (
														<>
															<span style={{ textDecoration: 'line-through' }}>{rowObjData.name}</span>&nbsp;
															{name}
														</>
													) : (
														rowObjData.name
													)
												) : (
													'Unknown'
												)}
											</td>
											<td>
												{conditions != null && conditions.length > 0
													? generateFieldConditionsDisplayString(rowObjData!, conditions)
													: ''}
											</td>
											<td className="text-right">
												<Button
													className="btn-icon"
													color="success"
													id={`column-attributes-section-${key}-edit`}
													size="sm"
													type="button"
													onClick={() => handleEditClick(key)}
												>
													<i className="fa fa-edit" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`column-attributes-section-${key}-edit`}>
													Edit
												</UncontrolledTooltip>
												<Button
													className="btn-icon"
													color="danger"
													id={`column-attributes-section-${key}-delete`}
													size="sm"
													type="button"
													onClick={() => handleRemoveClick(key)}
												>
													<i className="fa fa-times" />
												</Button>{' '}
												<UncontrolledTooltip delay={0} target={`column-attributes-section-${key}-delete`}>
													Delete
												</UncontrolledTooltip>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<Alert color="secondary">
							<span>No fields defined</span>
						</Alert>
					)}

					<div className="pull-right">
						<Button
							className="btn-icon"
							id={`reports-column-attributes-section-field-add`}
							color="info"
							size="sm"
							onClick={() => handleShowModalOverlayToggle()}
							disabled={showModalOverlay}
						>
							<i className="fa fa-plus" />
						</Button>
						<UncontrolledTooltip delay={0} target={`reports-column-attributes-section-field-add`}>
							Add Field
						</UncontrolledTooltip>
					</div>
				</Col>
			</FormGroup>
		</div>
	);
};
